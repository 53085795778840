import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";

/**
 * Toast Notification
 * 
 * @method showNotification
 * @param {String} type type of notification (success/error)
 * @param {String} message notification message to display
 * @returns Toast Notification
 */
export const showNotification = (type, message) => {
	toast.dismiss()
	
	if(type === 'success'){
		return toast.success(message, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		})
	} else if(type === 'error'){
		return toast.error(message, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		})
	}
}