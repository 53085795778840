/* eslint-disable */
import { Button } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import FormDatePicker from "../../advertisement-data/components/FormDatePicker";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from '@mui/icons-material/Refresh';
import { ToastContainer, toast } from "react-toastify";
import { LoadingContext } from "../../../../../App";
import { showNotification } from "../../../../helpers/showNotification";
import FormMultipleSearchInput from "../../../components/FormMultipleSearchInput";
import FormTypeAndSearchInput from "../../../components/FormTypeSearchInput";
import isDateValid from "../../../../helpers/isDateValid";
import { getFromStorage, saveOnStorage, STORAGE_KEY } from "../../../../helpers/Storage";
import "react-toastify/dist/ReactToastify.css";
import * as api from "../../../../api/request/DataServiceAPI"



export default function NewsDataForm(props) {
    
    const type_cast = {
        news_tvc: {key: STORAGE_KEY.news_tv_data, type: 'tv', index: "news_tvc"},
        news_rdc: {key: STORAGE_KEY.news_radio_data, type: 'rdc', index: "news_rdc"},
        news_print: {key: STORAGE_KEY.news_print_data, type: 'print', index: "news_print"},
        news_online: {key: STORAGE_KEY.news_online_data, type: 'online', index: "news_online"},
    }

    const { type = ''
    } = props;

    // state for, search by date
    const [startDate, setStartDate] = useState(null);
    // state for, search by date
    const [endDate, setEndDate] = useState(null);
    // state for, permitted start data for download
    const [permittedStartDate, setPermittedStartDate] = useState(null);
    // state for, permitted end data for download
    const [permittedEndDate, setPermittedEndDate] = useState(null);

    // selected
    const [companySelected, setCompanySelected] = useState([{ company_name: 'All' }]);
    const [categorySelected, setCategorySelected] = useState([{ name: 'All' }]);
    const [subCategorySelected, setSubCategorySelected] = useState([{ name: 'All' }]);

    // list
    const [company, setCompany] = useState([]);
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);

    // For All Set
    const [isAllCompany, setIsAllCompany] = useState(false);

    // state for, toggle loading status
    const [isLoading, setLoading] = useState(false);
    // state for, error status
    const [error, setError] = useState(false);

    //  methods for page overlay loading
    const { changeLoadingState, setLoadingMessage } = useContext(LoadingContext);

    //error messages
    const error_msg = {
        date_range_error: "Date range is required !",
        invalid_date_error: "Invalid date range !",
    };

    // fetching company & other data on page load
    const fetchData = async () => {
        changeLoadingState(true);
        setLoadingMessage("Loading... ...");

        let newsData;
        
        newsData = getFromStorage(type_cast[type].key);

        if(newsData === null){
            // fetch through api once
            newsData = await api.getNewsDataOnLoad(type_cast[type].type);
            // setting encrypted data to storage for avoiding api call
            saveOnStorage(type_cast[type].key, newsData);
        }

        setCompany(newsData?.company || []);
        setCategory(newsData?.category || []);
        setSubCategory(newsData?.sub_category || []);
        setIsAllCompany(newsData.is_all_company);

        // converting date to timestamp
        const permitted_start_date = new Date(newsData?.start_date * 1000);
        const permitted_end_date = new Date(newsData?.end_date * 1000);
        const today = new Date();

        setPermittedStartDate(permitted_start_date);
        setPermittedEndDate(today < permitted_end_date ? today : permitted_end_date);
        changeLoadingState(false);
    };

    useEffect(() => {
        fetchData();
    },
        // eslint-disable-next-line
    []);
    
    const handleStartDate = (date) => {
        setError(false); // for toggling red border in date range
        setStartDate(date);
    };

    const handleEndDate = (date) => {
        setEndDate(date);
    };

    // search company on keyUp
    const handleCompanySearch = async (user_typed) => {
        if(user_typed.length > 1){
            const companies = await api.searchNewsCompany(user_typed)
            setCompany(companies);
        } else if(user_typed.length === 0){
            setCompany([])
        }
    };

    // clear from data
    const handleClear = () => {
        setStartDate(null);
        setEndDate(null);
        setCompanySelected([{ company_name: 'All' }]);
        setCategorySelected([{ name: 'All' }]);
        setSubCategorySelected([{ name: 'All' }]);
        setLoading(false);
        setError(false);
        toast.dismiss();
    };

    /**
     * for generating item ids
     *
     * @handleSelected 
     * @param {array} list which api key needs to be check
     * @param {string} type Array of Ids for permission check
     * @returns {array} list of item ids
     */
    const handleSelected = (list, index) => {
        let item = [];
        let newsData = getFromStorage(type_cast[type].key);
        
        if ((newsData[`is_all_${index}`] && list.length === 0) 
            || list[0]?.[`${index}_name`] === 'All'
            || list[0]?.['name'] === 'All') {
            item.push(-9);
        }
        else if(list.length === 0) {
            item = newsData[index].map( (data) => {
                if(data[`${index}_id`]){
                    return data[`${index}_id`];
                } else {
                    return data['id'];
                }
            });
        }
        else {
            if (list[0]?.[`${index}_name`] === 'All') {
                item = newsData[index].map( (data) => {
                    if(data[`${index}_id`]){
                        return data[`${index}_id`];
                    } else {
                        return data['id'];
                    }
                });
            }
            else {
                item = list.map((data) => {
                    if(data[`${index}_id`]){
                        return data[`${index}_id`];
                    } else {
                        return data['id'];
                    }
                });
            }
        }
        return item;
    }

    // fetching json data & converting to excel on download button click
    const handleDownload = async () => {
        
        if( startDate === null || endDate === null ) {
            setError(true);
            // launch error notification
            return showNotification("error", error_msg.date_range_error);
        }
        else if(!isDateValid(startDate, endDate)) {
            setError(true);
            // launch error notification
            return showNotification("error", error_msg.invalid_date_error);
        }
        else if(startDate < permittedStartDate || endDate > permittedEndDate) {
            setError(true);
            // launch error notification
            return showNotification("error", error_msg.invalid_date_error);
        }
        
        // launching overlay loading
        changeLoadingState(true);

        // set downloading text
        setLoadingMessage("Downloading ... ...");

        setError(false);

        const payload = {
            type: type_cast[type].index,
            start_date: startDate,
            end_date: endDate,
            peak: [],
            campaign: [],
            company: handleSelected(companySelected, 'company'),
            category: handleSelected(categorySelected, 'category'),
            sub_category: handleSelected(subCategorySelected, 'sub_category')
        };

        // api call for fetching data
        const result = await api.fetchDownloadFile(payload);
        
        if(result?.flag === 'data_empty' || result?.flag === 'process_error'){
            // stop loading spinner
            changeLoadingState(false)
            // launch error notification
            return showNotification("error", result.message);
        } else {
            if(result?.data?.file.length > 0){
                result.data.file.forEach( (item, index) => {
                    // set the location of the generated excel to start downloading
                    window.open(`${process.env.REACT_APP_STATIC}static/d/u/${type_cast[type].index}/${result.data.uid}/${item.link}`);
                    if( (index+1) == result.data.file.length ) {
                        // stop loading spinner
                        changeLoadingState(false);
                    }
                })
            }
        }
    };

    return (
        <form className="form-center">
            <div className="form-container mt-4">

                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                
                {/* from & to date selection */}
                <div className="row mb-3 gx-5">
                    <div className="col-md-6 col-sm-12 sm-mb-1">
                        <FormDatePicker
                            label="From"
                            minDate={permittedStartDate}
                            maxDate={permittedEndDate}
                            state={startDate}
                            setState={handleStartDate}
                            error={error}
                        />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormDatePicker
                            label="To"
                            minDate={startDate}
                            maxDate={permittedEndDate}
                            state={endDate}
                            setState={handleEndDate}
                            error={error}
                        />
                    </div>
                </div>
                
                {/* company section */}
                <div className="row mb-3 gx-5">
                    <div className="col-md-12 col-sm-12 sm-mb-1">
                        {/* for selecting UI */}
                        {isAllCompany ? (
                            <FormTypeAndSearchInput
                                label="Company"
                                optionLabel="company_name"
                                placeholder="Type company name"
                                options={company}
                                state={companySelected}
                                setState={setCompanySelected}
                                handleSearch={handleCompanySearch}
                            />
                        ) : (
                            <FormMultipleSearchInput
                                label="Company"
                                optionLabel="company_name"
                                options={company}
                                state={companySelected}
                                setState={setCompanySelected}
                            />
                        )}
                    </div>
                </div>
                
                {/* category section */}
                <div className="row mb-3 gx-5">
                    <div className="col-md-6 col-sm-12 sm-mb-1">
                        {/* for selecting UI */}
                        <FormMultipleSearchInput
                            label="Category"
                            optionLabel="name"
                            options={category}
                            state={categorySelected}
                            setState={setCategorySelected}
                        />
                    </div>
                </div>

                {/* sub-category section */}
                <div className="row mb-3 gx-5">
                    <div className="col-md-6 col-sm-12 sm-mb-1">
                        {/* for selecting UI */}
                        <FormMultipleSearchInput
                            label="Sub Category"
                            optionLabel="name"
                            options={subCategory}
                            state={subCategorySelected}
                            setState={setSubCategorySelected}
                        />
                    </div>
                </div>

                

                <div className="d-flex justify-content-center mb-4">

                    <span className="me-2">
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<RefreshIcon />}
                            onClick={handleClear}
                        >
                            Reset
                        </Button>
                    </span>

                    <span>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#27606C !important",
                                "&:hover": {
                                    backgroundColor: "#0f3e47 !important",
                                },
                            }}
                            startIcon={<DownloadIcon />}
                            onClick={handleDownload}
                        >
                            Download
                        </Button>
                    </span>

                </div>

                {props.children}
            </div>
        </form>
    );
}
