import React from "react";
import {FileType, Icons} from "../../helpers/Constant";
import {LoadingContext} from "../../../App";
import {AlertServicePage} from "../../helpers/InlineStyle";
import { GetAndDownload } from "../../api/request/FetchDownloadFIle";

export const Attachments = ({fileType, data, desc}) => {
    const pageLoading = React.useContext(LoadingContext)
    let image = Icons.pdf;
    switch(fileType) {
        case FileType.pdf:
            image = Icons.pdf;
            break;
        case FileType.excel:
            image = Icons.excel;
            break;
        case FileType.ppt:
            image = Icons.ppt;
            break;
        case FileType.zip:
            image = Icons.zip;
            break;
        case FileType.docx:
            image = Icons.docx;
            break;
        default:
            break;
    }

    const changeLoading = (status) => {
        pageLoading.changeLoadingState(status)
    }
    
    return(
        <>
            <div className="col-6 col-md-3">
                <div className="attach">
                    <div className="row">
                        <div className="col-md-3">
                            <img src={image} alt="" className="img-fluid search-icon-wh" />
                        </div>
                        <div className="col-md-6">
                            <div className="font-size-12 font-weight-400">
                                <div className="font-prim" style={{cursor: "pointer"}}>
                                    {data[0].title}
                                </div>
                            </div>
                            <div className="font-size-12 font-weight-400 sm-d-font-w-100 ">
                                <span 
                                    onClick={ async () => {
                                        const filter = {
                                            id: typeof desc.id === "string" ? desc.id : 0,
                                            path: typeof data[0].path === "string" ? data[0].path : 't',
                                            type: typeof fileType === "string" ? fileType : 't',
                                            alert_flag: desc.alert_flag
                                        }
                                        changeLoading(true);
                                        const status = await GetAndDownload(filter);
                                        changeLoading(!status);
                                    }} 
                                    style={AlertServicePage.download}> 
                                        Download
                                </span>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </div>
            
        </>
    )

}