import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { AD_REPORT } from "../../../helpers/dataservice/Settings";
import AdvMediaCategories from "./components/AdvMediaCategories";
import AdvTVDataForm from "./forms/AdvTVDataForm";

function AdvTvMediaDataPage() {
    document.title = "Data - TV Media";

    return (
        <div className="content-section">
            <div className="container">
                <AdvMediaCategories />

                <h4 className="category-title">
                    TV Media (Advertisement Data)
                </h4>

                <AdvTVDataForm dataformFor={AD_REPORT.TV}/>
                
            </div>
        </div>
    );
}
export default AdvTvMediaDataPage;
