/* eslint-disable */

import React from "react"
import utf8 from "utf8"
import { createLinks } from './Links'
import { getCSSPropertyToValue } from "../../../../helpers/Methods"

// display/Generate main news here
export const GenerateMainAlert = (data, companyInfo={}, type, index, clickedMethod) => {   
    let linksDiv = ""
    let printDiv = ""
    let onlineDiv = ""
    let tv_radioDiv = ""

    let linksLength = 0
    let printLength = 0
    let onlineLength = 0
    let tv_radioLength = 0

    // set default compnay bg color
    let company_bg = '#000';

    // assign company bg color
    if(companyInfo.bg){
        company_bg = `#${getCSSPropertyToValue(companyInfo.bg)}`
    }

    // if links exists
    if (typeof data.link === 'object') {
        linksDiv = createLinks(data.link.info, clickedMethod)
        linksLength = data.link.info.length
    }
    
    // if print exists
    if (typeof data.print === 'object') {
        printDiv = createLinks(data.print.info, clickedMethod)
        printLength = data.print.info.length
    }
    
    // if online links exists
    if (typeof data.online === 'object') {
        onlineDiv = createLinks(data.online.info, clickedMethod)
        onlineLength = data.online.info.length
    }

    // if tv/radio links exists
    if (typeof data.tv_radio === 'object') {
        tv_radioDiv = createLinks(data.tv_radio.info, clickedMethod)
        tv_radioLength = data.tv_radio.info.length
    }
    
    // check is image exists on main section
    let img_exists = (typeof data.main_img === 'string' && data.main_img.length !=0) ? true : false

    // make row width adjust if image is exists
    let row_will_be = (img_exists) ? 'col-md-9' : 'col-md-12'
    

    return (
        <div>

            {/* if its first item of list, then show the company name with BG */}
            {(index == 0)?(
                <div style={{textAlign: "left", marginBottom: "0.5rem" }}>
                    <span style={{background: company_bg, color:"#fff", padding: "0.3rem", display:"block", width:"100%"}}>
                        {(companyInfo.logo) ? (
                            <img src={companyInfo.logo} style={{width:"15px", height:"15px", marginRight: '0.5rem'}} />
                        ) : ''  }
                        {companyInfo.name}
                    </span>
                </div>
            ) : ''}

            <div>
                {/* <h6>{utf8.decode(data.title)}</h6> */}
                <h5 className="single-alert-title">{data.title}</h5>
            </div>

            {/* if synopsis exists then print */}
            {(data.synopsis)?(
                <div className="row">
                    {(img_exists)?(
                        <div className="col-md-6 pt-1 offset-md-3">
                            <img className="img-fluid" src={data.main_img}/>
                        </div>
                    ) : "" }
                    <div className="col-md-12 pt-1">
                        <strong>Synopsis : </strong>
                        <span className="single-alert-details">
                            {data.synopsis}
                        </span>
                    </div>
                </div>
            ) : '' }

            {/* if synopsis 2 exists then print */}
            {(data.synopsis2)?(
                <div className="pt-2">
                    <span className="single-alert-details">
                        {data.synopsis2}
                    </span>
                </div>
            ) : '' }
            
            {/* Its shows when links exists */}
            { (linksLength > 0) ? (
                <div className="row text-align-right">
                    <div className="pt-1" style={{wordWrap:'break-word'}}>
                        <span>Link : </span>
                        { linksDiv }
                        <span className="text-dark">
                            ({linksLength})
                        </span>
                    </div>
                </div>
            ) : '' }

            {/* Its shows when print exists */}
            { (printLength > 0) ? (
                <div className="row text-align-right">
                    <div className="pt-1" style={{wordWrap:'break-word'}}>
                        <span>Print :</span>
                        {printDiv}
                        <span className="text-dark">
                            ({printLength})
                        </span>
                    </div>
                </div>
            ) : '' }
            
            {/* Its shows when online exists */}
            { (onlineLength > 0) ? (
                <div className="row text-align-right">
                    <div className="pt-1" style={{wordWrap:'break-word'}}>
                        <span>Online:</span>
                        {onlineDiv}
                        <span className="text-dark">
                            ({onlineLength})
                        </span>
                    </div>
                </div>
            ) : '' }

            {/* Its shows when tv/radio exists */}
            { (tv_radioLength > 0) ? (
                <div className="row text-align-right">
                    <div className="pt-1" style={{wordWrap:'break-word'}}>
                        <span>Tv/Radio:</span>
                        {tv_radioDiv} 
                        <span className="text-dark">
                            ({tv_radioLength})
                        </span>
                    </div>
                </div>
            ) : '' }
            <hr/>
        </div>)
}

