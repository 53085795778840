import { TextField } from "@mui/material";
import { useContext } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { LoadingContext } from "../../../App";
import * as api from "../../api/request/ClientAuthAPI";
import { showNotification } from "../../helpers/showNotification";

function ForgetPasswordComponent() {
    const history = useHistory();
    const initialState = Object.freeze({
        email: "",
        name: "",
        contact: "",
        remarks: "",
    });

    const [formData, setFormData] = useState(initialState);
    const { changeLoadingState } = useContext(LoadingContext)

    const handleChange = (e) => {
        e.preventDefault();

        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        let isValid = true
        const errors = [null, undefined, ""]

        Object.keys(formData).forEach(each => {
            if(errors.includes(formData[each])){
                isValid = false
            }
        })

        if(!isValid){
            return showNotification("error", "All fields are required")
        }
        
        toast.dismiss()
        changeLoadingState(true)
        const res = await api.forgetPassword(formData);
        if (res.flag === "success") {
            setFormData(initialState);
            showNotification("success", res.message)
        } else {
            showNotification("error", res.message)
        }
        changeLoadingState(false)
    };

    return (
        <>
            {/* error notification container */}
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="page-bg">
                <div className="register-container row">
                    <div className="col-md-7 col-sm-12">
                        <div className="design-register-wrapper">
                            <div className="img-register-wrapper">
                                <img
                                    className="max-h70"
                                    src={
                                        window.location.origin +
                                        "/assets/images/login/forgot_password.png"
                                    }
                                    alt="Forgot Password"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-sm-12 m-ps-4 s-ps-0 d-flex flex-column justify-content-between">
                        <h4 className="text-center mb-3 s-mt-1 forgot-title">
                            Forgot Password :(
                        </h4>
                        <TextField
                            className="mb-3"
                            fullWidth
                            autoComplete="off"
                            label="Email Address"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            InputLabelProps={{
                                style: { color: "#27606C" },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": {
                                        borderColor: "#27606C",
                                    },
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                    "& > fieldset": {
                                        borderColor: "#27606C",
                                        "& > legend": {
                                            color: "#27606C",
                                        },
                                    },
                                },
                            }}
                        />
                        <TextField
                            className="mb-3"
                            fullWidth
                            autoComplete="off"
                            label="Company Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            InputLabelProps={{
                                style: { color: "#27606C" },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": {
                                        borderColor: "#27606C",
                                    },
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                    "& > fieldset": {
                                        borderColor: "#27606C",
                                        "& > legend": {
                                            color: "#27606C",
                                        },
                                    },
                                },
                            }}
                        />
                        <TextField
                            className="mb-3"
                            fullWidth
                            autoComplete="off"
                            label="Contact No."
                            name="contact"
                            value={formData.contact}
                            onChange={handleChange}
                            InputLabelProps={{
                                style: { color: "#27606C" },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": {
                                        borderColor: "#27606C",
                                    },
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                    "& > fieldset": {
                                        borderColor: "#27606C",
                                        "& > legend": {
                                            color: "#27606C",
                                        },
                                    },
                                },
                            }}
                        />
                        <TextField
                            className="mb-3"
                            fullWidth
                            autoComplete="off"
                            label="Remarks"
                            multiline
                            rows={4}
                            name="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                            InputLabelProps={{
                                style: { color: "#27606C" },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": {
                                        borderColor: "#27606C",
                                    },
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                    "& > fieldset": {
                                        borderColor: "#27606C",
                                        "& > legend": {
                                            color: "#27606C",
                                        },
                                    },
                                },
                            }}
                        />
                        <div className="row">
                            <div className="col-md-6 col-sm-12 d-flex flex-column s-mb">
                                <button
                                    className="register-btn"
                                    onClick={handleSubmit}
                                >
                                    Request For Password
                                </button>
                            </div>
                            <div className="col-md-6 col-sm-12 d-flex flex-column s-mb">
                                <button
                                    className="login-back-btn"
                                    onClick={() => history.push("/")}
                                >
                                    Back To Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgetPasswordComponent;
