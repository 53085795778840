import AdvMediaCategories from "./components/AdvMediaCategories";
import AdvOnlineDataForm from "./forms/AdvOnlineDataForm";

function AdvOnlineMediaDataPage(){
    document.title = "Data - Online Media"

    return(
        <div className="content-section">
            <div className="container">
                <AdvMediaCategories />
                <h4 className="category-title">
                    Online Media (Advertisement Data)
                </h4>
                <AdvOnlineDataForm />
            </div>
        </div>
    )
}

export default AdvOnlineMediaDataPage;