export const ReadableDateTime = (timestamp) => {
    let date = new Date(timestamp*1000)
    return date.toLocaleDateString('en-us', 
        { weekday:"short", year:"numeric", month:"short", day:"numeric" }
    ) + " " + date.toLocaleTimeString('en-us')
}
export const CurrentDateTimeToUnix = () => {
    return Math.floor(new Date().getTime() / 1000)
}
export const AddedTimeWithCurrentDateTime = (minutes) => {
    return CurrentDateTimeToUnix() + minutes*60;
}