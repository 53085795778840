/* eslint-disable */

import React from "react"
import {NavLink} from "react-router-dom"
import LoadingOverlay from 'react-loading-overlay'
import { Icons } from "../../../helpers/Constant"
import data from '../../../storage/data/html_news_alert_daily.json'
import { GenerateMainAlert } from '../../../helpers/alerts/classic/DailyHtmlAlert/Main'
import { GenerateIndustryAlert } from '../../../helpers/alerts/classic/DailyHtmlAlert/Industry'
import { DisplayOpponent } from '../../../helpers/alerts/classic/DailyHtmlAlert/Compitator'
import { DisplayPrintOnline } from '../../../helpers/alerts/classic/DailyHtmlAlert/GeneratePrintOnline'


function DailyNews() {

    // set current browser title
    document.title = "DailyNews - Classic"
    
	// state for page loading indicator
	let [loading, setLoading] = React.useState(false)
    let [alert, setAlert] = React.useState(data.main.company_name)
    let [banner, setBanner] = React.useState(data.banner)
    let [created, setCreated] = React.useState(data.created_at)

    // assign main alert data 
    let [main, setMain] = React.useState(data.main.data)
    // assign opponent alert data
    let [opponent, setOpponent] = React.useState(data.mid)
    // assign industry alert data
    let [industry, setIndustry] = React.useState(data.industry.data)
    // assign print ad alert data
    let [printAd, setPrintAd] = React.useState(data.print_advertise)
    // assign online ad alert data
    let [onlineAd, setOnlineAd] = React.useState(data.online_advertise)
    
    
    // company name for own/main section
    let [mainCompanyInfo, setMainCompanyInfo] = React.useState({
        'name': data.main.company_name,
        'bg': data.main.company_bg,
        'logo': data.main.company_logo,
    })

    // industry name for industry section
    let [industryCompanyInfo, setIndustryCompanyInfo] = React.useState({
        'name': data.industry.company_name,
        'bg': data.industry.company_bg,
        'logo': '',
    })

    React.useEffect( () => {},[])

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Fetching Data ... ...' >
                <div style={{minHeight:100+'vh'}}>
                    <div className="top-bar bg-prim-2">
                        <div className="container">
                            <div id="overlay"></div>
                            <div className="row min-height-50px">
                                <div className="col-3 col-md-2 item-center min-height-50px">
                                    <img src={window.location.origin+"/assets/images/icons/home.svg"} alt="" className="img-fluid icon-15  sm-d-dn" />
                                    <div className="px-2">
                                        <NavLink to="/portal/home">
                                            <strong className="font-white  sm-d-font-12">Dashboard</strong>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 border-left item-center min-height-50px">
                                    <a href="/alert-service.html">
                                        <strong className="font-white sm-d-font-12">HTML Alert Test</strong>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="container">
                            <div className="row py-4 mx-1 mx-md-0">
                                <div className="col-md-8 my-md-3 py-2 alert-details-block">

                                    <div className="row py-3">
                                        <div className="col-2 col-md-1 alert-img">
                                            <div className="alert-img">
                                                <img src={Icons.roundBlue} alt="" className="img-fluid search-icon-wh"/>
                                            </div>
                                        </div>
                                        <div className="col-10 col-md-8">
                                            <div className="alert-title font-prim">
                                                Daily Html alert
                                            </div>
                                            <div className="alert-from font-soft-gray-2 font-size-12">From: Ryans Archives Report Team</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="alert-date-time">
                                                <strong className="font-soft-gray-2 font-size-12">
                                                    28 July 2021 | 10:25 PM
                                                    {created}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <img src={banner} style={{width: '100%', height: '175px'}}/>

                                    <div className="col-md-12 py-3 alert-detail">

                                        {/* Display main alert here */}
                                        <p className="font-size-14 text-align-justify font-weight-400">
                                            {main.map((data, index) => (
                                                GenerateMainAlert(data, mainCompanyInfo, 'main', index)
                                            ))}
                                        </p>

                                        {/* Display compitator alert here */}
                                        <p className="font-size-14 text-align-justify font-weight-400">
                                            {opponent.map((data) => (
                                                data.data.map((info, index) => (
                                                    DisplayOpponent(info, 
                                                        {'company_name':data.company_name, 
                                                        'company_logo': data.company_logo,
                                                        'company_bg': data.company_bg}, index)
                                                ))
                                            ))}
                                        </p>

                                        {/* Display industry alert here */}
                                        <p className="font-size-14 text-align-justify font-weight-400">
                                            {industry.map((data, index) => (
                                                GenerateIndustryAlert(data, industryCompanyInfo, 'industry', index)
                                            ))}
                                        </p>

                                        {/* Display print ad alert here */}
                                        <p className="font-size-14 text-align-justify font-weight-400">
                                            {printAd.map((data) => (
                                                data.data.map((info) => (
                                                    DisplayPrintOnline(
                                                        info, 
                                                        {'company_name':data.company_name, 
                                                        'company_logo': data.company_logo,
                                                        'company_bg': data.company_bg},
                                                        'Print Ad')
                                                ))
                                            ))}
                                        </p>

                                        {/* Display online ad alert here */}
                                        <p className="font-size-14 text-align-justify font-weight-400">
                                            {onlineAd.map((data) => (
                                                data.data.map((info) => (
                                                    DisplayPrintOnline(
                                                        info, 
                                                        {'company_name':data.company_name,'company_logo': data.company_logo,'company_bg': data.company_bg},'Online Ad')
                                                ))
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default DailyNews