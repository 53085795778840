/**
 * Checks date range are valid or not
 * 
 * @method isDateValid
 * @param {Date} from_date start date
 * @param {Date} to_date end date
 * @returns boolean
 */
const isDateValid = (from_date, to_date) => {
	return (
		from_date instanceof Date && !isNaN(from_date) &&
		to_date instanceof Date && !isNaN(to_date)
	)
}

export default isDateValid