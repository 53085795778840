import React from "react";
import "./instruction.css";

export default function ChromeInstruction() {
    return (
        <div className="instruction__container">
            <div className="instruction__fullpage">
                <div className="instruction__label-group">
                    <label className="instruction__circle">1</label>
                    <span className="instruction__title">
                        First visit to <strong>ryansmedia.com</strong>
                    </span>
                </div>
                <img
                    className="instruction__img"
                    src={
                        window.location.origin +
                        "/assets/images/instruction/chrome/chrome-1.png"
                    }
                    alt="Instruction"
                />
            </div>

            <div className="instruction__fullpage">
                <div className="instruction__label-group">
                    <label className="instruction__circle">2</label>
                    <span className="instruction__title">
                        Go to <strong>Site Settings</strong> by clicking the{" "}
                        <strong>Lock Button</strong> seen in this picture
                    </span>
                </div>
                <img
                    className="instruction__img"
                    src={
                        window.location.origin +
                        "/assets/images/instruction/chrome/chrome-2.png"
                    }
                    alt="Instruction"
                />
            </div>

            <div className="instruction__fullpage">
                <div className="instruction__label-group">
                    <label className="instruction__circle">3</label>
                    <span className="instruction__title">
                        <strong>Site Settings</strong> page will be opened
                        in a <strong>new tab</strong>
                    </span>
                </div>
                <img
                    className="instruction__img"
                    src={
                        window.location.origin +
                        "/assets/images/instruction/chrome/chrome-3.png"
                    }
                    alt="Instruction"
                />
            </div>
            <div className="instruction__fullpage">
                <div className="instruction__label-group">
                    <label className="instruction__circle">4</label>
                    <span className="instruction__title">
                        Scroll down a bit and change Block (default) to
                        Allow of <strong>Pop ups and redirects</strong>.
                    </span>
                </div>
                <img
                    className="instruction__img"
                    src={
                        window.location.origin +
                        "/assets/images/instruction/chrome/chrome-4.png"
                    }
                    alt="Instruction"
                />
            </div>

            <div className="instruction__fullpage">
                <div className="instruction__label-group">
                    <label className="instruction__circle">5</label>
                    <span className="instruction__title">
                        Also change the <strong>Automatic Downloads</strong>{" "}
                        from Ask (Default) to Allow
                    </span>
                </div>
                <img
                    className="instruction__img"
                    src={
                        window.location.origin +
                        "/assets/images/instruction/chrome/chrome-5.png"
                    }
                    alt="Instruction"
                />
            </div>

            <div className="instruction__fullpage">
                <div className="instruction__label-group">
                    <label className="instruction__circle">6</label>
                    <span className="instruction__title">
                        Now go back to the main site & press the{" "}
                        <strong>Reload button</strong> to save changes
                    </span>
                </div>
                <img
                    className="instruction__img"
                    src={
                        window.location.origin +
                        "/assets/images/instruction/chrome/chrome-6.png"
                    }
                    alt="Instruction"
                />
            </div>
        </div>
    );
}
