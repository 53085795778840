/* eslint-disable */

import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import { Icons } from "../../../helpers/Constant"
import data from '../../../storage/data/html_daily_advertise_alert.json';
import { getCSSPropertyToValue } from "../../../helpers/Methods"
import { getStore, updateAlertStore } from "../../../helpers/Storage"
import {ReadableDateTime} from "../../../helpers/DateTime"
import { GetJSONAlert } from "../../../api/request/FetchDownloadFIle"
import { MakeAlertRead } from "../../../api/request/AlertServiceAPI"
import { NavLink, useHistory } from "react-router-dom";

// Daily/Periodical Adv
function DailyAdvClassic(doc_id){
    // doc id    
    const ID = doc_id.doc_id

    document.title = "Advertisement Alert" 
    
	// state for page loading indicator
	let [loading, setLoading] = React.useState(false)
    let [alert, setAlert] = React.useState(data.company)
    
    // alert banner image
    let [banner, setBanner] = React.useState('')
    
    // alert generated datetime
    let [created, setCreated] = React.useState('')

    let [video, setVideo] = React.useState('')
    let [videoTitle, setVideoTitle] = React.useState('')
    // video ad url
    let [adType, setAdType] = React.useState(1) // 1= youtube, 2= image
    // image ad url
    let [imageUrl, setImageUrl] = React.useState('')
    // Alert title
    let [alert_title, setAlertTitle] = React.useState('')
    // fetch individual media data
    let [televisionData, setTelevisionData] = React.useState([])
    let [radioData, setRadioData] = React.useState([])
    let [printMediaData, setPrintMediaData] = React.useState([])
    let [onlineMediaData, setOnlineMediaData] = React.useState([])
    // handle scroll class
    const [scrollClass, setScrollClass] = React.useState('')

    // for jumping to another alert
    const history = useHistory()

    const fetchAlertservice = () => {
        // fetch storage alert service data if exists
        const alertService = getStore('alert_service') || []
        return alertService
    }

    const getAlertServiceById = (_id) => {
        if(_id){
            const alert = fetchAlertservice()
            let dt = {}
            if(alert){
                // setCreated(ReadableDateTime(alert.created))
                let service_data = alert.value
                for(let i=0; i<service_data.length; i++){
                    if(service_data[i]._id === _id || service_data[i].doc_id === _id){
                        // set alert created dateTime
                        setCreated(ReadableDateTime(service_data[i].created_at))
                        dt = service_data[i]
                        break;
                    }
                }
            }
            return dt
        } else {
            return null
        }
    }

    // get json alert 
    const fetchJSONFile = async () => {
        // get json alert
        let dt = getAlertServiceById(ID)

        // obj
        let obj = JSON.parse(dt.json)

        // json file url
        let JSONFileUrl = obj[0].path

        // json file url array
        let json_file_arr = JSONFileUrl.split("/")

        // json object
        let data = { 
            id: ID,
            s_id: json_file_arr[json_file_arr.length-2],  
            file: json_file_arr[json_file_arr.length-1]
        }

        // make an api call for fetch data
        const result = await GetJSONAlert(data)

        // make an api call for make alert unread to read
        const read = await MakeAlertRead(dt.alert_id)

        // update alert open at status
        updateAlertStore(dt._id, 'open_at', 111)
        // update alert new status
        updateAlertStore(dt._id, 'new', 0)
        // update alert unread status
        updateAlertStore(dt._id, 'unread', 0)
        
        // close loading indcator
        setLoading(false)

        // return result
        return result.data
    }

    React.useEffect( async () => {
        // fetch json data
        const dt = await fetchJSONFile()
        
        // set alert file title
        setAlertTitle(dt.title)

        // set alert banner img
        setBanner(dt.banner)
        
        // set television data
        setTelevisionData(dt.Television)
        
        // set radio data
        setRadioData(dt.Radio)

        // set print media data
        setPrintMediaData(dt.Print_Media)

        // set online media data
        setOnlineMediaData(dt.Online_Media)
        // setOnlineAd(dt.online_advertise)
        // setMainCompanyInfo({
        //     'name': dt.main.company_name,
        //     'bg': dt.main.company_bg,
        //     'logo': dt.main.company_logo,
        // })
        // setIndustryCompanyInfo({
        //     'name': dt.industry.company_name,
        //     'bg': dt.industry.company_bg,
        //     'logo': '',
        // })
        
        window.scrollTo(0, 0)
        window.addEventListener('scroll', () => {
            if(window.scrollY == 0){
                setScrollClass('')
            } else {
                setScrollClass('top-0 height-90')
            }
        })

        return () => {
            window.removeEventListener('scroll')
        }
    },[])

    // set model iframe data and ad url
    const changeModelData = (data) => {
        let [link, channel] = data

        // get array of given link
        let video_url_arr = link.split('/')
        
        // set media name
        setVideoTitle(channel)

        // console.log(link, video_url_arr)

        if (video_url_arr.length === 4) {
            // set media type as youtube video
            setAdType(1)

            // get only youtube video
            let video_code = video_url_arr[video_url_arr.length-1]
            
            // set youtube video code for iframe
            setVideo(video_code)
        } else {
            // set media type as image
            setAdType(2)
            
            // set image url for iframe
            setImageUrl(link)
        }
    }

    const withLinked = (link) => {
        let i=0;
        let view = link.map((data)=>{
            i++
            return(
                <>
                    {(i==1) ? (
                        <span 
                            className="p-1 alert-links"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={()=>{changeModelData(data)}}
                            style={{color:'#000',backgroundColor:'#e4e4e4', marginRight:"0.3rem", border:"1px solid #c4c2c2", fontSize:'0.6rem', fontWeight:"bold"}}>
                                {data[1]}
                        </span>
                    ) : (
                        <strong>
                            <small className="alert-links">
                                &nbsp;{data[1]}&nbsp;,
                            </small>
                        </strong>
                    )}
                </>
            )
        })
        return view
    }

    const generateTitle = (data, border_color = "grey") => {
        return(
            <>
                <div 
                    className="col-md-12 alert-title-box d-flex flex-column" 
                    style={{wordWrap:'break-word', borderBottom: `2px solid ${border_color}`, borderRadius: '0 0 0.3rem 0.3rem' }}
                >
                    <strong className="flex-1 text-align-center mb-1" style={{ fontWeight:'800' }}>
                        {(data.link.length !== 0) ? (
                            <small>{data.title}</small>
                        ) : (
                            <small className="no-periodical-ad">{data.title}</small>
                        )}  
                    </strong>
                    <div className="text-align-right">
                        {(data.link.length === 0) ? '' : withLinked(data.link)}
                    </div>
                </div>
            </>
        )
    }

    const displayData = (data) => {
        // get logo
        let logo = data.info.logo

        // get bg color
        let title_bg = `#${getCSSPropertyToValue(data.info.bg)}`
        
        return (
            <>
                <div className={`col-md-12`}>
                    <div className="col-md-12 px-2" style={{backgroundColor:title_bg, color:"#fff", textAlign:""}}>
                        <img src={logo} style={{width:"15px", height:"15px", marginRight:"0.5rem"}} />
                        <small>{data.info.name}</small>
                    </div>
                    <div className="col-md-12">
                        {data.details.map((alert) =>(
                            generateTitle(alert, title_bg)
                        ))}
                    </div>
                </div>
            </>
        )
    }
    
    // set loading image
    const clearIframeSource = () => {
        setVideo(window.location.origin+"/assets/images/loading.gif")
        setImageUrl(window.location.origin+"/assets/images/loading.gif")
    }
    
    return(
        <>
            <LoadingOverlay active={loading} spinner text='Fetching Data ... ...' >
            <div className="scroll__to-top" onClick={() => window.scrollTo(0,0)}>
                <img src={ window.location.origin + "/assets/images/up-arrow.png" } />
            </div>
                <div style={{ minHeight:100+'vh' }}>

                    <div className="content-section">
                        <div className="container">
                            <div className="row mx-1">
                                <div className="col-md-9 my-md-3 py-2 alert-details-block">
                                    
                                <div className="row border-bottom-gray py-3">
                                        <div className="col-2 col-md-1 alert-img">
                                            <div className="alert-img">
                                                <img src={Icons.roundBlue} alt="" className="img-fluid search-icon-wh" />
                                            </div>
                                        </div>
                                        <div className="col-10 col-md-8">
                                            <div className="alert-title font-prim">{alert_title}</div>
                                            <div className="alert-from font-soft-gray-2 font-size-12">From: Ryans Archives Limited</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="alert-date-time">
                                                <strong className="font-soft-gray-2 font-size-12">
                                                    {created}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <img
                                                src={banner}
                                                alt="banner"
                                                style={{width: '100%', height: '175px', marginTop: '0.5rem'}}
                                            />
                                        </div>
                                    </div>
                                    
                                    {/* using flex for flexible columns with flex grow 1 */}
                                    <div className="alert-media-block">
                                        <div className="alert-media-section">
                                            <div className="alert-media">Television</div>

                                            {/* display television column if exists */}
                                            {televisionData.map((alert) => (
                                                displayData(alert)
                                            ))}
                                        </div>
                                        <div className="alert-media-section">
                                            <div className="alert-media">Radio</div>

                                            {/* display radio column if exists */}
                                            {radioData.map((alert) => (
                                                displayData(alert)
                                            ))}
                                        </div>
                                        <div className="alert-media-section">
                                            <div className="alert-media">Print Media</div>

                                            {/* display print media column if exists */}
                                            {printMediaData.map((alert) => (
                                                displayData(alert)
                                            ))}
                                        </div>
                                        <div className="alert-media-section">
                                            <div className="alert-media">Online Media</div>

                                            {/* display online media column if exists */}
                                            {onlineMediaData.map((alert) => (
                                                displayData(alert)
                                            ))}
                                        </div>

                                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">
                                                            {videoTitle}
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="btn-close"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                            onClick={clearIframeSource}>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {(adType == 1) ? (
                                                            <iframe
                                                                title="load ad video"
                                                                id="cartoonVideo"
                                                                className="embed-responsive-item"
                                                                style={{width:"100%", height:"25rem"}}
                                                                src={`//www.youtube.com/embed/${video}`} allowFullScreen>
                                                            </iframe>
                                                        ):(
                                                            <iframe
                                                                title="load ad image"
                                                                id="cartoonVideo"
                                                                className="embed-responsive-item"
                                                                style={{width:"100%", height:"25rem"}}
                                                                src={imageUrl}>
                                                            </iframe>
                                                        )}
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            data-bs-dismiss="modal"
                                                            onClick={clearIframeSource}>
                                                                X
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-md-3 my-md-3 py-2 alert-sidebar-ad ${scrollClass}`}>
                                    <NavLink to="/portal/alert-service">
                                        <h5 className="font-bg text-center mt-1">Recent Alerts</h5>
                                    </NavLink>
                                    <div className="alert-suggestion">
                                        {fetchAlertservice()?.value
                                            .filter(each => each.doc_id !== ID)
                                            .slice(0, 15)
                                            .map(each => (
                                                each.doc_id && each.alert_flag !== 'customAlert'  ?
                                                (
                                                    <a
                                                        className="alert-sug-title-link"
                                                        href={`${
                                                            (each.type === 2) 
                                                            ? '/portal/json-alert-details' 
                                                            : '/portal/alert-details'}/${each.doc_id}`}
                                                    >
                                                        <div className="alert-sug-title">
                                                            {each.title}
                                                        </div>
                                                    </a>
                                                ) : (
                                                    <a
                                                        className="alert-sug-title-link"
                                                        href={`${
                                                            (each.type === 2) 
                                                            ? '/portal/json-alert-details' 
                                                            : '/portal/alert-details'}/${each._id}`}
                                                    >
                                                        <div className="alert-sug-title">
                                                            {(each.name) ? each.name:each.title}
                                                        </div>
                                                    </a>
                                                )
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default DailyAdvClassic;
