import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Paper } from "@mui/material";

const CustomPaper = (props) => {
    return (
        <Paper
            {...props}
            elevation={5}
            sx={{
                fontSize: "0.8rem !important",
                lineHeight: "1rem !important",
            }}
        />
    );
};

export default function FormSearchInput({
    label,
    options = [],
    state,
    setState,
    setMoreOption = () => {},
}) {
    return (
        <Autocomplete
            disablePortal
            autoHighlight
            size="small"
            id="combo-box-demo"
            options={options}
            PaperComponent={CustomPaper}
            sx={(theme) => ({
                width: 400,
                [theme.breakpoints.down('sm')]: {
                    width: 'calc(100vw - 3rem)'
                },
                "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                        borderColor: "#27606C",
                    },
                },
                "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                        border: "2px solid #27606C",
                    },
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                        borderColor: "#27606C",
                        "& > legend": {
                            color: "#27606C",
                        },
                    },
                },
                "& .MuiSvgIcon-root": {
                    color:"#27606C"
                }
            })}
            value={state}
            onChange={(e, newValue) => {
                setState(newValue)
                setMoreOption(false)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label={label}
                    InputLabelProps={{
                        style: { color: "#27606C", },
                    }}
                />
            )}
        />
    );
}
