const ORIGIN = window.location.origin;
const ICONS_DIR = `${ORIGIN}/assets/images/icons`;

export const FileType = {
    'pdf':'PDF', 
    'ppt':'PPT', 
    'excel':'EXCEL', 
    'zip':'ZIP',
    'docx': 'DOCX',
    'json': 'JSON'
}

export const Icons = {
    "attachment": `${ICONS_DIR}/paper-clip.svg`,
    "roundBlue": `${ICONS_DIR}/round-blue.svg`,
    "pdf": `${ICONS_DIR}/pdf.svg`,
    "excel": `${ICONS_DIR}/file.svg`,
    "zip": `${ICONS_DIR}/zip-folder.svg`,
    "ppt": `${ICONS_DIR}/ppt.svg`,
    "docx": `${ICONS_DIR}/docx.svg`,
}

export const ContentType = {
    "textHtml": "text/html; charset=UTF-8",
    "multiPart": "ultipart/form-data; boundary=---------------------------974767299852498929531610575",
    'urlEncoded': 'application/x-www-form-urlencoded',
    'json': "application/json"
}

export const MIMETypes = {
    "PDF":  "data:application/pdf;base64, ",
    "PPT":  "data:application/vnd.ms-powerpoint;base64, ",
    "EXCEL":  "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ",
    "DOC":  "data:application/msword;base64, ",
    "ZIP":  "data:application/zip;base64, ",
    "7Z": "data:application/x-7z-compressed;base64",
    "html": "data:text/html"
}