import React from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import { setCookies, removeCookies } from "../../helpers/Cookies";
import TextField from '@mui/material/TextField';
import { removeStorages } from "../../helpers/Storage";

function LoginComponent(){
    document.title="Portal - login";
    let [loading, setLoading] = React.useState(false);
    let [errorMsg, setErrorMsg] = React.useState([]);
    
    // initial user login data
    const initialFormData = Object.freeze({
        email: '',
        password: ''
    });

    // 
    const [formData, updateFormData] = React.useState(initialFormData);

    // invoke when write on input field
    const handleChange = (e) => {
        updateFormData({ ...formData, [e.target.name]: e.target.value.trim() })
    }

    // invoke when form submited
    const handleSubmit = (e) => {
        let user_data = {}
        e.preventDefault();
        setErrorMsg([])
        setLoading(true)
        const LOGIN_URL = `${process.env.REACT_APP_API_ENDPOINT}client/user/login`
        axios.post(LOGIN_URL, formData)
            .then(res => {
                setLoading(false)
                switch(res.data.status){
                    case "Fail":
                        setErrorMsg(errorMsg => [...errorMsg, res.data.msg])
                        break;
                    case "error":
                        res.data.msg.forEach(data => {
                            setErrorMsg(errorMsg => [...errorMsg, data.msg])
                        })
                        break;
                    case "Success":
                        removeStorages()
                        // get login user data
                        if(!!res.data.data){
                            user_data.client = {
                                row: res.data.data.user._id,
                                user: res.data.data.user.uid,
                                email: res.data.data.user.email,
                                full_name: res.data.data.user.full_name.first_name +' '+res.data.data.user.full_name.last_name,
                            }
                            user_data.service = {
                                general: res.data.data.access.g_service,
                                id: res.data.data.access.accessId,
                                list: res.data.data.access.service,
                            }
                            user_data.access = {
                                token:res.data.data.token,
                            }
                            user_data.csrf = {
                                token: 'CSRF_TOKEN',
                            }
                            
                            // set login data to cookies
                            setCookies('udata', user_data, {path:'/'})
                            
                            // admin data get successfully, redirect to admin dashboard
                            window.location.href = '/portal/home'
                        } else {
                            removeCookies('udata', {path:'/'})
                        }
                        break;
                    default:
                        setErrorMsg([])
                }
                
            })
            .catch(err => {
                setLoading(false)
                // console.log('Error ', err.data)
            })
    }
    
    return(
        <>
            <LoadingOverlay active={loading} spinner text='Requesting ... ...'>
                <div className="page-bg">
                    <div className="login-container row">
                        <div className="col-md-7 col-sm-12 s-design-wrapper d-flex">
                            <div className="design-wrapper">
                                <div className="img-wrapper">
                                    <img className="img-responsive" src="./assets/images/login/login.png" alt="Login" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12 d-flex flex-column justify-content-between">
                            <div className="form-wrapper">
                                <div className="center-element mb-2">
                                    <h2 className="header-title letter-animation" style={{ fontFamily: 'Roboto' }}>Ryans Media-Client Portal</h2>
                                    <h6 className="header-subtitle" >A Concern of Ryans Archives Limited</h6>
                                </div>
                                <form>
                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <TextField 
                                                fullWidth 
                                                label="Email Address" 
                                                autoComplete='off' 
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    style: { color: '#27606C' }
                                                }}
                                                sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                        "& > fieldset": {
                                                            borderColor: "#27606C",
                                                        },
                                                    },
                                                    "& .MuiOutlinedInput-root:hover": {
                                                        "& > fieldset": {
                                                            border: "2px solid #27606C",
                                                        },
                                                    },
                                                    "& .MuiOutlinedInput-root.Mui-focused": {
                                                        "& > fieldset": {
                                                            borderColor: "#27606C",
                                                            "& > legend": {
                                                                color: "#27606C",
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row  mb-4">
                                        <div className="col-12">
                                            <TextField 
                                                fullWidth 
                                                label="Password" 
                                                autoComplete='off' 
                                                type="password" 
                                                className="input-box" 
                                                name="password"
                                                value={formData.password}
                                                onChange={handleChange} 
                                                InputLabelProps={{
                                                    style: { color: '#27606C' }
                                                }}
                                                sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                        "& > fieldset": {
                                                            borderColor: "#27606C",
                                                        },
                                                    },
                                                    "& .MuiOutlinedInput-root:hover": {
                                                        "& > fieldset": {
                                                            border: "2px solid #27606C",
                                                        },
                                                    },
                                                    "& .MuiOutlinedInput-root.Mui-focused": {
                                                        "& > fieldset": {
                                                            borderColor: "#27606C",
                                                            "& > legend": {
                                                                color: "#27606C",
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6 d-flex justify-content-evenly align-items-center">
                                            <button className="login-btn attention-animation" onClick={handleSubmit}>Login</button>
                                        </div>
                                        <div className="col-6 d-flex justify-content-evenly align-items-center">
                                            <NavLink to="/forget-password" className="forgotpass text-decoration-none">Forgot Password ?</NavLink>
                                        </div>
                                    </div>

                                    <small  style={{fontSize:11+'px', color:'red', fontWeight:400}}>
                                        {errorMsg.map((value, index) => {
                                            return <div>{index+1}.{value}</div>
                                        })}
                                    </small>
                                </form>
                            </div>
                            <div className="footer-wrapper py-1 text-center">
                                <div className="row">
                                    <div className="col-6">
                                        <a href="https://www.ryansarchives.com" target="_blank" rel="noreferrer">
                                            <span className="footer-label">www.ryansarchives.com</span>
                                        </a>
                                    </div>
                                    <div className="col-6">
                                        <span className="footer-label">Visit Our Social Sites:</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <a href="https://www.ryansarchives.com" target="_blank" rel="noreferrer">
                                            <img className="icon-ryans" src="./assets/images/login/ryans-logo.png" alt="Ryans Archives Limited" />
                                        </a>
                                    </div>
                                    <div className="col-6">
                                        <div className="footer-icons">
                                            <a href="https://www.facebook.com/ryansmediapage/" target="_blank" rel="noreferrer">
                                                <img className="footer-icon" src="./assets/images/login/facebook.png" alt="Facebook" />
                                            </a>
                                            <a href="https://www.instagram.com/ryans_media/?hl=en" target="_blank" rel="noreferrer">
                                                <img className="footer-icon" src="./assets/images/login/instagram.png" alt="Instagram" />
                                            </a>
                                            <a href="https://bd.linkedin.com/company/rayansmedia" target="_blank" rel="noreferrer">
                                                <img className="footer-icon" src="./assets/images/login/linkedin.png" alt="Linkedin" />
                                            </a>
                                            <a href="https://www.youtube.com/channel/UCMEVKGs7OhRZb7Gw3LaCVhg" target="_blank" rel="noreferrer">
                                                <img className="footer-icon" src="./assets/images/login/youtube.png" alt="Youtube" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default LoginComponent