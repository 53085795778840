/* eslint-disable */
import React from "react"
import utf8 from "utf8"
import { createLinks } from './Links'
export const DisplayOpponent = (data, companyInfo={}) => {
    let style = "compitator"
    let linksDiv = ""
    let printDiv = ""
    let onlineDiv = ""
    let tv_radioDiv = ""
    let linksLength = 0
    let printLength = 0
    let onlineLength = 0
    let tv_radioLength = 0
    let title = data.title
    let company_name = companyInfo.company_name
    let company_logo = companyInfo.company_logo
    let company_bg = '#90a4ae';
    if(companyInfo.company_bg){
        let bg_arr = companyInfo.company_bg.split('#')
        let bg_code = bg_arr[1].split(';')
        company_bg = `#${bg_code[0]}`
    }
    if (typeof data.link === 'object') {
        linksDiv = createLinks(data.link.info)
        linksLength = data.link.info.length
    }
    if (typeof data.print === 'object') {
        printDiv = createLinks(data.print.info)
        printLength = data.print.info.length
    }
    if (typeof data.online === 'object') {
        onlineDiv = createLinks(data.online.info)
        onlineLength = data.online.info.length
    }
    if (typeof data.tv_radio === 'object') {
        tv_radioDiv = createLinks(data.tv_radio.info)
        tv_radioLength = data.tv_radio.info.length
    }
    
    return (
        <div className={`pb-4 ${style}`} >
            <div style={{textAlign: "right"}}>
                <span style={{background: company_bg, color:"#fff", padding: "1rem"}}>
                    {(company_logo) ? (
                        <img src={company_logo} style={{width:"15px", height:"15px", marginRight: '0.5rem'}} />
                    ) : ''  }
                    {company_name}
                </span>
            </div>
            <hr/>
            <div>
                <h6>{title}</h6>
            </div>
            {(data.synopsis)?(
                <div>
                    <strong> Synopsis : </strong> {data.synopsis}
                </div>
            ) : '' }
            {(data.synopsis2)?(
                <div className="pt-4">
                    {data.synopsis2}
                </div>
            ) : '' }
            { (linksLength > 0) ? (
                <div className="row">
                    <div className="col-md-3 py-2">
                        <b className="btn btn-success btn-sm">
                            Links &nbsp; &nbsp; &rarr; &nbsp; &nbsp; {linksLength}
                        </b>
                    </div>
                    <div className="col-md-9 py-2">{linksDiv}</div>
                </div>
            ) : '' }
            { (printLength > 0) ? (
                <div className="row">
                    <div className="col-md-3 py-2">
                        <b className="btn btn-success btn-sm">
                            Print Source &nbsp; &nbsp; &rarr; &nbsp; &nbsp; {printLength}
                        </b>
                    </div>
                    <div className="col-md-9 py-2">{printDiv}</div>
                </div>
            ) : '' }
            { (onlineLength > 0) ? (
                <div className="row">
                    <div className="col-md-3 py-2">
                        <b className="btn btn-success btn-sm">
                            Online Source &nbsp; &nbsp; &rarr; &nbsp; &nbsp; {onlineLength}
                        </b>
                    </div>
                    <div className="col-md-9 py-2">{onlineDiv}</div>
                </div>
            ) : '' }
            { (tv_radioLength > 0) ? (
                <div className="row">
                    <div className="col-md-3 py-2">
                        <b className="btn btn-success btn-sm">
                            Tv/Media Source &nbsp; &nbsp; &rarr; &nbsp; &nbsp; {tv_radioLength}
                        </b>
                    </div>
                    <div className="col-md-9 py-2">{tv_radioDiv}</div>
                </div>
            ) : '' }

        </div>
    )
}
