/* eslint-disable */

import React from "react"
import utf8 from "utf8"
import { createLinks } from './Links'
import { getCSSPropertyToValue } from "../../../../helpers/Methods"

// display compitator news here
export const Online = (data, companyInfo={}, type='', clickedMethod, index, company_index) => {
    
    // define style
    let style = { backgroundColor: "#fffde7", padding: '1rem', marginBottom:'1rem' }
    
    let linksDiv = ""
    let printDiv = ""
    let onlineDiv = ""
    let tv_radioDiv = ""

    let linksLength = 0
    let printLength = 0
    let onlineLength = 0
    let tv_radioLength = 0

    // assign alert title
    // let title = utf8.decode(data.title)
    let title = data.title

    // assign company name
    let company_name = companyInfo.company_name

    // assign company logo
    let company_logo = companyInfo.company_logo

    // set default company bg color
    let company_bg = '#90a4ae';

    // assign company set color
    if(companyInfo.company_bg){
        // re-assign company bg color
        company_bg = `#${getCSSPropertyToValue(companyInfo.company_bg)}`
    }

    // if links exists
    if (typeof data.link === 'object') {
        linksDiv = createLinks(data.link.info, clickedMethod)
        linksLength = data.link.info.length
    }
    
    // if print exists
    if (typeof data.print === 'object') {
        printDiv = createLinks(data.print.info, clickedMethod)
        printLength = data.print.info.length
    }

    // if online links exists
    if (typeof data.online === 'object') {
        onlineDiv = createLinks(data.online.info, clickedMethod)
        onlineLength = data.online.info.length
    }

    // if tv/radio links exists
    if (typeof data.tv_radio === 'object') {
        tv_radioDiv = createLinks(data.tv_radio.info, clickedMethod)
        tv_radioLength = data.tv_radio.info.length
    }
    
    return (
        <div>
            {/* if its first item of list, then show the content title bar with BG */}
            {((index == 0) && (company_index == 0))?(
                <div style={{ textAlign: "center", marginBottom: "0.5rem" }}>
                    <span style={{background: 'rgb(0, 126, 158)', color:"#fff", padding: "0.3rem", display:"block", width:"100%"}}>
                        {(companyInfo.logo) ? (
                            <img src={companyInfo.logo} style={{width:"15px", height:"15px", marginRight: '0.5rem'}} />
                        ) : ''  }
                        { type }
                    </span>
                </div>
            ) : ''}
        
            {/* if its first item of list, then show the company name with BG */}
            {
                (company_index == 0) &&
                (<div className="row">
                    <div style={{ textAlign: "left", marginBottom: "0.5rem" }}>
                        <span style={{background: company_bg, color:"#fff", padding: "0.3rem", display:"block", width:"100%"}}>
                            {(company_logo) ? (
                                <img src={company_logo} style={{width:"15px", height:"15px", marginRight: '0.5rem'}} />
                                ) : ''  }
                            {company_name}
                        </span>
                    </div>
                </div>)
            }

            <div>
                {/* <h6>{title}</h6> */}
                <h5 className="single-alert-title">{title}</h5>
            </div>

            {/* if synopsis exists then print */}
            {(data.synopsis)?(
                <div>
                    <strong> Synopsis : </strong> 
                    <span className="single-alert-details">
                        {data.synopsis}
                    </span>
                </div>
            ) : '' }

            {/* if synopsis exists then print */}
            {(data.synopsis2)?(
                <div className="pt-4">
                    <span className="single-alert-details">
                        {data.synopsis2}
                    </span>
                </div>
            ) : '' }
            
            {/* Its shows when links exists */}
            { (linksLength > 0) ? (
                <div className="row text-align-right">
                    <div className="pt-1" style={{wordWrap:'break-word'}}>
                        <span>Link :</span>
                        {linksDiv}
                        <span className="text-dark">
                            ({linksLength})
                        </span>
                    </div>
                </div>
            ) : '' }

            {/* Its shows when print exists */}
            { (printLength > 0) ? (
                <div className="row text-align-right">
                    <div className="pt-1" style={{wordWrap:'break-word'}}>
                        <span>Print :</span>
                        {printDiv}
                        <span className="text-dark">
                            ({printLength})
                        </span>
                    </div>
                </div>
            ) : '' }
            
            {/* Its shows when online exists */}
            { (onlineLength > 0) ? (
                <div className="row text-align-right">
                    <div className="pt-1" style={{wordWrap:'break-word'}}>
                        <span>Online:</span>
                        {onlineDiv}
                        <span className="text-dark">
                            ({onlineLength})
                        </span>
                    </div>
                </div>
            ) : '' }

            {/* Its shows when tv/radio exists */}
            { (tv_radioLength > 0) ? (
                <div className="row text-align-right">
                    <div className="pt-1">
                        <span>Tv/Radio:</span>
                        {tv_radioDiv}
                        <span className="text-dark">
                            ({tv_radioLength})
                        </span>
                    </div>
                </div>
            ) : '' }
        <hr/>
        </div>
    )
}
