import React from "react";

export default function Footer() {
    const year = new Date().getFullYear()
    return (
        <div className="footer-section">
			<hr />
			<span className="footer-text">Copyright © {year}, Ryans Archives Limited, All rights reserved.</span>
        </div>
    );
}
