import NewsMediaCategories from "./components/NewsMediaCategories";
import NewsDataForm from "./forms/NewsDataForm";

function NewsOnlineMediaDataPage() {
    document.title = "Data - Online Media";

    return (
        <div className="content-section">
            <div className="container">
                <NewsMediaCategories />

                <h4 className="category-title">
                    Online Media (Advertisement Data)
                </h4>

                <NewsDataForm type="news_online" />
            </div>
        </div>
    );
}

export default NewsOnlineMediaDataPage;
