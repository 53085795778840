import axios from 'axios';
import { getCookies } from '../helpers/Cookies';
let customInstance  = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        'content-type': 'application/x-www-form-urlencoded',
    }
})
if(getCookies('udata') && getCookies('udata') !== undefined && getCookies('udata') !== null) {
    const { access, csrf } = getCookies('udata')
    let token = ''
    let csrf_token = ''

    
    if(access && access.token !== undefined && access.token !== null){
        token = access.token
    }
    
    if(csrf && csrf.token !== undefined && csrf.token !== null){
        csrf_token = csrf.token
    }
    customInstance  = axios.create({
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        headers: {
            'content-type' : 'application/x-www-form-urlencoded',
            'X-CSRF-Token' : csrf_token,
            'Authorization': token
        }
    })
} 

export default customInstance;