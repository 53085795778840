import React from "react";

export default function DataCategory({ label, dataType, setDataType }) {
	return (
        // for styling selected category
        <div style={{ opacity: `${label === dataType ? 0.5 : 1}` }} className="data-category-card">
            <input 
                type="radio" 
                name="data-section" 
                id={label}
                className="data-selection"
                style={{ display: 'none' }}
                value={label}
                onChange={(e) => setDataType(e.target.value)} 
            />
            <label htmlFor={label} className="data-label">
                <div className="category-card">
                    <div className="col-md-6 d-flex">
                        <img 
                            className="img-fluid category-card-img" 
                            src={window.location.origin+"/assets/images/dataservice/ads-data.jpg"} 
                            alt={label}
                        />
                    </div>
                    <div className="col-md-6 text-center" style={{ width: '50%' }}>
                        <div className="category-card-label">{label}</div>
                        <div className="category-card-label">Data</div>
                    </div>
                </div>
            </label>
        </div>
    )
}
