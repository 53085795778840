import React from 'react'
import { NavLink } from "react-router-dom"
import Cookies from 'universal-cookie'
import Card from '../../helpers/card/Card'
import Footer from '../layouts/footer/Footer'

const cookies = new Cookies();

function HomeComponent(){
    // set page title 
    document.title = "Portal | Home"
    // get general services
    const {service} = cookies.get('udata')    

    const [dev, setDev] = React.useState(false)

    React.useEffect(() => {
        // set true if need to show alert design section
        setDev(false)
    }, [])

    return(
        <>
            <div className="content-section">
                <div className="container">
                                        
                    {/* <div className="row check text-align-center py-3 py-md-5"> */}
                    <div className="row dashboard-box text-align-center">

                        {/* show alert services */}
                        { 
                            (service.general.alert === 1) ? 
                            <div className="col d-flex justify-content-center">
                                <Card
                                    variant='variant-1'
                                    title="Alert Services"
                                    subtitle={"News & Advertisement Alert"}
                                    img={window.location.origin+"/assets/images/dashboard/alertinbox.png"}
                                    jumpto="/portal/alert-service"
                                />
                            </div>
                            :""
                        }

                        {/* show data services */}
                        {
                            <div className="col d-flex justify-content-center">
                                <Card
                                    variant='variant-2'
                                    title="Data Services"
                                    subtitle={"News & Advertisement Data"}
                                    img={window.location.origin+"/assets/images/dashboard/data-analysis.png"}
                                    jumpto="/portal/data-service-selection"
                                />
                            </div>
                        }
                        {/* show data services */}
                        {
                            <div className="col d-flex justify-content-center">
                                <Card
                                    variant='variant-3'
                                    title="Other Services"
                                    subtitle={"Report/Footage Coverage for download"}
                                    img={window.location.origin+"/assets/images/dashboard/otherservice.jpg"}
                                    jumpto="/portal/other-service"
                                />
                            </div>
                        }
                    </div>

                    {(dev) ? (
                        <div className="row">
                            <div className="col-md-4 py-2 py-md-4 text-center">
                                <NavLink to="/portal/dev-dailynews-modern-alert">
                                    <div className="card pt-2">
                                        <h5> DailyNews (Dev) - Modern </h5>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="col-md-4 py-2 py-md-4 text-center">
                                <NavLink to="/portal/dev-dailyadv-modern-alert">
                                    <div className="card pt-2">
                                        <h5> DailyAdv (Dev) - Modern </h5>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="col-md-4 py-2 py-md-4 text-center">
                                <NavLink to="/portal/dev-PeriodicAdvElastic-modern-alert">
                                    <div className="card pt-2">
                                        <h5> PeriodicAdvElastic (Dev) - Modern</h5>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="col-md-4 py-2 py-md-4 text-center">
                                <NavLink to="/portal/dev-dailynews-classic-alert">
                                    <div className="card pt-2">
                                        <h5> DailyNews (Dev) - Classic </h5>
                                    </div>
                                </NavLink>
                            </div>
                            
                            <div className="col-md-4 py-2 py-md-4 text-center">
                                <NavLink to="/portal/dev-dailyadv-classic-alert">
                                    <div className="card pt-2">
                                        <h5> DailyAdv (Dev) - Classic </h5>
                                    </div>
                                </NavLink>
                            </div>
                            
                            <div className="col-md-4 py-2 py-md-4 text-center">
                                <NavLink to="/portal/periodical-alert-service-classic">
                                    <div className="card pt-2">
                                        <h5>PeriodicAdvElastic (Dev) - Classic</h5>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    ) : ''}
                </div>

                <Footer />
            </div>
        </>
    )
}

export default HomeComponent