/* eslint-disable */

import React from "react"
import "react-datepicker/dist/react-datepicker.css"
import LoadingOverlay from 'react-loading-overlay'
import { Icons } from "../../../helpers/Constant"
import { getCSSPropertyToValue } from "../../../helpers/Methods"
import { alert_service_title_bg } from "../../../helpers/InlineStyle"
import { ReadableDateTime } from "../../../helpers/DateTime"
import data2 from '../../../storage/data/html_periodical_adv_alert.json'
import { getStore, updateAlertStore } from "../../../helpers/Storage"
import { GetJSONAlert } from "../../../api/request/FetchDownloadFIle"
import { MakeAlertRead } from "../../../api/request/AlertServiceAPI"
import { NavLink, useHistory } from "react-router-dom"

/**
 * generate periodical elastic ad alert view using alert data
 * 
 * @method
 * @name PeriodicalAdvElasticClassic
 * @param 
 * @returns {object} generate periodical ad alert view
 * @created_at  24th November 2021
 * @created_by  Muhammad Hasan
 */
function PeriodicalAdvElasticClassic(doc_id){
    // doc id    
    const ID = doc_id.doc_id

    // title for windows tab
    document.title = "Periodical Advertise Alert"

    // state for page loading indicator
	let [loading, setLoading] = React.useState(false)

    // alert banner image
    let [banner, setBanner] = React.useState('')
    
    // alert generated datetime
    let [created, setCreated] = React.useState('')

    // video ad url
    let [adType, setAdType] = React.useState(1) // 1= youtube, 2= image

    // title for alert 
    let [title, setTitle] = React.useState('')

    // image ad url
    let [imageUrl, setImageUrl] = React.useState('')
    
    // set data
    let [televisionData, setTelevisionData] = React.useState([])    // set television data
    let [radioData, setRadioData] = React.useState([])  // set radio data
    let [printMediaData, setPrintData] = React.useState([]) // set print data
    let [onlineMediaData, setOnlineMediaData] = React.useState([])  // set online data
    
    // set display
    let [tvView, setTvView] = React.useState(false)
    let [radioView, setRadioView] = React.useState(false)
    let [onlineView, setOnlineView] = React.useState(false)
    let [printView, setPrintView] = React.useState(false)

    let elastic_alert = []
   

    let [displayColumn, setDisplayColumn] = React.useState('')
    let [video, setVideo] = React.useState('')
    let [videoTitle, setVideoTitle] = React.useState('')

    // handle scroll class
    const [scrollClass, setScrollClass] = React.useState('')

    // for jumping to another alert
    const history = useHistory()

    const setVideoURLToModel = (link) => {
        // set meida name
        setVideoTitle(link.media_name)
        // get array of video url
        let video_url_arr = link.media_url.split('=')
        // get only youtube video
        let video_code = video_url_arr[video_url_arr.length-1]
        // set youtube video code 
        setVideo(video_code)
    }

    const withLinked = (link) => {
        return(
            <>
                <span 
                    onClick={()=>{setVideoURLToModel(link)}}
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    className="badge badge-info"
                    style={{color:'#fff',backgroundColor:'#17a2b8', marginRight:"0.3rem"}}>
                        {link.media_name}
                </span>
            </>
        )
    }
    
    // set model iframe data and ad url
    const changeModelData = (data) => {
        let [link, channel] = data
        // get array of given link
        let video_url_arr = link.split('/')
        
        // set media name
        setVideoTitle(channel)

        // console.log(link, video_url_arr)

        if (video_url_arr.length === 4) {
            // set media type as youtube video
            setAdType(1)

            // get only youtube video
            let video_code = video_url_arr[video_url_arr.length-1]
            
            // set youtube video code for iframe
            setVideo(video_code)
        } else {
            // set media type as image
            setAdType(2)
            
            // set image url for iframe
            setImageUrl(link)
        }
    }

    const generateAdAlert = (links, index, details_size) => {
        let link_index = links.link.length - 1
        // console.log(links, index, details_size)
        return(
            <div className="alert-title-box d-flex flex-column" style={{ borderRadius: '0 0 0.3rem 0.3rem'  }}> 
                {/* display alert title */}
                <strong className="flex-1 col-sm-12 text-align-center" style={{ fontWeight:'800' }}>
                    <small>{links.title}</small>
                </strong>

                {/* display ad links */}
                <div className="col-sm-12 text-align-right">
                    {links.link.map((link, index) => (

                        (link[0] !== '') ?

                        (
                            // if ad link exists
                            <span 
                                className="badge badge-info" 
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={()=>{changeModelData(link)}}
                                style={{
                                    color: '#000',
                                    backgroundColor: '#e4e4e4', 
                                    marginRight: "0.3rem", 
                                    border: "1px solid #c4c2c2", 
                                    fontSize: '0.7rem', 
                                    fontWeight: "bold"}}>
                                {link[1]}
                            </span>
                        ) : 
                        // remove comma form the last link
                        (link_index == index) ? 
                            (
                                // if ad link does't exists
                                <small className="" style={{color:'#000'}}>
                                    &nbsp;{link[1]}&nbsp;
                                </small>
                            ) : (
                                // if ad link does't exists
                                <small className="" style={{color:'#000'}}>
                                    &nbsp;{link[1]}&nbsp;,
                                </small>
                            )
                    ))}
                </div>
            </div>
        )
    }

    const getLinks = (details) => {
        // set default title bg
        let title_bg = alert_service_title_bg
        
        // get logo
        let logo = details.info.logo
        
        // get details size
        let details_size = details.details.length - 1

        // set title background color if exists
        if(details.info.bg){
            title_bg = `#${getCSSPropertyToValue(details.info.bg)}`
        }

        const generateDeatils = (detail) => {
            return(
                <>  
                    {detail.map((dt, index) => (
                        generateAdAlert(dt, index, details_size)
                    ))}
                </>
            )
        }
        
        return(
            <div className="">
                <div className="col-md-12 ps-1" style={{backgroundColor:title_bg}}>
                    <strong>
                        {(logo)  ?   (
                            <img src={logo} style={{width:"15px", height:"15px", marginRight:"0.5rem" }}/>
                        ) : ''}
                        {details.info.name}
                    </strong>
                </div>
                {generateDeatils(details.details)}
            </div>
        )
    }
    
    /**
     * generate alert title and links of periodical elisticle ad alert 
     * 
     * @method
     * @name displayData
     * @param {object} data 
     * @returns {view} generate periodical ad alert column
     */
    const displayData = (data) => {
        return (
            <div className={`col-md-12`}  style={{backgroundColor:data.bg, color:"#000", textAlign:"left"}}>
                {/* display alert name of alert category */}
                <h6 className="text-center py-1" style={{backgroundColor: "#8CC0DE"}}>
                    {data.info}
                </h6>
                {/* display media name and links */}
                { data.details.map( (detail) => (
                    <div className="row">
                        {getLinks(detail)}
                    </div>
                ))}
            </div>
        )
    }


    // ==========================================
    const fetchAlertservice = () => {
        // fetch storage alert service data if exists
        const alertService = getStore('alert_service') || []
        return alertService
    }

    // fetch selected file location
    const getAlertServiceById = (_id) => {
        if(_id){
            const alert = fetchAlertservice()
            let dt = {}
            if(alert){
                let service_data = alert.value
                for(let i=0; i<service_data.length; i++){
                    if (service_data[i]._id === _id || service_data[i].doc_id === _id) {
                        // set alert created dateTime
                        setCreated(ReadableDateTime(service_data[i].created_at))
                        dt = service_data[i]
                        break;
                    }
                }
            }
          return dt
        } else {
          return null
        }
    }

    // get json alert 
    const fetchJSONFile = async () => {
        // get json alert
        let dt = getAlertServiceById(ID)
        
        // obj
        let obj = JSON.parse(dt.json)

        // json file url
        let JSONFileUrl = obj[0].path

        // json file url array
        let json_file_arr = JSONFileUrl.split("/")

        // json object
        let data = { 
            id: ID,
            s_id: json_file_arr[json_file_arr.length-2],  
            file: json_file_arr[json_file_arr.length-1]
        }

        // make an api call for fetch data
        const result = await GetJSONAlert(data)
        
        // make an api call for make alert unread to read
        const read = await MakeAlertRead(dt.alert_id)

        // update alert open at status
        updateAlertStore(dt._id, 'open_at', 111)
        // update alert new status
        updateAlertStore(dt._id, 'new', 0)
        // update alert unread status
        updateAlertStore(dt._id, 'unread', 0)

        // close loading indcator
        setLoading(false)

        // return result
        return result.data
    }

    React.useEffect( async () => {
        // fetch json data
        const dt = await fetchJSONFile()

        // set alert banner image
        setBanner(dt.banner)

        // set alert title
        setTitle(dt.title)
        
        // preset alert column size
        const columnFormat = () => {
            
            if(dt.Radio){
                setRadioView(true)
                elastic_alert.push('Radio')

                // set radio data
                if(dt.Radio.details.length == 0){
                    setRadioData([])
                }else{
                    setRadioData(dt.Radio.details)
                }
            }
            
            if(dt.Television){
                setTvView(true)
                elastic_alert.push('Television')

                // set television data
                if(dt.Television.details.length == 0){
                    setTelevisionData([])
                }else{
                    setTelevisionData(dt.Television.details)
                }
            }

            if(dt.Online_Media){
                setOnlineView(true)
                elastic_alert.push('Online_Media')

                // set online data
                if(dt.Online_Media.details.length == 0){
                    setOnlineMediaData([])
                }else{
                    setOnlineMediaData(dt.Online_Media.details)
                }
            }

            if(dt.Print_Media){
                setPrintView(true)
                elastic_alert.push('Print_Media')

                // set print data
                if(dt.Print_Media.details.length == 0){
                    setPrintData([])
                }else{
                    // set television data
                    setPrintData(dt.Print_Media.details)
                }
            }

            let count = 0
            elastic_alert.map((value, index) =>{
                if(data2[value].details){
                    count++;
                }
            })
            let div_column = `col-md-${(12/count)}`
            // set alert number of column length
            setDisplayColumn(div_column)
            return true
        }
        columnFormat()
        
        window.scrollTo(0, 0)
        window.addEventListener('scroll', () => {
            if(window.scrollY == 0){
                setScrollClass('')
            } else {
                setScrollClass('top-0 height-90')
            }
        })

        return () => {
            window.removeEventListener('scroll')
        }
    },[])

    // ==========================================
    
    return(
        <>
            <LoadingOverlay active={loading} spinner text='Fetching Data ... ...' >
            <div className="scroll__to-top" onClick={() => window.scrollTo(0,0)}>
                <img src={ window.location.origin + "/assets/images/up-arrow.png" } />
            </div>
                <div style={{minHeight:100+'vh'}}>

                    <div className="content-section">
                        <div className="container">
                            <div className="row mx-1 mx-md-0">
                                <div className="col-md-9 my-md-3 py-2 alert-details-block">
                                    
                                    <div className="row border-bottom-gray py-3">

                                        {/* display alert page icon */}
                                        <div className="col-2 col-md-1 alert-img">
                                            <div className="alert-img">
                                                <img src={Icons.roundBlue} alt="" className="img-fluid search-icon-wh" />
                                            </div>
                                        </div>

                                        {/* display periodical alert title */}
                                        <div className="col-10 col-md-8">
                                            <div className="alert-title font-prim">
                                                { (title) ? title : '' }
                                            </div>
                                            <div className="alert-from font-soft-gray-2 font-size-12">
                                                From: Ryans Archives Limited
                                            </div>
                                        </div>

                                        {/* display periodical alert created date */}
                                        <div className="col-md-3">
                                            <div className="alert-date-time">
                                                <strong className="font-soft-gray-2 font-size-12">
                                                    {created}
                                                </strong>
                                            </div>
                                        </div>

                                    </div>

                                    {/* display alert page banner */}
                                    <img src={banner} style={{width: '100%', height: '175px', marginTop: '0.5rem'}}/>

                                    <div className="row mt-2 gx-2">
                                        {/* display television column if exists */}
                                        { (tvView && televisionData != undefined) ? 
                                            <div className={`${displayColumn}`} style={{wordWrap:'break-word'}}>
                                                <div className="col-md-12 alert-media">
                                                    <strong>Television</strong>
                                                </div>
                                                {televisionData.map((alert) => (
                                                    displayData(alert)
                                                ))}
                                            </div> : '' 
                                        }

                                        {/* display radio column if exists */}
                                        { (radioView && radioData != undefined) ? 
                                            <div className={`${displayColumn}`} style={{wordWrap:'break-word'}}>
                                                <div className="col-md-12 alert-media">
                                                    <strong>Radio</strong>
                                                </div>
                                                {radioData.map((alert) => (
                                                    displayData(alert)
                                                ))}
                                            </div> : '' 
                                        }

                                        {/* display print media column if exists */}
                                        { (printView && printMediaData != undefined) ? 
                                            <div className={`${displayColumn}`} style={{wordWrap:'break-word'}}>
                                                <div className="col-md-12 alert-media">
                                                    <strong>Print Media</strong>
                                                </div>
                                                {printMediaData.map((alert) => (
                                                    displayData(alert)
                                                ))}
                                            </div> : '' 
                                        }

                                        {/* display online media column if exists */}
                                        { (onlineView && onlineMediaData != undefined) ? 
                                            <div className={`${displayColumn}`} style={{wordWrap:'break-word'}}>
                                                 <div className="col-md-12 alert-media">
                                                    <strong>Online Media</strong>
                                                </div>
                                                {onlineMediaData.map((alert) => (
                                                    displayData(alert)
                                                ))}
                                            </div> : '' 
                                        }

                                        {/* model for display video */}
                                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">
                                                            {videoTitle}
                                                        </h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {/* load youtube video */}
                                                        {(adType == 1) ? (
                                                            <iframe
                                                                title="load video"
                                                                id="cartoonVideo"
                                                                className="embed-responsive-item"
                                                                style={{width:"100%", height:"25rem"}}
                                                                src={`//www.youtube.com/embed/${video}`} allowFullScreen>
                                                            </iframe>
                                                        ):(
                                                            // load image
                                                            <iframe
                                                                title="load image"
                                                                id="cartoonVideo"
                                                                className="embed-responsive-item"
                                                                style={{width:"100%", height:"25rem"}}
                                                                src={imageUrl}>
                                                            </iframe>
                                                        )}
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">X</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className={`col-md-3 my-md-3 py-2 alert-sidebar-ad ${scrollClass}`}>
                                    <NavLink to="/portal/alert-service">
                                        <h5 className="font-bg text-center mt-1">Recent Alerts</h5>
                                    </NavLink>
                                    <div className="alert-suggestion">
                                        {fetchAlertservice()?.value
                                            .filter(each => each.doc_id !== ID)
                                            .slice(0, 15)
                                            .map(each => (
                                                each.doc_id && each.alert_flag !== 'customAlert'  ?
                                                (
                                                    <a
                                                        className="alert-sug-title-link"
                                                        href={`${
                                                            (each.type === 2) 
                                                            ? '/portal/json-alert-details' 
                                                            : '/portal/alert-details'}/${each.doc_id}`}
                                                    >
                                                        <div className="alert-sug-title">
                                                            {each.title}
                                                        </div>
                                                    </a>
                                                ) : (
                                                    <div className="alert-sug-title">
                                                        <a
                                                            className="alert-sug-title-link"
                                                            href={`${
                                                                (each.type === 2) 
                                                                ? '/portal/json-alert-details' 
                                                                : '/portal/alert-details'}/${each._id}`}
                                                            >
                                                            {(each.name) ? each.name:each.title}
                                                        </a>
                                                    </div>
                                                )
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </LoadingOverlay>
        </>
    )
}

export default PeriodicalAdvElasticClassic;
