import axios from "../Index";
import { CLIENT } from "../APIEndPoints";
export const forgetPassword = async (payload) => {
    const params = new URLSearchParams();
    params.append("email", payload.email);
    params.append("name", payload.name);
    params.append("contact", payload.contact);
    params.append("remarks", payload.remarks);
    return await axios
        .post(CLIENT.forget_password, params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return "Something went wrong";
        });
}
