import { Button } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import { toast } from "react-toastify";
import * as api from "../../../../api/request/DataServiceAPI";
import FormInput from "../components/FormInput";
import { AD_REPORT, API_DS_KEY } from "../../../../helpers/dataservice/Settings";
import FormTypeSearchInput from "../components/FormTypeSearchInput";
import FormTypeAndSearchInput from "../components/FormTypeAndSearchInput";
import isDateValid from "../../../../helpers/isDateValid";
import FormMultipleSearchInput from "../components/FormMultipleSearchInput";
import FormDatePicker from "../components/FormDatePicker";
import FormCompanyInput from "../components/FormCompanyInput";
import { LoadingContext } from "../../../../../App";
import { getFromStorage, saveOnStorage, STORAGE_KEY } from "../../../../helpers/Storage";
import { showNotification } from "../../../../helpers/showNotification";

export default function AdvOnlineDataForm() {
    // state for, search by date
    const [startDate, setStartDate] = useState(null);
    // state for, search by date
    const [endDate, setEndDate] = useState(null);
    // state for, permitted start data for download
    const [permittedStartDate, setPermittedStartDate] = useState(null);
    // state for, permitted end data for download
    const [permittedEndDate, setPermittedEndDate] = useState(null);

    // selected
    const [companySelected, setCompanySelected] = useState([{ company_name: 'All' }]);
    const [productTypeSelected, setProductTypeSelected] = useState([{ product_type: 'All' }]);
    const [productSelected, setProductSelected] = useState([{ product_name: 'All' }]);
    const [brandSelected, setBrandSelected] = useState([{ brand_name: 'All' }]);

    // list
    const [company, setCompany] = useState([]);
    const [productType, setProductType] = useState([]);
    const [product, setProduct] = useState([]);
    const [brand, setBrand] = useState([]);

    // For All Set
    const [isAllCompany, setIsAllCompany] = useState(false);
    const [isAllBrand, setIsAllBrand] = useState(false);
    
    // state for, user searched campaign
    const [searchCampaign, setSearchCampaign] = useState("");
    // state for, toggle loading status
    const [isLoading, setLoading] = useState(false);
    // state for, error status
    const [error, setError] = useState(false);

    //  methods for page overlay loading
    const { changeLoadingState, setLoadingMessage } = useContext(LoadingContext);

    //error messages
    const error_msg = {
        date_range_error: "Date range is required !",
        invalid_date_error: "Invalid date range !",
    };

    // fetching company & other data on page load
    const fetchData = async () => {
        changeLoadingState(true);
        setLoadingMessage("Loading... ...");
        let advOnlineData;

        advOnlineData = getFromStorage(STORAGE_KEY.adv_online_data);

        if(advOnlineData === null){
            // fetch through api once
            advOnlineData = await api.fetchPrintOnlineAdData("online-ad");
            // setting encrypted data to storage for avoiding api call
            saveOnStorage(STORAGE_KEY.adv_online_data, advOnlineData);
        }

        setCompany(advOnlineData?.company || []);
        setProductType(advOnlineData?.product_type || []);
        setProduct(advOnlineData?.product || []);
        setBrand(advOnlineData?.brand || []);

        setIsAllCompany(advOnlineData.is_all_company);
        setIsAllBrand(advOnlineData.is_all_brand);

        // converting date to timestamp
        const permitted_start_date = new Date(advOnlineData?.start_date * 1000);
        const permitted_end_date = new Date(advOnlineData?.end_date * 1000);
        const today = new Date();

        setPermittedStartDate(permitted_start_date);
        setPermittedEndDate(today < permitted_end_date ? today : permitted_end_date);
        changeLoadingState(false);
    };

    useEffect(() => {
        fetchData();
    },
        // eslint-disable-next-line
    []);

    const handleStartDate = (date) => {
        setError(false); // for toggling red border in date range
        setStartDate(date);
    };

    const handleEndDate = (date) => {
        setEndDate(date);
    };

    // search company on keyUp
    const handleCompanySearch = async (user_typed) => {
        if(user_typed.length > 1){
            const companies = await api.searchAdvPrintOnlineCompany(user_typed)
            setCompany(companies);
        } else if(user_typed.length === 0){
            setCompany([])
        }
    };

    // search brand
    const handleBrandSearch = async (user_typed) => {
        if(user_typed.length > 1){
            const brands = await api.searchAdvPrintOnlineBrand(user_typed);
            setBrand(brands);
        } else if(user_typed.length === 0){
            setBrand([]);
        }
    };

    // clear from data
    const handleClear = () => {
        setStartDate(null);
        setEndDate(null);
        setCompanySelected([{ company_name: 'All' }]);
        setProductTypeSelected([{ product_type: 'All' }]);
        setProductSelected([{ product_name: 'All' }]);
        setBrandSelected([{ brand_name: 'All' }]);
        setLoading(false);
        setError(false);
        toast.dismiss();
    };

    /**
     * for generating item ids
     *
     * @handleSelected 
     * @param {array} list which api key needs to be check
     * @param {string} type Array of Ids for permission check
     * @returns {array} list of item ids
     */
    const handleSelected = (list, type) => {
        let item = [];
        let advOnlineData = getFromStorage(STORAGE_KEY.adv_online_data);
        
        if ((advOnlineData[`is_all_${type}`] && list.length === 0) 
            || list[0]?.[`${type}_name`] === 'All'
            || list[0]?.[`${type}`] === 'All') {
            item.push(-9);
        }
        else if(list.length === 0) {
            item = advOnlineData[type].map( (data) => {
                if(data[`${type}_id`]){
                    return data[`${type}_id`];
                } else {
                    return data['id'];
                }
            });
        }
        else {
            if (list[0]?.[`${type}_name`] === 'All') {
                item = advOnlineData[type].map( (data) => {
                    if(data[`${type}_id`]){
                        return data[`${type}_id`];
                    } else {
                        return data['id'];
                    }
                });
            }
            else {
                item = list.map((data) => {
                    if(data[`${type}_id`]){
                        return data[`${type}_id`];
                    } else {
                        return data['id'];
                    }
                });
            }
        }
        return item;
    }

    // fetching json data & converting to excel on download button click
    const handleDownload = async () => {
        if( startDate === null || endDate === null ) {
            setError(true);
            // launch error notification
            return showNotification("error", error_msg.date_range_error);
        }
        else if(!isDateValid(startDate, endDate)) {
            setError(true);
            // launch error notification
            return showNotification("error", error_msg.invalid_date_error);
        }
        else if(startDate < permittedStartDate || endDate > permittedEndDate) {
            setError(true);
            // launch error notification
            return showNotification("error", error_msg.invalid_date_error);
        }
        // launching overlay loading
        changeLoadingState(true);

        // set downloading text
        setLoadingMessage("Downloading ... ...");

        setError(false);

        const payload = {
            type: API_DS_KEY.AD.PRINT,
            start_date: startDate,
            end_date: endDate,
            peak: [],
            campaign: [],
            company: handleSelected(companySelected, 'company'),
            brand: handleSelected(brandSelected, 'brand'),
            product: handleSelected(productSelected, 'product'),
            product_type: handleSelected(productTypeSelected, 'product_type')
        };

        // api call for fetching data
        const result = await api.getAdvOnlineData(payload);
        
        if(result?.flag === 'data_empty' || result?.flag === 'process_error'){
            // stop loading spinner
            changeLoadingState(false)
            // launch error notification
            return showNotification("error", result.message);
        } else {
            if(result?.data?.file.length > 0){
                result.data.file.forEach( (item, index) => {
                    // set the location of the generated excel to start downloading
                    window.open(`${process.env.REACT_APP_STATIC}static/d/u/ad_online/${result.data.uid}/${item.link}`);
                    if( (index+1) == result.data.file.length ) {
                        // stop loading spinner
                        changeLoadingState(false);
                    }
                })
            }
        }
    };

    return (
        <form className="form-center">
            <div className="form-container mt-4">

                <div className="row mb-3 gx-5">
                    {/* select start date */}
                    <div className="col-md-6 col-sm-12 sm-mb-1">
                        <FormDatePicker
                            label="From"
                            minDate={permittedStartDate}
                            maxDate={permittedEndDate}
                            state={startDate}
                            setState={handleStartDate}
                            error={error}
                        />
                    </div>

                    {/* select end date */}
                    <div className="col-md-6 col-sm-12">
                        <FormDatePicker
                            label="To"
                            minDate={startDate}
                            maxDate={permittedEndDate}
                            state={endDate}
                            setState={handleEndDate}
                            error={error}
                        />
                    </div>
                </div>

                <div className="row mb-3 gx-5">
                    <div className="col-md-12 col-sm-12 sm-mb-1">
                        {/* for selecting UI */}
                        {isAllCompany ? (
                            // on keyup search
                            <FormTypeAndSearchInput
                                label="Company"
                                optionLabel="company_name"
                                placeholder="Type company name"
                                options={company}
                                state={companySelected}
                                handleSearch={handleCompanySearch}
                                setState={setCompanySelected}
                            />
                        ) : (
                            // dropdown autocomplete search
                            <FormCompanyInput
                                label="Company"
                                optionLabel="company_name"
                                state={companySelected}
                                options={company}
                                setState={setCompanySelected}
                            />
                        )}
                    </div>
                </div>
                
                <div className="row mb-3 gx-5">
                    <div className="col-md-6 col-sm-12 sm-mb-1">
                        <FormMultipleSearchInput
                            label="Product Type"
                            optionLabel="product_type"
                            state={productTypeSelected}
                            options={productType}
                            setState={setProductTypeSelected}
                        />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormMultipleSearchInput
                            label="Product"
                            optionLabel="product_name"
                            state={productSelected}
                            options={product}
                            setState={setProductSelected}
                        />
                    </div>
                </div>
                <div className="row mb-3 gx-5">
                    <div className="col-md-6 col-sm-12 sm-mb-1">
                        {isAllBrand ? (
                            // for all items type & search
                            <FormTypeSearchInput
                                label="Brand"
                                optionLabel="brand_name"
                                placeholder="Type brand name"
                                state={brandSelected}
                                options={brand}
                                setState={setBrandSelected}
                                handleSearch={handleBrandSearch}
                            />
                        ) : (
                            // for selected items dropdown values
                            <FormMultipleSearchInput
                                label="Brand"
                                optionLabel="brand_name"
                                state={brandSelected}
                                options={brand}
                                setState={setBrandSelected}
                            />
                        )}
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormInput
                            label="Campaign"
                            state={searchCampaign}
                            setState={setSearchCampaign}
                        />
                    </div>
                </div>                    
                    
                <div className="d-flex justify-content-center mb-4">
                    <span className="me-2">
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<RefreshIcon />}
                            onClick={handleClear}
                        >
                            Reset
                        </Button>
                    </span>
                    <span>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#27606C !important",
                                "&:hover": {
                                    backgroundColor: "#0f3e47 !important",
                                },
                            }}
                            startIcon={<DownloadIcon />}
                            onClick={handleDownload}
                        >
                            Download
                        </Button>
                    </span>
                </div>
            </div>
        </form>
    );
}