/* eslint-disable */

import {
    CurrentDateTimeToUnix,
    AddedTimeWithCurrentDateTime,
} from "./DateTime";
import CryptoJS from "crypto-js";

export const setStore = (key, value) => {
    let data = JSON.stringify({
        created: CurrentDateTimeToUnix(),
        value: value,
    });
    localStorage.setItem(key, data);
    return true;
};

export const getStore = (key) => {
    if (localStorage.getItem(key)) {
        return JSON.parse(localStorage.getItem(key));
    }
    return null;
};

export const removeStore = (key) => {
    return localStorage.removeItem(key);
};

export const clearAllStore = () => {
    return localStorage.clear();
};

/**
 * update storeage alert service data using key-value
 * @method
 * @name updateAlertStore
 * @param {string} alertId alert service id
 * @param {string} key which key want to update
 * @param {string} value which is new value
 * @return {bool} alert list will be returned
 * @created_at  02nd October 2021
 * @created_by  Muhammad Hasan
 */
export const updateAlertStore = (_id = "", key = "", value = "") => {
    // fetch storage alert service data if exists
    const alertService = getStore("alert_service") || [];
    let dt = {};

    // if alert service found
    if (alertService) {
        // get alert service data as array
        let service_data = alertService.value;
        for (let i = 0; i < service_data.length; i++) {
            if (service_data[i]._id === _id) {
                dt = service_data[i];
                // update service alert data
                service_data[i][key] = value;
                break;
            }
        }
        // re-store alert service data
        setStore("alert_service", service_data);
        return true;
    }
    return false;
};

export const STORAGE_KEY = {
    alert_service: "alert_service",
    old_ad_print_data: "advprintdata",
    old_ad_print_more_option: "advprintmoreopt",

    adv_tv_data: "__qAz",
    adv_tv_more_option: "__wSx",
    adv_radio_data: "__eDc",
    adv_radio_more_option: "__rFv",
    adv_print_data: "__tGb",
    adv_print_more_option: "__yHn",
    adv_online_data: "__uJm",
    adv_online_more_option: "__iKk",

    news_tv_data: "__oOl",
    news_tv_more_option: "__pPp",
    news_radio_data: "__aZq",
    news_radio_more_option: "__sXw",
    news_print_data: "__dCe",
    news_print_more_option: "__fVr",
    news_online_data: "__gBt",
    news_online_more_option: "__hNy",
};

/**
 * For removing local storages
 *
 * @param {Array} keys
 * @returns boolean
 */
export const removeStorages = () => {
    Object.keys(STORAGE_KEY).map((each) => localStorage.removeItem(STORAGE_KEY[each]));

    return true;
};

/**
 * Saving encrypted data on local storage
 * 
 * @param {String} key saving data with this key
 * @param {Object} data passing the data to be encrypted & saved
 */
export const saveOnStorage = (key, data) => {
    const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        process.env.REACT_APP_ENCRYPTED_KEY
    ).toString();

    localStorage.setItem(key, encryptedData);
};

/**
 * Retrive decrypted data from local storage
 * 
 * @param {String} key accessing the data with this key
 * @returns JSON
 */
export const getFromStorage = (key) => {
    const storageData = localStorage.getItem(key);
    if(storageData &&
        storageData !== null &&
        storageData !== undefined
    ){
        const bytes = CryptoJS.AES.decrypt(
            storageData,
            process.env.REACT_APP_ENCRYPTED_KEY
        );
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

        return decryptedData
    } else {
        return null
    }

};
