import NewsMediaCategories from "./components/NewsMediaCategories";
import NewsDataForm from "./forms/NewsDataForm";

function NewsRadioMediaDataPage() {
    document.title = "Data - Radio Media";

    return (
        <div className="content-section">
            <div className="container">
                <NewsMediaCategories />

                <h4 className="category-title">
                    Radio Media (News Data)
                </h4>

                <NewsDataForm type="news_rdc" />
            </div>
        </div>
    );
}

export default NewsRadioMediaDataPage;
