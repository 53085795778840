import React, { useState } from "react";
import "./moderndropdown.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useHistory } from "react-router-dom";

export default function ModernDropdown() {
    const history = useHistory();

    const initialState = {
        nested1: false,
        nested2: false,
    };
    const [hoverState, setHoverState] = useState({
        nested1: false,
        nested2: false,
    });

    const handleSelect = (e) => {
        setHoverState((prev) => ({
            ...initialState,
            [e.target.value]: !prev[e.target.value],
        }));
    };

    const getItemStyle = (key) => {
        return { backgroundColor: `${hoverState[key] ? "#f1f1f1" : ""}` };
    };

    const getNestedItemStyle = (key) => {
        return { display: `${hoverState[key] ? "block" : "none"}` };
    };

    return (
        <>
            <div className="dropdown__menu slide-right-animation">
                <div onChange={handleSelect}>
                    <div>
                        <input
                            type="radio"
                            value="nested1"
                            name="nested"
                            id="radio1"
                            className="input-radio"
                        />
                        <label
                            className="dropdown__item"
                            htmlFor="radio1"
                            style={getItemStyle("nested1")}
                        >
                            Advertisement Data <KeyboardArrowRightIcon />
                        </label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            value="nested2"
                            name="nested"
                            id="radio2"
                            className="input-radio"
                        />
                        <label
                            className="dropdown__item"
                            htmlFor="radio2"
                            style={getItemStyle("nested2")}
                        >
                            News Data <KeyboardArrowRightIcon />
                        </label>
                    </div>
                </div>
                <div className="dropdown__nesteditems">
                    <div
                        className="dropdown__nesteditem"
                        style={getNestedItemStyle("nested1")}
                    >
                        <ul>
                            <li
                                className="dropdown__nesteditem-label"
                                onClick={() =>
                                    history.push("/portal/adv/tv-media-data")
                                }
                            >
                                <img
                                    height={25}
                                    width={25}
                                    style={{ marginRight: "0.5rem" }}
                                    src={
                                        window.location.origin +
                                        "/assets/images/dataservice/ad-tv.png"
                                    }
                                    alt="TV Media"
                                />
                                TV Media Data
                            </li>
                            <li
                                className="dropdown__nesteditem-label"
                                onClick={() =>
                                    history.push("/portal/adv/radio-media-data")
                                }
                            >
                                <img
                                    height={22}
                                    width={22}
                                    style={{ marginRight: "0.7rem" }}
                                    src={
                                        window.location.origin +
                                        "/assets/images/dataservice/ad-radio.png"
                                    }
                                    alt="Radio Media"
                                />
                                Radio Media Data
                            </li>
                            <li
                                className="dropdown__nesteditem-label"
                                onClick={() =>
                                    history.push("/portal/adv/print-media-data")
                                }
                            >
                                <img
                                    height={22}
                                    width={22}
                                    style={{ marginRight: "0.7rem" }}
                                    src={
                                        window.location.origin +
                                        "/assets/images/dataservice/ad-print.png"
                                    }
                                    alt="Print Media"
                                />
                                Print Media Data
                            </li>
                            <li
                                className="dropdown__nesteditem-label"
                                onClick={() =>
                                    history.push(
                                        "/portal/adv/online-media-data"
                                    )
                                }
                            >
                                <img
                                    height={22}
                                    width={22}
                                    style={{ marginRight: "0.7rem" }}
                                    src={
                                        window.location.origin +
                                        "/assets/images/dataservice/ad-online.png"
                                    }
                                    alt="Online Media"
                                />
                                Online Media Data
                            </li>
                        </ul>
                    </div>
                    <div
                        className="dropdown__nesteditem"
                        style={getNestedItemStyle("nested2")}
                    >
                        <ul>
                            <li
                                className="dropdown__nesteditem-label"
                                onClick={() =>
                                    history.push("/portal/news/tv-media-data")
                                }
                            >
                                <img
                                    height={25}
                                    width={25}
                                    style={{ marginRight: "0.5rem" }}
                                    src={
                                        window.location.origin +
                                        "/assets/images/dataservice/news-tv.png"
                                    }
                                    alt="TV Media"
                                />
                                TV Media Data
                            </li>
                            <li
                                className="dropdown__nesteditem-label"
                                onClick={() =>
                                    history.push(
                                        "/portal/news/radio-media-data"
                                    )
                                }
                            >
                                <img
                                    height={22}
                                    width={22}
                                    style={{ marginRight: "0.7rem" }}
                                    src={
                                        window.location.origin +
                                        "/assets/images/dataservice/news-radio.png"
                                    }
                                    alt="Radio Media"
                                />
                                Radio Media Data
                            </li>
                            <li
                                className="dropdown__nesteditem-label"
                                onClick={() =>
                                    history.push(
                                        "/portal/news/print-media-data"
                                    )
                                }
                            >
                                <img
                                    height={22}
                                    width={22}
                                    style={{ marginRight: "0.7rem" }}
                                    src={
                                        window.location.origin +
                                        "/assets/images/dataservice/news-print.png"
                                    }
                                    alt="Print Media"
                                />
                                Print Media Data
                            </li>
                            <li
                                className="dropdown__nesteditem-label"
                                onClick={() =>
                                    history.push(
                                        "/portal/news/online-media-data"
                                    )
                                }
                            >
                                <img
                                    height={22}
                                    width={22}
                                    style={{ marginRight: "0.7rem" }}
                                    src={
                                        window.location.origin +
                                        "/assets/images/dataservice/news-online.png"
                                    }
                                    alt="Online Media"
                                />
                                Online Media Data
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
