import { Button } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

export default function NotAllowedPage() {
    const history = useHistory();

    return (
        <div className="d-flex align-items-center flex-column mt-5">
            <img
                src={"../assets/images/not-allowed.gif"}
                alt="Not Allowed"
                height={200}
            />
            <h3 className="text-center">You are not allowed for this service !</h3>
            <h3 className="text-center">
                Requesting to contact with{" "}
                <strong>
                    Client Relation Department.
                </strong>
            </h3>
            <h5 className="text-center">
                Call: <strong>+880 175 551 3979</strong> or E-mail: <strong>archives@ryansplus.com</strong>
            </h5>
            <h5 className="text-center">Thank you for visiting</h5>
            
            <Button
                variant="contained"
                onClick={() => history.push("/portal/home")}
                className="mt-3"
                sx={{
                    backgroundColor: "#27606C !important",
                    "&:hover": {
                        backgroundColor: "#0f3e47 !important",
                    },
                }}
                
            >
                Go To Homepage
            </Button>
        </div>
    );
}
