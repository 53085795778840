import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Paper } from "@mui/material";

const CustomPaper = (props) => {
    return (
        <Paper
            {...props}
            elevation={5}
            sx={(theme) => ({
                fontSize: "0.8rem !important",
                lineHeight: "1rem !important",
                [theme.breakpoints.down('sm')]: {
                    lineHeight: "0.1rem",
                }
            })}
        />
    );
};

export default function FormCompanyInput({
    label,
    options = [],
    state,
    setState,
}) {
    return (
        <Autocomplete
            multiple
            limitTags={1}
            id="multiple-limit-tags"
            options={options}
            getOptionLabel={(option) => option.company_name}
            sx={(theme) => ({
                width: 850,
                [theme.breakpoints.down('sm')]: {
                    width: 'calc(100vw - 3rem)',
                },
                "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                        borderColor: "#27606C",
                    },
                },
                "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                        border: "2px solid #27606C",
                    },
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                        borderColor: "#27606C",
                        "& > legent": {
                            color: "#27606C",
                        },
                    },
                },
                "& .MuiSvgIcon-root": {
                    color:"#27606C"
                },
                "& .MuiChip-deleteIcon.MuiSvgIcon-root": {
                    color:"gray",
                    "& :hover": {
                        color: "#27606C",
                    }
                },
            })}
            filterOptions={createFilterOptions({
                matchFrom: "any",
                trim: true,
                stringify: (option) => option.company_name
            })}
            PaperComponent={CustomPaper}
            value={state}
            onChange={(e, newValue) => {
                setState(newValue);

                const needToUpdate = state.filter(
                    (each) => each.company_name === "All"
                ).length;
                if (needToUpdate > 0) {
                    setState((prevState) =>
                        prevState.filter((each) => each.company_name !== "All")
                    );
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label={label}
                    InputLabelProps={{
                        style: { color: "#27606C" },
                    }}
                />
            )}
        />
    );
}
