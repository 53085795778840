/* eslint-disable */
import React from "react"
import { getStore, updateAlertStore } from "../../helpers/Storage"
import { NavLink, useParams } from "react-router-dom"
import { Attachments } from "../components/Attachments"
import { FileType, Icons } from "../../helpers/Constant"
import { changeStatus } from "../../api/request/AlertServiceAPI"
import { ReadableDateTime } from "../../helpers/DateTime"
import Linkify from 'react-linkify';
import reactStringReplace from 'react-string-replace';

function AlertServiceDetailsPage(){
    // set page title
    document.title = "Alert Service Details"

    // get alert service id
    let { id } = useParams()

    // state for checking current doc_id
    let [docId, setDocId] = React.useState('')
    // state for checking current file type
    let [fileType, setFileType] = React.useState(0)

    let [created, setCreated] = React.useState('')

    const [alertData, setAlertData] = React.useState({})
    const [pdf, setPDFData] = React.useState([])
    const [ppt, setPPTData] = React.useState([])
    const [xls, setXLSData] = React.useState([])
    const [zip, setZIPData] = React.useState([])
    const [docx, setDocxData] = React.useState([])

    const fetchAlertservice = () => {
      // fetch storage alert service data if exists
      const alertService = getStore('alert_service') || []
      return alertService
    }

    const getAlertServiceById = (_id) => {
        if(_id){
          const alert = fetchAlertservice()
          let dt = {}
          if(alert){
              let service_data = alert.value
              for(let i=0; i<service_data.length; i++){
                  if(service_data[i]._id === _id){
                      dt = service_data[i]
                      dt['position'] = i
                      break;
                  }
              }
          }
          return dt
        } else {
          return null
        }
    }

    // get details alert service data
    const setdata = () => {
        // data object of details notification
        let dataObject = { id: 0, name: '', desc: '', created_at: '' }

        // fetch storage alert service data if exists
        const alertService = fetchAlertservice()

        if(alertService){
            
            let service_data = alertService.value

            let dt = getAlertServiceById(id)
            
            if (typeof dt === 'object') {
                // get stored data using array destructuring
                let {_id, name, desc, pdf, ppt, xls, zip, docx, created_at, doc_id, type, alert_flag} = dt
                
                // setting doc_id for rendering recent alert list without current alert
                setDocId(doc_id)

                // setting type for rendering recent alert list
                setFileType(type)

                // set alert created date
                setCreated(ReadableDateTime(created_at))

                // assign value to local object
                dataObject = {
                    id: (doc_id) ? doc_id : _id, 
                    name: (name) ? name : dt.title,
                    desc, 
                    created_at,
                    alert_flag
                }
                
                // set file data object
                if(pdf){
                    setPDFData(JSON.parse(pdf))    
                }
                if(ppt){
                    setPPTData(JSON.parse(ppt))
                }
                if(xls){
                    setXLSData(JSON.parse(xls))
                }
                if(zip){
                    setZIPData(JSON.parse(zip))
                }
                if(docx){
                    setDocxData(JSON.parse(docx))
                }
            } else {
                console.log("error")
            }
            // set data to the alert object
            setAlertData(dataObject)
        }
    }

    // change unread status
    const changeReadStatus = async () => {
        const alert = getAlertServiceById(id)
        /**
         * if this alert haven't 'unread' property :
         * that means its was a new unread alert.
         * now its open. and need to add 'open_at' property value 
         **/
        if(typeof alert.unread !== 'undefined'){
            const filter = {id, index: alert.position}
            const unread = await changeStatus(filter)
        }
    }

    React.useEffect( () => {
        // fetch alert service details data
        setdata()
        // update server data
        changeReadStatus()
        // update alert unread status
        updateAlertStore(id, "unread", 0)
        // update alert new status
        updateAlertStore(id, "new", 0)
        // update alert open at status
        updateAlertStore(id, 'open_at', 111)
    },[])

    let content = "";
    
    if(alertData?.desc !== "" && alertData?.desc !== undefined){
        let url_regex = /(https?:\/\/[^\s]+)/g;
        content = reactStringReplace(alertData?.desc, url_regex, (match, i) => (<Linkify> {match} </Linkify>));
    }
    

    return(
        <>
            <div className="scroll__to-top" onClick={() => window.scrollTo(0,0)}>
                <img src={ window.location.origin + "/assets/images/up-arrow.png" } />
            </div>
            <div className="content-section">
                <div className="container">
                    <div className="row py-4 mx-1 mx-md-0">
                        <div className="col-md-9 my-md-3 py-2 alert-details-block">
                            <div className="row border-bottom-gray py-3">
                                <div className="col-2 col-md-1 alert-img">
                                    <div className="alert-img">
                                        <img src={Icons.roundBlue} alt="" className="img-fluid search-icon-wh" />
                                    </div>
                                </div>
                                <div className="col-10 col-md-8">
                                    <div className="alert-title font-prim">{alertData.name}</div>
                                    <div className="alert-from font-soft-gray-2 font-size-12">From: Ryans Archives Limited</div>
                                </div>
                                <div className="col-md-3">
                                    <div className="alert-date-time">
                                        <strong className="font-soft-gray-2 font-size-12">
                                            {created}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 py-3 alert-details border-bottom-gray">
                                <p className="font-size-14 text-align-justify font-weight-400">
                                    {content}
                                </p>
                            </div>
                            <div className="col-md-12 alert-attach ">
                                <div className="py-3 alert-attach-icon font-soft-gray-2">
                                    <img src={Icons.attachment} alt="" className="img-fluid search-icon-wh-1"/> Alert Attachments
                                </div>
                                <div className="alert-attach-file pb-3">
                                    <div className="row">
                                        {pdf?.length >=1 ? <Attachments fileType={FileType.pdf} data={pdf} desc={alertData} /> :''}
                                        {xls?.length >=1 ? <Attachments fileType={FileType.excel} data={xls} desc={alertData} /> :''}
                                        {zip?.length >=1 ? <Attachments fileType={FileType.zip} data={zip} desc={alertData} /> :''}
                                        {ppt?.length >=1 ? <Attachments fileType={FileType.ppt} data={ppt} desc={alertData} /> :''}
                                        {docx?.length >=1 ? <Attachments fileType={FileType.docx} data={docx} desc={alertData} /> :''}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={`col-md-3 my-md-3 py-2 alert-sidebar`}>
                            <NavLink to={`${fileType === 4 ? "/portal/other-service" : "/portal/alert-service"}`}>
                                <h5 className="font-bg text-center mt-1">Recent Alerts</h5>
                            </NavLink>
                            <div className="alert-suggestion">
                                {fetchAlertservice()?.value
                                    .filter(each => fileType === 4 
                                                    ? (each.type === 4 && each.doc_id !== docId) 
                                                    : (each.type !== 4 && each.doc_id !== docId))
                                    .slice(0, 15)
                                    .map(each => (
                                        each.doc_id && each.alert_flag !== 'customAlert'  ?
                                        (
                                            <a
                                                className="alert-sug-title-link"
                                                href={`${
                                                    (each.type === 2) 
                                                    ? '/portal/json-alert-details' 
                                                    : '/portal/alert-details'}/${each.doc_id}`}
                                            >
                                                <div className="alert-sug-title">
                                                    {each.title}
                                                </div>
                                            </a>
                                        ) : (
                                            <a
                                                className="alert-sug-title-link"
                                                href={`${
                                                    (each.type === 2) 
                                                    ? '/portal/json-alert-details' 
                                                    : '/portal/alert-details'}/${each._id}`}
                                            >
                                                <div className="alert-sug-title">
                                                    {(each.name) ? each.name:each.title}
                                                </div>  
                                            </a>
                                        )
                                    ))
                                }
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default AlertServiceDetailsPage
