import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ToastContainer, toast } from "react-toastify";
import { AD_REPORT, API_DS_KEY } from "../../../../helpers/dataservice/Settings";
import FormDatePicker from "../components/FormDatePicker";
import FormMultipleSearchInput from "../components/FormMultipleSearchInput";
import FormSearchInput from "../components/FormSearchInput";
import isDateValid from "../../../../helpers/isDateValid";
import { showNotification } from "../../../../helpers/showNotification";
import * as api from "../../../../api/request/DataServiceAPI";
import FormTypeAndSearchInput from "../components/FormTypeAndSearchInput";
import { LoadingContext } from "../../../../../App";
import { getFromStorage, saveOnStorage, STORAGE_KEY } from "../../../../helpers/Storage";
import "react-toastify/dist/ReactToastify.css";

export default function AdvRadioDataForm() {
    // state for, search by date
    const [startDate, setStartDate] = useState(null);
    // state for, search by date
    const [endDate, setEndDate] = useState(null);
    // state for, permitted start data for download
    const [permittedStartDate, setPermittedStartDate] = useState(null);
    // state for, permitted end data for download
    const [permittedEndDate, setPermittedEndDate] = useState(null);

    // selected
    const [companySelected, setCompanySelected] = useState([{ company_name: 'All' }]);
    const [mediaSelected, setMediaSelected] = useState([{ media_name: 'All' }]);
    const [adTypeSelected, setAdTypeSelected] = useState([{ ad_type_name: 'All' }]);
    const [productTypeSelected, setProductTypeSelected] = useState([{ product_type_name: 'All' }]);
    const [productSelected, setProductSelected] = useState([{ product_name: 'All' }]);
    const [brandSelected, setBrandSelected] = useState([{ brand_name: 'All' }]);

    // list
    const [company, setCompany] = useState([]);
    const [media, setMedia] = useState([]);
    const [adType, setAdType] = useState([]);
    const [productType, setProductType] = useState([]);
    const [product, setProduct] = useState([]);
    const [brand, setBrand] = useState([]);

    // For All Set
    const [isAllCompany, setIsAllCompany] = useState(false);
    const [isAllBrand, setIsAllBrand] = useState(false);
    
    // state for, searched company
    const [searchCompany, setSearchCompany] = useState([{ company_name: 'All' }]);
    // state for, searched product
    const [searchProduct, setSearchProduct] = useState([{ ad_type_name: 'All' }]);
    // state for, searched product type
    const [searchProductType, setSearchProductType] = useState([]);
    // state for, searched product
    const [searchBrand, setSearchBrand] = useState([]);
    // state for, searched campaign
    const [searchCampaign, setSearchCampaign] = useState('');
    // state for, searched peak/off peak
    const [searchPeak, setSearchPeak] = useState();
    // state for, toggle loading status
    const [loading, setLoading] = useState(false);
    // state for, error status
    const [error, setError] = useState(false);
    // state for, handling more data query field options
    const [peakList, setPeakList] = useState([]);
    const [productSearchList, setProductSearchList] = useState([{ product_name: 'All' }])
    const [fetchProductSearchList, setFetchProductSearchList] = useState([]);

    // methods for page overlay loading
    const { changeLoadingState, setLoadingMessage } = useContext(LoadingContext);

    //error messages
    const error_msg = {
        date_range_error: "Date range is required !",
        invalid_date_error: "Invalid date range !",
    };

    // fetching on page load data
    const fetchDropdownData = async () => {
        changeLoadingState(true);
        setLoadingMessage("Fetching data ... ...");
        let advRadioData;

        advRadioData = getFromStorage(STORAGE_KEY.adv_radio_data);
        
        if(advRadioData === null){
            // fetch through api once
            advRadioData = await api.fetchRdcAdData();
            // setting encrypted data to storage for avoiding api call
            saveOnStorage(STORAGE_KEY.adv_radio_data, advRadioData);
        }
        
        // console.log(advRadioData);
        
        setCompany(advRadioData?.company || []);
        setMedia(advRadioData?.media || []);
        setAdType(advRadioData?.ad_type || []);
        setPeakList([
            "Peak/Off Peak",
            "Customize Peak/Off Peak",
            "Customize Peak",
            "Customize Off Peak"
        ]);
        setProductType(advRadioData?.product_type || []);
        setProduct(advRadioData?.product || []);
        setBrand(advRadioData?.brand || []);

        setIsAllCompany(advRadioData.is_all_company);
        setIsAllBrand(advRadioData.is_all_brand);

        // converting date to timestamp
        const permitted_start_date = new Date(advRadioData?.start_date * 1000);
        const permitted_end_date = new Date(advRadioData?.end_date * 1000);
        const today = new Date();

        setPermittedStartDate(permitted_start_date);
        setPermittedEndDate(today < permitted_end_date ? today : permitted_end_date);
        changeLoadingState(false);
    }

    useEffect(() => {
        fetchDropdownData();
    },
    // eslint-disable-next-line
    [])

    const handleStartDate = (date) => {
        setError(false);
        setStartDate(date);
    };

    const handleEndDate = (date) => {
        setEndDate(date);
    };

    // used for company search
    const handleCompanySearch = async (user_typed) => {
        if(user_typed.length > 1){
            const companies = await api.searchAdvRadioCompany(user_typed)
            setCompany(companies);
        } else if(user_typed.length === 0){
            setCompany([])
        }
    };

    // used for brand search
    const handleBrandSearch = async (user_typed) => {
        if(user_typed.length > 1){
            const brands = await api.searchAdvRadioBrand(user_typed);
            setBrand(brands);
        } else if(user_typed.length === 0){
            setBrand([]);
        }
    };

    // reset tv-ad data download form
    const handleClear = () => {
        setStartDate(null);
        setEndDate(null);
        setCompanySelected([{ company_name: 'All' }]);
        setMediaSelected([{ media_name: 'All' }]);
        setAdTypeSelected([{ ad_type_name: 'All' }]);
        setProductTypeSelected([{ product_type_name: 'All' }]);
        setProductSelected([{ product_name: 'All' }]);
        setBrandSelected([{ brand_name: 'All' }]);
        setSearchPeak('Peak/Off Peak');
        setLoading(false);
        setError(false);
        toast.dismiss();
    };

    /**
     * for generating item ids
     *
     * @handleSelected 
     * @param {array} list which api key needs to be check
     * @param {string} type Array of Ids for permission check
     * @returns {array} list of item ids
     */
    const handleSelected = (list, type) => {
        let item = [];
        let advRadioData = getFromStorage(STORAGE_KEY.adv_radio_data);

        if ((advRadioData[`is_all_${type}`] && list.length === 0) || list[0]?.[`${type}_name`] === 'All') {
            item.push(-9);
        }
        else if(list.length === 0) {
            item = advRadioData[type].map( (data) => {
                return data[`${type}_id`]; 
            });
        }
        else {
            if (list[0]?.[`${type}_name`] === 'All') {
                item = advRadioData[type].map( (data) => {
                    return data[`${type}_id`]; 
                });
            }
            else {
                item = list.map((data) => {
                    return data[`${type}_id`]; 
                });
            }
        }
        return item;
    }

    const handleDownload = async () => {
        if(startDate === null || endDate === null) {
            setError(true)
            // launch error notification
            return showNotification("error", error_msg.date_range_error)
        }
        else if(!isDateValid(startDate, endDate)) {
            setError(true);
            // launch error notification
            return showNotification("error", error_msg.invalid_date_error);
        }
        else if(startDate < permittedStartDate || endDate > permittedEndDate) {
            setError(true)
            // launch error notification
            return showNotification("error", error_msg.invalid_date_error)
        }

        // starting loading spinner
        changeLoadingState(true);

        // set downloading text
        setLoadingMessage("Downloading ... ...");

        const payload = {
            type: API_DS_KEY.AD.RADIO,
            start_date: startDate,
            end_date: endDate,
            peak: [],
            campaign: [],
            company: handleSelected(companySelected, 'company'),
            brand: handleSelected(brandSelected, 'brand'),
            product: handleSelected(productSelected, 'product'),
            product_type: handleSelected(productTypeSelected, 'product_type'),
            media: handleSelected(mediaSelected, 'media'),
            ad_type: handleSelected(adTypeSelected, 'ad_type')
        };

        // call api to process filter data
        const result = await api.getAdvRadioData(payload);

        if(result.data.length == 0){
            // stop loading spinner
            changeLoadingState(false);

            // launch error notification
            return showNotification("error", result.message);
        }
        else {
            if(result.data.file.length > 0){
                result.data.file.forEach((item, index) => {
                    // set the location of the generated excel to start downloading
                    window.open(`${process.env.REACT_APP_STATIC}static/d/u/ad_rdc/${result.data.uid}/${item.link}`);
                    if((index+1) == result.data.file.length){
                        // stop loading spinner
                        changeLoadingState(false);
                    }
                })
            }
        }
    };

    return (
        <form className="form-center">
            <div className="form-container mt-4">
                <div className="row mb-3 gx-5">
                    <div className="col-md-6 col-sm-12 sm-mb-1">
                        <FormDatePicker
                            label="From"
                            minDate={permittedStartDate}
                            maxDate={permittedEndDate}
                            state={startDate}
                            setState={handleStartDate}
                            error={error}
                        />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormDatePicker
                            label="To"
                            minDate={startDate}
                            maxDate={permittedEndDate}
                            state={endDate}
                            setState={handleEndDate}
                            error={error}
                        />
                    </div>
                </div>

                <div className="row mb-3 gx-5">
                    <div className="col-md-6 col-sm-12 sm-mb-1">
                        {/* for selecting UI */}
                        {isAllCompany ? (
                            <FormTypeAndSearchInput
                                width="400"
                                label="Company"
                                optionLabel="company_name"
                                placeholder="Type company name"
                                options={company}
                                state={companySelected}
                                setState={setCompanySelected}
                                handleSearch={handleCompanySearch}
                            />
                        ) : (
                            <FormMultipleSearchInput
                                label="Company"
                                optionLabel="company_name"
                                options={company}
                                state={companySelected}
                                setState={setCompanySelected}
                            />
                        )}
                        
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormMultipleSearchInput
                            label="Media"
                            optionLabel="media_name"
                            options={media}
                            state={mediaSelected}
                            setState={setMediaSelected}
                        />
                    </div>
                </div>

                <div className="row mb-3 gx-5">
                    <div className="col-md-6 col-sm-12 sm-mb-1">
                        <FormMultipleSearchInput
                            label="Ad Type"
                            optionLabel="ad_type_name"
                            options={adType}
                            state={adTypeSelected}
                            setState={setAdTypeSelected}
                        />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormSearchInput
                            label="Peak/Off Peak"
                            options={peakList}
                        />
                    </div>
                </div>

                <div className="row mb-3 gx-5">
                    <div className="col-md-6 col-sm-12 sm-mb-1">
                        <FormMultipleSearchInput
                            label="Product Type"
                            optionLabel="product_type_name"
                            state={productTypeSelected}
                            options={productType}
                            setState={setProductTypeSelected}
                        />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormMultipleSearchInput
                            label="Product"
                            optionLabel="product_name"
                            state={productSelected}
                            options={product}
                            setState={setProductSelected}
                        />
                    </div>
                </div>
                <div className="row mb-3 gx-5">
                    <div className="col-md-6 col-sm-12 sm-mb-1">
                        {/* for selecting UI */}
                        {isAllBrand ? (
                            <FormTypeAndSearchInput
                                width="400"
                                label="Brand"
                                optionLabel="brand_name"
                                placeholder="Type brand name"
                                state={brandSelected}
                                options={brand}
                                setState={setBrandSelected}
                                handleSearch={handleBrandSearch}
                            />
                        ) : (
                            <FormMultipleSearchInput
                                label="Brand"
                                optionLabel="brand_name"
                                state={brandSelected}
                                options={brand}
                                setState={setBrandSelected}
                            />
                        )}
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormSearchInput
                            label="Campaign"
                            state={searchCampaign}
                            setState={setSearchCampaign}
                        />
                    </div>
                </div>
                
                <div className="d-flex justify-content-center mb-4">
                    <span className="me-2">
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<RefreshIcon />}
                            onClick={handleClear}
                        >
                            Reset
                        </Button>
                    </span>
                    <span>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#27606C !important",
                                "&:hover": {
                                    backgroundColor: "#0f3e47 !important",
                                },
                            }}
                            startIcon={<DownloadIcon />}
                            onClick={handleDownload}
                        >
                            Download
                        </Button>
                    </span>
                </div>
            </div>
        </form>
    );
}