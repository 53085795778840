import { removeStorages } from "./Storage";
import { removeCookies } from "./Cookies";

/**
 * logout user from the portal
 * 
 * @method
 * @name forceLogout
 * @param 
 * @returns {redirect} redirect to login page
 * @created_at  26th June 2023
 * @created_by  Muhammad Hasan
 */
export const forceLogout = () => {
    // clear all storage data
    removeStorages();

    // remove all user stored data
    removeCookies('udata', {path:'/'});
    
    // redirect to login page
    window.location.href = '/';

    // reload window
    window.location.reload(false);
}