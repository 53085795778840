/* eslint-disable */

export const createLinks = (data) => {
    let links = data.map((info) => {
        return (
            <div className="btn btn-danger btn-sm mx-1 m-b-half">
                <a href={info[0]} target="_blank">
                    <span className="badge badge-light">{info[1]}</span>
                </a>
            </div>
        );
    })
    return links
}