import React from "react";
import { NavLink } from "react-router-dom";
import "./card.css"

export default function Card(props) {
	const {
		title = "", 
		subtitle = "", 
		img = "", 
		jumpto = "", 
		variant = "variant-1"
	} = props
    return (
		<NavLink to={jumpto} >
			<div className={`box ${variant} sm-mb-1`}>
				<img className="img-container img-fluid" src={img} alt={title + " Image"} />
				<div className="text-position-center">
					<h4 className="text-center letter-animation">{ title }</h4>
					{ subtitle && <span className="text-center">{ subtitle }</span> }
					{props.children}
            	</div>
			</div>
		</NavLink>
    );
}