/* eslint-disable */

import React, { useRef } from "react"
import jsPDF from "jspdf"
import html2canvas from "html2canvas";

import LoadingOverlay from 'react-loading-overlay'
import { Icons } from "../../../helpers/Constant"
import { GenerateMainAlert } from '../../../helpers/alerts/classic/DailyHtmlAlert/Main'
import { GenerateIndustryAlert } from '../../../helpers/alerts/classic/DailyHtmlAlert/Industry'
import { DisplayOpponent } from '../../../helpers/alerts/classic/DailyHtmlAlert/Compitator'
import { DisplayPrintOnline } from '../../../helpers/alerts/classic/DailyHtmlAlert/GeneratePrintOnline'
import { ReadableDateTime } from "../../../helpers/DateTime"
import { getStore, updateAlertStore } from "../../../helpers/Storage"
import { GetJSONAlert } from "../../../api/request/FetchDownloadFIle"
import { MakeAlertRead } from "../../../api/request/AlertServiceAPI"
import { Print } from "../../../helpers/alerts/classic/DailyHtmlAlert/Print";
import { Online } from "../../../helpers/alerts/classic/DailyHtmlAlert/Online";
import { NavLink, useHistory } from "react-router-dom";

function DailyNewsClassic(doc_id) {
    // doc id
    const ID = doc_id.doc_id
    
    // set current browser title
    document.title = "Daily News"
    
	// state for page loading indicator
	let [loading, setLoading] = React.useState(true)
    let [alert, setAlert] = React.useState('')
    let [banner, setBanner] = React.useState('')
    let [created, setCreated] = React.useState('')
    let [title, setTitle] = React.useState('')
    let [modalTitle, setModalTitle] = React.useState('')
    let [mediaUrl, setMediaUrl] = React.useState(window.location.origin+"/assets/images/loading.gif")

    // assign main alert data 
    let [main, setMain] = React.useState([])
    // assign opponent alert data
    let [opponent, setOpponent] = React.useState([])
    // assign industry alert data
    let [industry, setIndustry] = React.useState([])
    // assign print ad alert data
    let [printAd, setPrintAd] = React.useState([])
    // assign online ad alert data
    let [onlineAd, setOnlineAd] = React.useState([])
    
    // company name for own/main section
    let [mainCompanyInfo, setMainCompanyInfo] = React.useState({})

    // industry name for industry section
    let [industryCompanyInfo, setIndustryCompanyInfo] = React.useState({})

    //----------------------------------------------------------------
    //----------------------------------------------------------------
    let [jsonAlertData, setJsonAlertData] = React.useState({})

    // handle scroll class
    const [scrollClass, setScrollClass] = React.useState('')

    // for navigation to another alert
    const history = useHistory()

    // refering the main content block
    const mainRef = useRef(null)

    // for executing jump to main content section
    const scrollToMainContent = () => {
        mainRef.current.scrollIntoView({ behavior: "smooth" })
    }

    // refering the competitor content block
    const competitorRef = useRef(null)

    // for executing jump to competitor content section
    const scrollToCompetitorContent = () => {
        competitorRef.current.scrollIntoView({ behavior: "smooth" })
    }

    // refering the industry block
    const industryRef = useRef(null)

    // for executing jump to industry content section
    const scrollToIndustryContent = () => {
        industryRef.current.scrollIntoView({ behavior: "smooth" })
    }

    // refering the print ad content block
    const printAdRef = useRef(null)

    // for executing jump to print ad content section
    const scrollToPrintContent = () => {
        printAdRef.current.scrollIntoView({ behavior: "smooth" })
    }

    // refering the online ad content block
    const OnlineAdRef = useRef(null)

    // for executing jump to online ad content section
    const scrollToOnlineContent = () => {
        OnlineAdRef.current.scrollIntoView({ behavior: "smooth" })
    }

    // for executing jump to top of the page
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    
    const fetchAlertservice = () => {
        // fetch storage alert service data if exists
        const alertService = getStore('alert_service') || []
        return alertService
    }

    const getAlertServiceById = (_id) => {
        if(_id){
            const alert = fetchAlertservice()

            let dt = {}
            if(alert){
                let service_data = alert.value
                for(let i=0; i<service_data.length; i++){
                    if(service_data[i]._id === _id || service_data[i].doc_id === _id){
                        dt = service_data[i]
                        break;
                    }
                }
            }
            return dt
        } else {
            return null
        }
    }

    // get json alert 
    const fetchJSONFile = async () => {
        // get json alert
        let dt = getAlertServiceById(ID)
        
        // set alert created date
        setCreated(ReadableDateTime(dt.created_at))

        // obj
        let obj = JSON.parse(dt.json)

        // json file url
        let JSONFileUrl = obj[0].path

        // json file url array
        let json_file_arr = JSONFileUrl.split("/")

        // json object
        let data = { 
            id: ID,
            s_id: json_file_arr[json_file_arr.length-2],  
            file: json_file_arr[json_file_arr.length-1]
        }

        // make an api call for fetch data
        const result = await GetJSONAlert(data)

        // make an api call for make alert unread to read
        const read = await MakeAlertRead(dt.alert_id)
        
        // update alert open at status
        updateAlertStore(dt._id, 'open_at', 111)
        // update alert new status
        updateAlertStore(dt._id, 'new', 0)
        // update alert unread status
        updateAlertStore(dt._id, 'unread', 0)

        // close loading indcator
        setLoading(false)

        // return result
        return result.data
    }

    React.useEffect( async () => {
        // fetch json data
        const dt = await fetchJSONFile()
        
        // set alert title
        setTitle(dt?.title||'')
        
        // set fetched data
        setJsonAlertData(dt)
        
        // 
        setBanner(dt?.banner||'')
        setAlert(dt?.alert_type||'')
        setAlert(dt?.main.company_name||'')
        setMain(dt?.main.data||'')
        setOpponent(dt?.mid||'')
        setIndustry(dt?.industry.data||'')
        setPrintAd(dt?.print_advertise||'')
        setOnlineAd(dt?.online_advertise||'')
        setMainCompanyInfo({
            'name': dt?.main.company_name,
            'bg': dt?.main.company_bg,
            'logo': dt?.main.company_logo,
        })
        setIndustryCompanyInfo({
            'name': dt?.industry.company_name,
            'bg': dt?.industry.company_bg,
            'logo': '',
        })

        // initially scroll to top
        window.scrollTo(0, 0)
        window.addEventListener('scroll', () => {
            if(window.scrollY == 0){
                setScrollClass('')
            } else {
                setScrollClass('top-0 height-90')
            }
        })

        return () => {
            window.removeEventListener('scroll')
        }
    },[])

    // set loading image
    const clearIframeSource = () => {
        // set loading image
        setMediaUrl(window.location.origin+"/assets/images/loading.gif")
    }

    // method for set modal title and link
    const setModalDetails = (params) => {
        // set media name to the model title
        setModalTitle(params.media)
        // set url for media url
        setMediaUrl(params.url)
    }

    const printDocument = () => {
        
        const doc = new jsPDF('p', 'in', 'letter');
        
        let sizes = [12, 16, 20]
        let fonts = [['Times', 'Roman'], ['Helvetica', ''], ['Times', 'Italic']]
        let font, size, lines
        let margin = 0.5
        let verticalOffset = margin
        let loremipsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id eros turpis. Vivamus tempor urna vitae sapien mollis molestie. Vestibulum in lectus non enim bibendum laoreet at at libero. Etiam malesuada erat sed sem blandit in varius orci porttitor. Sed at sapien urna. Fusce augue ipsum, molestie et adipiscing at, varius quis enim. Morbi sed magna est, vel vestibulum urna. Sed tempor ipsum vel mi pretium at elementum urna tempor. Nulla faucibus consectetur felis, elementum venenatis mi mollis gravida. Aliquam mi ante, accumsan eu tempus vitae, viverra quis justo.Proin feugiat augue in augue rhoncus eu cursus tellus laoreet. Pellentesque eu sapien at diam porttitor venenatis nec vitae velit. Donec ultrices volutpat lectus eget vehicula. Nam eu erat mi, in pulvinar eros. Mauris viverra porta orci, et vehicula lectus sagittis id. Nullam at magna vitae nunc fringilla posuere. Duis volutpat malesuada ornare. Nulla in eros metus. Vivamus a posuere libero.'

        font = fonts[1]
        size = sizes[0]
        
        lines = doc.setFont(font[0], font[1]).setFontSize(size).splitTextToSize(loremipsum, 7.5)
        doc.text(0.5, verticalOffset + size / 72, lines)
        
        doc.save('document.pdf')
    }

    //----------------------------------------------------------------
    //----------------------------------------------------------------

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Fetching Data ... ...' >
            <div className="scroll__to-top" onClick={() => window.scrollTo(0,0)}>
                <img src={ window.location.origin + "/assets/images/up-arrow.png" } />
            </div>
                <div style={{minHeight:100+'vh'}}>
                    {/* <button className="btn btn-primary" onClick={printDocument}>Export To PDF</button> */}
                    <div className="content-section">
                        <div className="container">
                            <div className="row mx-1 mx-md-0">
                                <div className="col-md-9 my-md-3 py-2 alert-details-block" id="divToPrint">
                                    
                                    {/* alert head */}
                                    <div className="row py-3">
                                        <div className="col-2 col-md-1 alert-img">
                                            <div className="alert-img">
                                                <img src={Icons.roundBlue} alt="" className="img-fluid search-icon-wh"/>
                                            </div>
                                        </div>
                                        <div className="col-10 col-md-8">
                                            <div className="alert-title font-prim">
                                                {title}
                                            </div>
                                            <div className="alert-from font-soft-gray-2 font-size-12">From: Ryans Archives Limited</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="alert-date-time">
                                                <strong className="font-soft-gray-2 font-size-12">
                                                    {created}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* company banner */}
                                    <img src={banner} style={{width: '100%', height: '175px'}}/>

                                    <div className="col-md-12 py-3 alert-detail">

                                        {/* Display main alert here if exist */}
                                        {
                                            (main) &&
                                            (<p ref={mainRef} className="font-size-14 text-align-justify font-weight-400">
                                                {main.map((data, index) => (
                                                    GenerateMainAlert(
                                                        data,
                                                        mainCompanyInfo,
                                                        'main',
                                                        index,
                                                        setModalDetails
                                                    )
                                                ))}
                                            </p>)
                                        }

                                        {/* Display compitator alert here if exist */}
                                        {
                                            (opponent) &&
                                            (<p ref={competitorRef} className="font-size-14 text-align-justify font-weight-400">
                                                {opponent.map((data) => (
                                                    data.data.map((info, index) => (
                                                        DisplayOpponent(
                                                            info,
                                                            {
                                                                'company_name': data.company_name,
                                                                'company_logo': data.company_logo,
                                                                'company_bg': data.company_bg
                                                            },
                                                            index,
                                                            setModalDetails
                                                        )
                                                    ))
                                                ))}
                                            </p>)
                                        }

                                        {/* Display industry alert here if exist */}
                                        {
                                            (industry) &&
                                            (<p ref={industryRef} className="font-size-14 text-align-justify font-weight-400">
                                                {industry.map((data, index) => (
                                                    GenerateIndustryAlert(
                                                        data,
                                                        industryCompanyInfo,
                                                        'industry',
                                                        index,
                                                        setModalDetails
                                                    )
                                                ))}
                                            </p>)
                                        }

                                        {/* Display print ad alert here if exist */}
                                        {
                                            (printAd) &&
                                            (<p ref={printAdRef} className="font-size-14 text-align-justify font-weight-400">
                                                {printAd.map((data, index) => (
                                                    data.data.map((info, company_index) => (
                                                        Print(
                                                            info, 
                                                            {
                                                                'company_name': data.company_name,
                                                                'company_logo': data.company_logo,
                                                                'company_bg': data.company_bg
                                                            },
                                                            'Print Advertisement',
                                                            setModalDetails,
                                                            index,
                                                            company_index
                                                        )
                                                    ))
                                                ))}
                                            </p>)
                                        }

                                        {/* Display online ad alert here if exist */}
                                        {
                                            (onlineAd) &&
                                            (<p ref={OnlineAdRef} className="font-size-14 text-align-justify font-weight-400">
                                                {onlineAd.map((data, index) => (
                                                    data.data.map((info, company_index) => (
                                                        Online(
                                                            info,
                                                            {
                                                                'company_name': data.company_name,
                                                                'company_logo': data.company_logo,
                                                                'company_bg': data.company_bg
                                                            },
                                                            'Online Advertisement',
                                                            setModalDetails,
                                                            index,
                                                            company_index                                                    )
                                                    ))
                                                ))}
                                            </p>)
                                        }
                                    </div>

                                    {/* iframe for load alert */}
                                    <div
                                        className="modal fade"
                                        data-keyboard="false"
                                        id="exampleModal" tabIndex="-1"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                    >
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">
                                                    { modalTitle }
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={clearIframeSource}>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <iframe
                                                    title="load image"
                                                    id="cartoonVideo"
                                                    className="embed-responsive-item"
                                                    style={{width:"100%", height:"60vh"}}
                                                    src={mediaUrl} allowFullScreen>
                                                </iframe>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    data-bs-dismiss="modal"
                                                    onClick={clearIframeSource}>
                                                        X
                                                </button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className={`col-md-3 my-md-3 py-2 alert-sidebar ${scrollClass}`}>
                                    <div>
                                        <h5 className="text-center font-bg">Navigator</h5>
                                        {main?.length > 0 
                                            &&
                                            <div>
                                                <button onClick={scrollToMainContent}>Your Content</button>
                                            </div>
                                        }
                                        {opponent[0]?.data?.length > 0 
                                            &&
                                            <div>
                                                <button onClick={scrollToCompetitorContent}>Competitor Content</button>
                                            </div>
                                        }
                                        {industry?.length > 0 
                                            &&
                                            <div>
                                                <button onClick={scrollToIndustryContent}>Industry Content</button>
                                            </div>
                                        }
                                        {printAd[0]?.data?.length > 0 
                                            &&
                                            <div>
                                                <button onClick={scrollToPrintContent}>Print Ad Content</button>
                                            </div>
                                        }
                                        {onlineAd[0]?.data?.length > 0 
                                            &&
                                            <div>
                                                <button onClick={scrollToOnlineContent}>Online Ad Content</button>
                                            </div>
                                        }
                                        <div>
                                            <button onClick={scrollToTop}>Scroll To Top</button>
                                        </div>
                                    </div>

                                    <NavLink to="/portal/alert-service">
                                        <h5 className="font-bg text-center mt-1">Recent Alerts</h5>
                                    </NavLink>
                                    <div className="alert-suggestion">
                                        {fetchAlertservice()?.value
                                            .filter(each => each.doc_id !== ID)
                                            .slice(0, 15)
                                            .map(each => (
                                                each.doc_id && each.alert_flag !== 'customAlert'  ?
                                                (
                                                    <a
                                                        className="alert-sug-title-link"
                                                        href={`${
                                                            (each.type === 2) 
                                                            ? '/portal/json-alert-details' 
                                                            : '/portal/alert-details'}/${each.doc_id}`}
                                                    >
                                                        <div className="alert-sug-title">
                                                            {each.title}
                                                        </div>
                                                    </a>
                                                ) : (
                                                    <a
                                                        className="alert-sug-title-link"
                                                        href={`${
                                                            (each.type === 2) 
                                                            ? '/portal/json-alert-details' 
                                                            : '/portal/alert-details'}/${each._id}`}
                                                    >
                                                        <div className="alert-sug-title">
                                                            {(each.name) ? each.name:each.title}
                                                        </div>
                                                    </a>
                                                )
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </LoadingOverlay>
        </>
    )
}

export default DailyNewsClassic
