import React from 'react'
import { useHistory } from 'react-router-dom'
import "./instruction.css"
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';

export default function InstructionPage() {
	const history = useHistory()

  return (
	<>
		<h5 className='instruction__layout-title'>Set below settings once to download data from client portal.</h5>
		<h6 className='instruction__layout-title'>Choose your preferable browser.</h6>

		<div className="instruction__layout-box">
			<div className="instruction__group">
				<img src={window.location.origin + '/assets/images/instruction/chrome.png'} alt="Chrome Browser" />
				<div className="instruction__group-btn">
					<button 
						className="instruction__btn"
						onClick={() => history.push("/instruction/chrome")}
					>
						<SwipeRightIcon />
						Instruction For Chrome
					</button>
					<button 
						className="instruction__btn"
						onClick={() => window.open("https://video.ryansmedia.com/details/63d9e3efa7376c465b6ae8d9")}
					>
						<MovieFilterIcon />
						Tutorial For Chrome
					</button>
				</div>
			</div>

			<div className="instruction__group">
				<img src={window.location.origin + '/assets/images/instruction/firefox.png'} alt="Firefox Browser" />
				<div className="instruction__group-btn">
					<button 
						className="instruction__btn"
						onClick={() => history.push("/instruction/firefox")}
					>
						<SwipeRightIcon />
						Instruction For Firefox
					</button>
					<button 
						className="instruction__btn"
						onClick={() => window.open("https://video.ryansmedia.com/details/63da1033a7376c465b6f572f")}
					>
						<MovieFilterIcon />
						Tutorial For Firefox
					</button>
				</div>
			</div>
		</div>
	</>
  )
}
