import { NavLink } from "react-router-dom";

function RequestServiceComponent(){
    document.title = "Request Service"
    return(
        <>
            <div className="top-bar bg-prim-2">
                <div className="container">
                    <div id="overlay"></div>
                    <div className="row min-height-50px">
                        <div className="col-3 col-md-2 item-center min-height-50px">
                            <img src={window.location.origin+"/assets/images/icons/home.svg"} alt="" className="img-fluid icon-15 sm-d-dn" />
                            <div className="px-2">
                                <NavLink to="/portal/home">
                                    <strong className="font-white sm-d-font-12">Dashboard</strong>
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-3 col-md-2 border-left item-center min-height-50px">
                            <NavLink to="/portal/request-service" className="active">
                                <strong className="font-white sm-d-font-12">Request Service</strong>
                            </NavLink>
                        </div>
                        <div className="col-3 col-md-2 border-left border-right item-center min-height-50px">
                            <NavLink to="/portal/notification">
                                <strong className="font-white sm-d-font-12">Notification</strong>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="container">
                    
                    <div className="row">
                        <div className="col-md-12 py-4 padding-l-two-rem">
                            <h6>Report New Issue</h6>
                            <span className="font-size-14">
                                <b>Have an issue ???</b>  Here you can request your new issue .
                            </span>
                            <hr/>

                            <div className='customAlert'>
                                <div className="alert-type bg-white text-align-center p-y-10">
                                    <img src={window.location.origin+"/assets/images/icons/home.svg"} className="img-fluid" alt="" style={{width:20+'px', height:20+'px'}}/>
                                    <span className="font-success font-weight-400 font-size-18">Success</span>
                                </div>
                                <p className='message'></p>
                                <div className="alert-btn">
                                    <input type='button' className='confirmButton' value='Ok' />
                                    <input type='button' className='cancleButton' value='Cancle'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="body-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="report-form">
                                <form action="#">
                                    <div className="form-group pb-4">
                                        <label htmlFor="exampleFormControlSelect1">Select Issue</label>
                                        <select className="form-control" id="exampleFormControlSelect1">
                                        <option>Please select an issue</option>
                                        <option>Review</option>
                                        <option>Request</option>
                                        </select>
                                    </div>
                                    <div className="form-group pb-4">
                                        <label htmlFor="exampleFormControlFile1">Select File</label><br/>
                                        <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                                    </div>
                                    <div className="form-group pb-4">
                                        <label htmlFor="exampleFormControlTextarea1">Write Message</label>
                                        <textarea  className="form-control" 
                                            id="msg" rows="3" placeholder="Write your message here..."></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-prim-2 font-white mb-2 float-end rab">Submit</button>                        
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6 border-left-soft-gray">
                            <p className="sm-d-t-center">Previous Report Status</p>
                            <div className="issue-area" style={{height:50+'vh !important', overflow:'scroll'}}>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1 ">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td className="text-success fw-bold">Resolved</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td className="text-warning fw-bold">Pending</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#" className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td className="text-danger fw-bold">Denied</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td>Denied</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td>Denied</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td>Denied</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td>Denied</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td>Denied</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td>Denied</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td>Denied</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td>Denied</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td>Denied</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td>Denied</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td>Denied</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <NavLink to="#"  className="font-prim-1">
                                                Report ID - R0000001
                                            </NavLink>
                                        </th>
                                        <td className="font-light-gray">3 days ago</td>
                                        <td>Denied</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <hr/>
                            <p>Copyright © 2021 Rayans Archives Limited, All rights reserved </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestServiceComponent