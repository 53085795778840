import React from "react";
import "./instruction.css";

export default function FirefoxInstruction() {
    return (
        <div className="instruction__container">
            <div className="instruction__fullpage">
                <div className="instruction__label-group">
                    <label className="instruction__circle">1</label>
                    <span className="instruction__title">
                        First visit to <strong>ryansmedia.com</strong>
                    </span>
                </div>
                <img
                    className="instruction__img"
                    src={
                        window.location.origin +
                        "/assets/images/instruction/firefox/firefox-1.png"
                    }
                    alt="Instruction"
                />
            </div>

            <div className="instruction__fullpage">
                <div className="instruction__label-group">
                    <label className="instruction__circle">2</label>
                    <span className="instruction__title">
                        Go to <strong>Connection secure</strong> by clicking the{" "}
                        <strong>Lock Button</strong> seen in this picture
                    </span>
                </div>
                <img
                    className="instruction__img"
                    src={
                        window.location.origin +
                        "/assets/images/instruction/firefox/firefox-2.png"
                    }
                    alt="Instruction"
                />
            </div>

            <div className="instruction__fullpage">
                <div className="instruction__label-group">
                    <label className="instruction__circle">3</label>
                    <span className="instruction__title">
                        Then click the <strong>More information</strong> button
                    </span>
                </div>
                <img
                    className="instruction__img"
                    src={
                        window.location.origin +
                        "/assets/images/instruction/firefox/firefox-3.png"
                    }
                    alt="Instruction"
                />
            </div>

            <div className="instruction__fullpage">
                <div className="instruction__label-group">
                    <label className="instruction__circle">4</label>
                    <span className="instruction__title">
                        A new window will be poped up, goto <strong>Permission tab</strong>
                    </span>
                </div>
                <img
                    className="instruction__img"
                    src={
                        window.location.origin +
                        "/assets/images/instruction/firefox/firefox-4.png"
                    }
                    alt="Instruction"
                />
            </div>

            <div className="instruction__fullpage">
                <div className="instruction__label-group">
                    <label className="instruction__circle">5</label>
                    <span className="instruction__title">
                        In <strong>Permissions tab</strong> deselect the use default of <strong>Open Pop-up windows</strong>
                    </span>
                </div>
                <img
                    className="instruction__img"
                    src={
                        window.location.origin +
                        "/assets/images/instruction/firefox/firefox-5.png"
                    }
                    alt="Instruction"
                />
            </div>
            
            <div className="instruction__fullpage">
                <div className="instruction__label-group">
                    <label className="instruction__circle">6</label>
                    <span className="instruction__title">
                        After deselecting, <strong>Allow</strong> the <strong>Open Pop-up windows</strong> and done ! Close the window
                    </span>
                </div>
                <img
                    className="instruction__img"
                    src={
                        window.location.origin +
                        "/assets/images/instruction/firefox/firefox-6.png"
                    }
                    alt="Instruction"
                />
            </div>
        </div>
    );
}
