import { useEffect, useState } from "react";
import { NEWS_REPORT } from "../../../helpers/dataservice/Settings";
import NewsMediaCategories from "./components/NewsMediaCategories";
import NewsDataForm from "./forms/NewsDataForm";

function NewsPrintMediaDataPage() {
    document.title = "Data - Print Media";

    const [companyList, setCompanyList] = useState([]);

    const fetchDropdownData = async () => {
        setCompanyList([]);
    };

    useEffect(
        () => {
            fetchDropdownData();
        },
        //eslint-disable-next-line
        []
    );

    return (
        <div className="content-section">
            <div className="container">
                <NewsMediaCategories />

                <h4 className="category-title">
                    Print Media (News Data)
                </h4>

                <NewsDataForm type="news_print" />
            </div>
        </div>
    );
}

export default NewsPrintMediaDataPage;
