import {NavLink} from "react-router-dom";

function AdDataComponent(){
    document.title = "Ad Data"
    return(
        <>
            {/* <div className="top-bar bg-prim-2">
                <div className="container">
                    <div className="row min-height-50px d-flex justify-content-between">
                        <div className="col-3 col-md-2 item-center min-height-50px">
                            <img src={window.location.origin+"/assets/images/icons/home.svg"} alt="" className="img-fluid icon-15 sm-d-dn" />
                            <div className="px-2">
                                <NavLink to="/portal/home">
                                    <strong className="font-white sm-d-font-12">Dashboard</strong>
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-4 col-md-2 border-left item-center min-height-50px">
                            <NavLink to="#" className="active">
                                <strong className="font-white  sm-d-font-12">Advertisement Data</strong>
                            </NavLink>
                        </div>
                        <div className="col-3 col-md-2 border-left item-center min-height-50px">
                            <NavLink to="#">
                                <strong className="font-white  sm-d-font-12">Select a media</strong>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="content-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 py-4 py-md-5 padding-l-two-rem">
                            <h6>Select a media</h6>
                            <span className="font-size-14">
                                You can select any of them .
                            </span>
                        </div>
                    </div>

                    <div className="row padding-l-two-rem sm-d-p-r-2">
                        <div className="col-md-2 area-ad-data py-4 mb-5 mb-md-0 px-3-r text-align-center">
                            <NavLink to="/portal/tv-media-data">
                                <div className="service-icon">
                                    <img src={window.location.origin+"/assets/images/icons/tv.svg"} alt="" className="img-fluid search-icon-wh" />
                                </div>
                                <div className="service-title">
                                    <p className="m-b-0 font-weight-500 font-prim">Tv Data</p>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-2 offset-md-1 area-news-data py-4 mb-5 mb-md-0 px-3-r text-align-center">
                            <NavLink to="/portal/radio-media-data">
                                <div className="service-icon">
                                    <img src={window.location.origin+"/assets/images/icons/radio.svg"} alt="" className="img-fluid search-icon-wh" />
                                </div>
                                <div className="service-title">
                                    <p className="m-b-0 font-weight-500 font-prim">Radio Data</p>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-2 offset-md-1 area-news-data py-4 mb-5 mb-md-0 px-3-r text-align-center">
                            <NavLink to="/portal/print-media-data">
                                <div className="service-icon">
                                    <img src={window.location.origin+"/assets/images/icons/newspaper.svg"} alt="" className="img-fluid search-icon-wh" />
                                </div>
                                <div className="service-title">
                                    <p className="m-b-0 font-weight-500 font-prim">Print Media Data</p>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-2 offset-md-1 area-news-data py-4 mb-5 mb-md-0 px-3-r text-align-center">
                            <NavLink to="/portal/online-media-data">
                                <div className="service-icon">
                                    <img src={window.location.origin+"/assets/images/icons/social-media.svg"} alt="" className="img-fluid search-icon-wh" />
                                </div>
                                <div className="service-title">
                                    <p className="m-b-0 font-weight-500 font-prim">Online Data</p>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdDataComponent;