export const AD_REPORT = {
	TV: "ad_tvc",
	RADIO: "ad_rdc",
	PRINT: "ad_print",
	ONLINE: "ad_online",
}

export const NEWS_REPORT = {
	TV: "news_tvc",
	RADIO: "news_rdc",
	PRINT: "news_print",
	ONLINE: "news_online",
}

export const REPORT_NAMES = {
	AD: {
		TV: "TV_Advertisement_Detail_Report",
		RADIO: "Radio_Advertisement_Detail_Report",
		PRINT: "Print_Advertisement_Detail_Report",
		ONLINE: "Online_Advertisement_Detail_Report",
	},
	NEWS: {
		TV: "TV_News_Detail_Report",
		RADIO: "Radio_News_Detail_Report",
		PRINT: "Print_News_Detail_Report",
		ONLINE: "Online_News_Detail_Report",
	},
}

export const HEADERS = {
	AD: {
		TV: [
			{
				header: "Ad Date", 
				key: "Ad Date", 
				width: 12
			},
			{
				header: "Channel Name", 
				key: "Channel Name", 
				width: 18
			},
			{
				header: "Company", 
				key: "Company", 
				width: 25
			},
			{
				header: "Ad Type", 
				key: "Ad Type", 
				width: 15
			},
			{
				header: "Peak/Off-Peak", 
				key: "Peak/Off-peak", 
				width: 20
			},
			{
				header: "Telecast Time", 
				key: "Telecast Time", 
				width: 18
			},
			{
				header: "Ad Dur(sec)", 
				key: "Ad Dur(sec)", 
				width: 15
			},
			{
				header: "Ad Name", 
				key: "Ad Name", 
				width: 30
			},
			{
				header: "Brand", 
				key: "Brand", 
				width: 25
			},
			{
				header: "Sub Brand", 
				key: "Sub Brand", 
				width: 25
			},
			{
				header: "Product Type", 
				key: "Product Type", 
				width: 20
			},
			{
				header: "Product", 
				key: "Product", 
				width: 20
			},
			{
				header: "Program Type", 
				key: "Program Type", 
				width: 20
			},
			{
				header: "Program Name", 
				key: "Program Name", 
				width: 25
			},
			{
				header: "Break/Placing", 
				key: "Break/Placing", 
				width: 20
			},
			{
				header: "Break Type", 
				key: "Break Type", 
				width: 20
			},
			{
				header: "Ad Qty", 
				key: "Ad Qty", 
				width: 15
			},
			{
				header: "Ad Pos", 
				key: "Ad Pos", 
				width: 15
			},
			{
				header: "Campaign", 
				key: "Campaign", 
				width: 15
			},
			{
				header: "Price(BDT)", 
				key: "price", 
				width: 15
			},
			{
				header: "Incomplete Ad", 
				key: "Incomplete Ad", 
				width: 20
			},
		],
		RADIO: [],
		PRINT: [
			{
				header: 'Ad Date', 
				key: "Ad Date", 
				width: 12
			},
			{
				header: "Media Name", 
				key: "Media Name", 
				width: 20 
			},
			{
				header: "Media Type", 
				key: "Media Type", 
				width: 18 
			},
			{
				header: "Pub Place", 
				key: "Pub Place", 
				width: 18 
			},
			{
				header: "Pub.Freq", 
				key: "Pub.Freq", 
				width: 18 
			},
			{
				header: "Pub Lang", 
				key: "Pub Lang", 
				width: 18 
			},
			{
				header: "Company", 
				key: "Company", 
				width: 30 
			},
			{
				header: "Ad Title", 
				key: "Ad Title", 
				width: 25 
			},
			{
				header: "Brand", 
				key: "Brand", 
				width: 20 
			},
			{
				header: "Product Type", 
				key: "Product Type", 
				width: 18 
			},
			{
				header: "Product", 
				key: "Product", 
				width: 15 
			},
			{
				header: "Campaign", 
				key: "Campaign", 
				width: 18 
			},
			{
				header: "Ad Link", 
				key: "hyperlink", 
				width: 12 
			},
			{
				header: "Hue", 
				key: "Hue", 
				width: 15 
			},
			{
				header: "Page No", 
				key: "Page No", 
				width: 15 
			},
			{
				header: "Col/Width", 
				key: "Col/Width", 
				width: 18 
			},
			{
				header: "Inch/Height", 
				key: "Inch/Height", 
				width: 18 
			},
			{
				header: "Total Col*Inch/Pixel", 
				key: "Total Col*Inch/Pixel", 
				width: 25 
			},
			{
				header: "Price(BDT)", 
				key: "price", 
				width: 15 
			},
		],
		ONLINE: [
			{
				header: 'Ad Date', 
				key: "Ad Date", 
				width: 12
			},
			{
				header: "Media Name", 
				key: "Media Name", 
				width: 20 
			},
			{
				header: "Media Type", 
				key: "Media Type", 
				width: 18 
			},
			{
				header: "Pub Place", 
				key: "Pub Place", 
				width: 15 
			},
			{
				header: "Pub.Freq", 
				key: "Pub.Freq", 
				width: 15 
			},
			{
				header: "Pub Lang", 
				key: "Pub Lang", 
				width: 15 
			},
			{
				header: "Company", 
				key: "Company", 
				width: 30 
			},
			{
				header: "Ad Title", 
				key: "Ad Title", 
				width: 25 
			},
			{
				header: "Brand", 
				key: "Brand", 
				width: 20 
			},
			{
				header: "Product Type", 
				key: "Product Type", 
				width: 18 
			},
			{
				header: "Product", 
				key: "Product", 
				width: 15 
			},
			{
				header: "Campaign", 
				key: "campaign", 
				width: 18 
			},
			{
				header: "Ad Link", 
				key: "hyperlink", 
				width: 15 
			},
			{
				header: "Hue", 
				key: "Hue", 
				width: 15 
			},
			{
				header: "Page No", 
				key: "Page No", 
				width: 18 
			},
			{
				header: "Col/Width", 
				key: "Col/Width", 
				width: 18 
			},
			{
				header: "Inch/Height", 
				key: "Inch/Height", 
				width: 18 
			},
			{
				header: "Total Col*Inch/Pixel", 
				key: "Total Col*Inch/Pixel", 
				width: 25 
			},
			{
				header: "Price(BDT)", 
				key: "price", 
				width: 15 
			},
		],
	},
	NEWS: {
		TV: [],
		RADIO: [],
		PRINT: [],
		ONLINE: [],
	}
}

export const API_DS_KEY = {
	AD: {
		TV: "ad_tvc",
		RADIO: "ad_rdc",
		PRINT: "ad_print",
		ONLINE: "ad_online",
	},
	NEWS: {
		TV: "news_tvc",
		RADIO: "news_rdc",
		PRINT: "news_print",
		ONLINE: "news_online",
	},
}