import axios from "../Index";
import { DATA_SERVICE, DATA_OPTION, CLIENT } from "../APIEndPoints";
import { forceLogout } from "../../helpers/User";
export const getAdvPrintCompany = async () => {
    return await axios
        .post(DATA_SERVICE.get_adv_print_company)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const getAdvPrintData = async (payload) => {
    const params = new URLSearchParams();
    params.append("type", payload.type);
    params.append("from_date", payload.start_date);
    params.append("to_date", payload.end_date);
    params.append("company", payload.company);
    params.append("product_type", payload.product_type);
    params.append("product", payload.product);
    params.append("brand", payload.brand);
    params.append("campaign", payload.campaign);
    return await axios
        .post(DATA_SERVICE.get_adv_print_data, params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const getAdvOnlineData = async (payload) => {
    const params = new URLSearchParams();

    params.append("type", payload.type);
    params.append("from_date", payload.start_date);
    params.append("to_date", payload.end_date);
    params.append("company", payload.company);
    params.append("product_type", payload.product_type);
    params.append("product", payload.product);
    params.append("brand", payload.brand);
    params.append("campaign", payload.campaign);
    return await axios
        .post(DATA_SERVICE.get_adv_online_data, params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const getAdvTvData = async (payload) => {
    const params = new URLSearchParams();
    params.append("type", payload.type);
    params.append("from_date", payload.start_date);
    params.append("to_date", payload.end_date);
    params.append("company", payload.company);
    params.append("media", payload.media);
    params.append("ad_type", payload.ad_type);
    params.append("peak", payload.peak);
    params.append("product_type", payload.product_type);
    params.append("product", payload.product);
    params.append("brand", payload.brand);
    params.append("campaign", payload.campaign);
    return await axios
        .post(DATA_SERVICE.get_adv_tv_data, params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            if(err.message.search("401") !== -1){
                forceLogout();
            } else {
                return {
                    data: null,
                    flag: "",
                    msg: err.message
                };
            }
        });
};
export const getAdvTvDataResponse = async (payload) => {
    const params = new URLSearchParams();
    params.append("docs_id", payload);
    return await axios
        .post(DATA_SERVICE.get_tv_news_report, params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
}
export const getAdvTvDataPartial = async (payload) => {
    const params = new URLSearchParams();

    params.append("access_id", payload.access_id);

    return await axios
        .post(DATA_SERVICE.get_adv_tv_data_partial, params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const getAdvPrintCompanyOnLoad = async () => {
    return await axios
        .post(DATA_SERVICE.get_adv_print_company)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const getAdvPrintMoreOption = async () => {
    return await axios
        .post(DATA_SERVICE.get_adv_print_more_option)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const getAdvOnlineCompanyOnLoad = async () => {
    return await axios
        .post(DATA_SERVICE.get_adv_online_company)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log('err', err);
            return []
        });
};
export const getAdvOnlineMoreOption = async () => {
    return await axios
        .post(DATA_SERVICE.get_adv_online_more_option)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const getAdvTvDataOnLoad = async () => {
    return await axios
        .post(DATA_SERVICE.get_adv_tv_option)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log('err', err);
            return []
        });
};
export const getAdvTvDataMoreOption = async () => {
    return await axios
        .post(DATA_SERVICE.get_adv_tv_more_option)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log('err', err);
            return []
        });
};
export const searchAdvPrintOnlineCompany = async (company_name) => {
    const params = new URLSearchParams();
    params.append("search_q", company_name);
    return await axios
        .post(DATA_SERVICE.search_adv_print_online_company, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const searchAdvTvCompany = async (company_name) => {
    const params = new URLSearchParams();
    params.append("search_q", company_name);
    return await axios
        .post(DATA_SERVICE.search_adv_tv_company, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const searchAdvRadioCompany = async (company_name) => {
    const params = new URLSearchParams();
    params.append("search_q", company_name);
    return await axios
        .post(DATA_SERVICE.search_adv_radio_company, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const searchAdvPrintOnlineBrand = async (brand_name) => {
    const params = new URLSearchParams();
    params.append("search_q", brand_name);
    return await axios
        .post(DATA_SERVICE.search_adv_print_online_brand, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const searchAdvTvBrand = async (brand_name) => {
    const params = new URLSearchParams();
    params.append("search_q", brand_name);
    return await axios
        .post(DATA_SERVICE.search_adv_tv_brand, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const searchAdvRadioBrand = async (brand_name) => {
    const params = new URLSearchParams();

    params.append("search_q", brand_name);

    return await axios
        .post(DATA_SERVICE.search_adv_radio_brand, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const getAdvRadioDataOnLoad = async () => {
    return await axios
        .post(DATA_SERVICE.get_adv_radio_info)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const getAdvRadioDataMoreOption = async () => {
    return await axios
        .post(DATA_SERVICE.get_adv_radio_more_info)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const getAdvRadioData = async (payload) => {
    const params = new URLSearchParams();
    params.append("type", payload.type);
    params.append("from_date", payload.start_date);
    params.append("to_date", payload.end_date);
    params.append("company", payload.company);
    params.append("media", payload.media);
    params.append("ad_type", payload.ad_type);
    params.append("peak", payload.peak);
    params.append("product_type", payload.product_type);
    params.append("product", payload.product);
    params.append("brand", payload.brand);
    params.append("campaign", payload.campaign);
    return await axios.post(DATA_SERVICE.get_adv_radio_data, params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const getNewsDataOnLoad = async (type='') => {
    
    if(type == null || type == undefined || type.length == 0) {
        console.log("Api type missing");
        return [];
    }
    
    const params = new URLSearchParams();

    let api_url = DATA_OPTION.news_data;

    switch (type) {
        case "tv":
            params.append("type", "tvc");
            break
        case "rdc":
            params.append("type", "rdc");
            break
        case "print":
            params.append("type", "print");
            break
        case "online":
            params.append("type", "online");
            break
        default:
            params.append("type", "");
    }

    return await axios
        .post(api_url, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log('err', err);
            return [];
        });
};
export const searchNewsCompany = async (company_name) => {
    const params = new URLSearchParams();

    params.append("search_q", company_name);
    return await axios
        .post(DATA_SERVICE.search_news_company, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const searchNewsCategory = async (company_name) => {
    const params = new URLSearchParams();
    params.append("search_q", company_name);
    return await axios
        .post(DATA_SERVICE.search_news_company, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return [];
        });
};
export const fetchDownloadFile = async (payload) => {
    try {
        const params = new URLSearchParams();
        params.append("from_date", payload.start_date);
        params.append("to_date", payload.end_date);
        params.append("company", payload.company);
        params.append("category", payload.category);
        params.append("sub_category", payload.sub_category);
        params.append("campaign", payload.campaign);
        params.append("type", payload.type);
        let api_url = ''
        switch(payload.type){
            case 'news_tvc':
                api_url = DATA_SERVICE.get_tv_news_report;
                break;
            case 'news_rdc':
                api_url = DATA_SERVICE.get_rdc_news_report;
                break;
            case 'news_print':
                api_url = DATA_SERVICE.get_print_news_report;
                break;
            case 'news_online':
                api_url = DATA_SERVICE.get_online_news_report;
                break;
        }
        return await axios
            .post(api_url, params)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.log(err);
                return []
            });

    } catch (err) {
        console.log(err)
        return []
    }   
};
export const fetchTvAdData = async () => {
    return await axios
        .post(DATA_OPTION.tv_ad_data)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const fetchRdcAdData = async () => {
    return await axios
        .post(DATA_OPTION.rdc_ad_data)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
            return []
        });
};
export const fetchPrintOnlineAdData = async (payload) => {
    try {
        const params = new URLSearchParams();
        params.append("type", payload);
        return await axios
            .post(DATA_OPTION.print_online_ad_data, params)
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                console.log(err);
                return []
            });
    } catch(err) {
        console.log(err);
        return [];
    }
};
export const clientLogout = async () => {
    try {
        const params = new URLSearchParams();
        return await axios
            .post(CLIENT.logout, params)
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                console.log(err.message);
                return []
            });
    } catch(err) {
        console.log(err.message);
        return [];
    }
};