import { TextField } from "@mui/material";
import React from "react";

export default function FormInput({ label, state, setState }) {
    return (
        <TextField
            label={label}
            autoComplete="off"
			value={state}
			onChange={(e) => setState(e.target.value)}
            size="small"
            InputLabelProps={{
                style: { color: "#27606C" },
            }}
            sx={(theme) => ({
                width: 400,
                [theme.breakpoints.down("sm")]: {
                    width: "calc(100vw - 3rem)",
                },
                "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                        borderColor: "#27606C",
                    },
                },
                "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                        border: "2px solid #27606C",
                    },
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                        borderColor: "#27606C",
                        "& > legend": {
                            color: "#27606C",
                        },
                    },
                },
                "& .MuiSvgIcon-root": {
                    color: "#27606C",
                },
            })}
        />
    );
}
