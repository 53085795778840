import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material"
import DataCategory from "./components/DataCategory";

function DataServiceComponent(props){
    document.title = "Data Service"
    const [dataType, setDataType] = useState(props.location.state || '')
    const history = useHistory()


    return(
        <div className="container">
            <div className="data-selection">
                <DataCategory 
                    label="Advertisement" 
                    dataType={dataType}
                    setDataType={setDataType} 
                />
                <DataCategory 
                    label="News" 
                    dataType={dataType}
                    setDataType={setDataType} 
                />
            </div>

            {dataType === ''
                ?
                <h4 className="category-title">Choose Category From Above</h4>
                :
                dataType === 'Advertisement'
                    ?
                    <>
                        <h4 className="category-title">{dataType} Data</h4>
                        <ul className="category-animation" style={{ padding: 0, marginTop: '1rem' }} >
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item xs={6} sm={3}>
                                    <li className="category-media" 
                                        onClick={() => history.push("/portal/adv/tv-media-data")}
                                    >
                                        <span>
                                            <img src={window.location.origin+"/assets/images/dataservice/ad-tv.png"} 
                                            alt="TV Media" className="img-fluid category-icon" />
                                        </span>
                                        <span className="category-label">TV Media</span>
                                        <span style={{ color: 'grey' }}>Advertisement Data</span>
                                    </li>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <li className="category-media"
                                        onClick={() => history.push("/portal/adv/radio-media-data")}
                                    >
                                        <span>
                                            <img src={window.location.origin+"/assets/images/dataservice/ad-radio.png"} 
                                            alt="Radio Media" className="img-fluid category-icon" />
                                        </span>
                                        <span className="category-label">Radio Media</span>
                                        <span style={{ color: 'grey' }}>Advertisement Data</span>
                                    </li>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <li className="category-media"
                                        onClick={() => history.push("/portal/adv/print-media-data")}
                                    >
                                        <span>
                                            <img src={window.location.origin+"/assets/images/dataservice/ad-print.png"} 
                                            alt="Print Media" className="img-fluid category-icon" />
                                        </span>
                                        <span className="category-label">Print Media</span>
                                        <span style={{ color: 'grey' }}>Advertisement Data</span>
                                    </li>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <li className="category-media"
                                        onClick={() => history.push("/portal/adv/online-media-data")}
                                    >
                                        <span>
                                            <img src={window.location.origin+"/assets/images/dataservice/ad-online.png"} 
                                            alt="Online Media" className="img-fluid category-icon" />
                                        </span>
                                        <span className="category-label">Online Media</span>
                                        <span style={{ color: 'grey' }}>Advertisement Data</span>
                                    </li>
                                </Grid>
                            </Grid>
                        </ul>
                    </>
                    :
                    <>
                        <h4 className="category-title">{dataType} Data</h4>
                        <ul className="category-animation" style={{ padding: 0, marginTop: '1rem' }}>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item xs={6} sm={3}>
                                    <li className="category-media"
                                        onClick={() => history.push("/portal/news/tv-media-data")}
                                    >
                                        <span>
                                            <img src={window.location.origin+"/assets/images/dataservice/news-tv.png"} 
                                            alt="TV Media" className="img-fluid category-icon" />
                                        </span>
                                        <span className="category-label">TV Media</span>
                                        <span style={{ color: 'grey' }}>News Data</span>
                                    </li>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <li className="category-media"
                                        onClick={() => history.push("/portal/news/radio-media-data")}
                                    >
                                        <span>
                                            <img src={window.location.origin+"/assets/images/dataservice/news-radio.png"} 
                                            alt="Radio Media" className="img-fluid category-icon" />
                                        </span>
                                        <span className="category-label">Radio Media</span>
                                        <span style={{ color: 'grey' }}>News Data</span>
                                    </li>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <li className="category-media"
                                        onClick={() => history.push("/portal/news/print-media-data")}
                                    >
                                        <span>
                                            <img src={window.location.origin+"/assets/images/dataservice/news-print.png"} 
                                            alt="Print Media" className="img-fluid category-icon" />
                                        </span>
                                        <span className="category-label">Print Media</span>
                                        <span style={{ color: 'grey' }}>News Data</span>
                                    </li>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <li className="category-media"
                                        onClick={() => history.push("/portal/news/online-media-data")}
                                    >
                                        <span>
                                            <img src={window.location.origin+"/assets/images/dataservice/news-online.png"} 
                                            alt="Online Media" className="img-fluid category-icon" />
                                        </span>
                                        <span className="category-label">Online Media</span>
                                        <span style={{ color: 'grey' }}>News Data</span>
                                    </li>
                                </Grid>
                            </Grid>
                        </ul>
                    </>
            }
            
        </div>
    )
}

export default DataServiceComponent;