import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function AdvMediaCategories() {
	const history = useHistory()
    const location = useLocation()

    return (
        <ul className="topbar-category-media-section">
            <li
                className="media-type row"
                onClick={() => history.push("/portal/adv/tv-media-data")}
				style={{ opacity: `${location.pathname === '/portal/adv/tv-media-data' ? 0.5 : 1}` }}
			>
                <div className="col-3 ms-3 d-flex justify-content-center">
                    <img
                        src={window.location.origin + "/assets/images/dataservice/ad-tv.png"}
                        alt="TV Media"
                        className="img-fluid media-category-icon"
                    />
                </div>
                <div className="col-9">
                    <div className="media-category-label">TV Media</div>
                    <div style={{ color: "grey" }}>Advertisement Data</div>
                </div>
            </li>

            <li
                className="media-type row"
                onClick={() => history.push("/portal/adv/radio-media-data")}
				style={{ opacity: `${location.pathname === '/portal/adv/radio-media-data' ? 0.5 : 1}` }}
			>
                <div className="col-3 ms-3 d-flex justify-content-center">
                    <img
                        src={window.location.origin + "/assets/images/dataservice/ad-radio.png"}
                        alt="Radio Media"
                        className="img-fluid media-category-icon"
                    />
                </div>
                <div className="col-9">
                    <div className="media-category-label">Radio Media</div>
                    <div style={{ color: "grey" }}>Advertisement Data</div>
                </div>
            </li>
            <li
                className="media-type row"
                onClick={() => history.push("/portal/adv/print-media-data")}
				style={{ opacity: `${location.pathname === '/portal/adv/print-media-data' ? 0.5 : 1}` }}
			>
                <div className="col-3 ms-3 d-flex justify-content-center">
                    <img
                        src={
                            window.location.origin +
                            "/assets/images/dataservice/ad-print.png"
                        }
                        alt="Print Media"
                        className="img-fluid media-category-icon"
                    />
                </div>
                <div className="col-9">
                    <div className="media-category-label">Print Media</div>
                    <div style={{ color: "grey" }}>Advertisement Data</div>
                </div>
            </li>
            <li
                className="media-type row"
                onClick={() => history.push("/portal/adv/online-media-data")}
				style={{ opacity: `${location.pathname === '/portal/adv/online-media-data' ? 0.5 : 1}` }}
			>
                <div className="col-3 ms-3 d-flex justify-content-center">
                    <img
                        src={
                            window.location.origin +
                            "/assets/images/dataservice/ad-online.png"
                        }
                        alt="Online Media"
                        className="img-fluid media-category-icon"
                    />
                </div>
                <div className="col-9">
                    <div className="media-category-label">Online Media</div>
                    <div style={{ color: "grey" }}>Advertisement Data</div>
                </div>
            </li>
        </ul>
    );
}
