import axios from "../Index";
import { ALERT_SERVICE } from "../APIEndPoints";
export const getAlertService = async () => {
    return await axios.post(ALERT_SERVICE.get)
    .then(res => {
        return res.data.result.data
    })
    .catch(err => {
        console.log(err)
    })

}

export const getAlertServiceFlashData = async () => {
    return await axios.post(ALERT_SERVICE.flash)
    .then(res => {
        return res.data.result.data
    })
    .catch(err => {
        console.log(err)
        return []
    })
}
export const getJSONAlertService = async () => {
    return await axios.post(ALERT_SERVICE.json_alert)
    .then(res => {
        return res.data.result.data
    })
    .catch(err => {
        console.log(err)
        return []
    })

}
export const changeStatus = async (alert) => {
  const params = new URLSearchParams();
  params.append('id', alert.id)
  params.append('index', alert.index)
  return await axios.post(ALERT_SERVICE.inbox_change_read_status, params)
    .then(res => {
        return res.status
    })
    .catch(err => {
        return 'fail'
    })
}
export const getAlertInbox = async () => {
    return await axios.post(ALERT_SERVICE.inbox_alert)
    .then(res => {
        if (res.data.data.length === 1 ) {
            return res.data.data[0]
        } else {
            return {status: 0}
        }
    })
    .catch(err => {
        return 'fail'
    })
}
export const getUnreadAlert = async () => {
    return await axios.post(ALERT_SERVICE.get_unread)
    .then(res => {
        return res.data.data
    })
    .catch(err => {
        console.log(err)
        return []
    })
}
export const MakeAlertRead = async (alert_id) => {
    const params = new URLSearchParams();
    params.append('alert_id', alert_id);
    return await axios.post(ALERT_SERVICE.read_status, params)
    .then(res => {
        return res.data.data
    })
    .catch(err => {
        console.log(err)
        return []
    })
}