/* eslint-disable */

import React from "react";
import {NavLink} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingOverlay from 'react-loading-overlay';
import { Icons } from "../../../helpers/Constant"
import data from '../../../storage/data/html_daily_advertise_alert.json';
import data2 from '../../../storage/data/html_daily_ad_alert.json'
import { getCSSPropertyToValue } from "../../../helpers/Methods"


function DailyAdv(){
    document.title = "DailyAdv - classic"
    
	// state for page loading indicator
	let [loading, setLoading] = React.useState(false)
    let [alert, setAlert] = React.useState(data.company)
    let [banner, setBanner] = React.useState(data.banner)
    let [created, setCreated] = React.useState(data.created_at)

    let [column, setColumn] = React.useState(data.column)
    let [displayColumn, setDisplayColumn] = React.useState('')
    let [video, setVideo] = React.useState('')
    let [videoTitle, setVideoTitle] = React.useState('')

    // fetch individual media data
    let [televisionData, setTelevisionData] = React.useState(data2.Television)
    let [radioData, setRadioData] = React.useState(data2.Radio)
    let [printMediaData, setPrintMediaData] = React.useState(data2.Print_Media)
    let [onlineMediaData, setonlineMediaData] = React.useState(data2.Online_Media)

    // set alert title 
    let alert_title = data2.title

    const setVideoURLToModel = (link) => {
        // set meida name
        setVideoTitle(link.media_name)
        // get array of video url
        let video_url_arr = link.media_url.split('=')
        // get only youtube video
        let video_code = video_url_arr[video_url_arr.length-1]
        // set youtube video code 
        setVideo(video_code)
    }

    const withLinked = (link) => {
        let i=0;
        let view = link.map((data)=>{
            i++
            return(
                <>
                    {(i==1) ? (
                        <span 
                            className="p-1"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={()=>{setVideoURLToModel(link)}}
                            style={{color:'#000',backgroundColor:'#e4e4e4', marginRight:"0.3rem", border:"1px solid #c4c2c2", fontSize:'0.7rem', fontWeight:"bold"}}>
                                {data[1]}
                        </span>
                    ) : (
                        <small>
                            &nbsp;{data[1]}&nbsp;, 
                        </small>
                    )}
                </>
            )
        })
        return view
    }

    const generateTitle = (data) => {
        // console.log('data ', data)
        return(
            <>
                <div className="col-md-12" style={{wordWrap:'break-word'}}>
                    <strong>
                        {(data.link.length !== 0) ? (
                            <small>{data.title}</small>
                        ) : (
                            <small className="no-periodical-ad">{data.title}</small>
                        )}  
                    </strong>
                    <br/>
                    {(data.link.length === 0) ? '' : withLinked(data.link)}
                    <hr/>
                </div>
            </>
        )
    }

    const displayData = (data) => {
        // get logo
        let logo = data.info.logo

        // get bg color
        let title_bg = `#${getCSSPropertyToValue(data.info.bg)}`

        return (
            <>
                <div className={`col-md-12`}>
                    <div className="col-md-12 px-2" style={{backgroundColor:title_bg, color:"#fff", textAlign:""}}>
                        <img src={logo} style={{width:"15px", height:"15px", marginRight:"0.5rem"}} />
                        <small>{data.info.name}</small>
                    </div>
                    <div className="col-md-12">
                        {data.details.map((alert) =>(
                            generateTitle(alert)
                        ))}
                    </div>
                </div>
            </>
        )
    }

    React.useEffect( () => {},[])

    
    return(
        <>
            <LoadingOverlay active={loading} spinner text='Fetching Data ... ...' >
                <div style={{minHeight:100+'vh'}}>

                    <div className="top-bar bg-prim-2">
                        <div className="container">
                            <div id="overlay"></div>
                            <div className="row min-height-50px">
                                <div className="col-3 col-md-2 item-center min-height-50px">
                                    <img src={window.location.origin+"/assets/images/icons/home.svg"} alt="" className="img-fluid icon-15  sm-d-dn" />
                                    <div className="px-2">
                                        <NavLink to="/portal/home">
                                            <strong className="font-white  sm-d-font-12">Dashboard</strong>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 border-left item-center min-height-50px">
                                    <a href="/">
                                        <strong className="font-white sm-d-font-12">
                                            Daily Ad Alert
                                        </strong>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="content-section">
                        <div className="container">
                            <div className="row py-4 mx-1 mx-md-0">
                                <div className="col-md-10 my-md-3 py-2 alert-details-block">

                                    <div className="row py-3">
                                        <div className="col-2 col-md-1 alert-img">
                                            <div className="alert-img">
                                                <img src={Icons.roundBlue} alt="" className="img-fluid search-icon-wh" />
                                            </div>
                                        </div>
                                        <div className="col-10 col-md-8">
                                            <div className="alert-title font-prim">{alert_title}</div>
                                            <div className="alert-from font-soft-gray-2 font-size-12">From: Ryans Archives Report Team</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="alert-date-time">
                                                <strong className="font-soft-gray-2 font-size-12">
                                                    28 July 2021 | 10:25 PM
                                                    {created}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <img src={banner} style={{width: '100%', height: '175px'}}/>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {/* display television column if exists */}
                                        <div className="col-md-3 ad-tv-bg">
                                            <div className="col-md-12 ad-tv">
                                                <strong>Television</strong>
                                            </div>
                                            {televisionData.map((alert) => (
                                                displayData(alert)
                                            ))}
                                        </div>

                                        {/* display radio column if exists */}
                                        <div className="col-md-3 ad-radio-bg">
                                            <div className="col-md-12 ad-radio">
                                                <strong>Radio</strong>
                                            </div>
                                            {radioData.map((alert) => (
                                                displayData(alert)
                                            ))}
                                        </div>

                                        {/* display print media column if exists */}
                                        <div className="col-md-3 ad-tv-bg">
                                            <div className="col-md-12 ad-tv">
                                                <strong>Print Media</strong>
                                            </div>
                                            {printMediaData.map((alert) => (
                                                displayData(alert)
                                            ))}
                                        </div>

                                        {/* display online media column if exists */}
                                        <div className="col-md-3 ad-radio-bg">
                                                <div className="col-md-12 ad-radio">
                                                <strong>Online Media</strong>
                                            </div>
                                            {onlineMediaData.map((alert) => (
                                                displayData(alert)
                                            ))}
                                        </div>

                                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">
                                                        {videoTitle}
                                                    </h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                <iframe 
                                                    id="cartoonVideo"
                                                    className="embed-responsive-item"
                                                    style={{width:"100%", height:"15rem"}}
                                                    src={`//www.youtube.com/embed/${video}`} allowFullScreen>
                                                </iframe>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default DailyAdv;
