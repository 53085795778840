import React, { useEffect } from 'react';
import Layout from './apps/pages/layouts/Index';
import LoadingOverlay from 'react-loading-overlay';
import NotificationContainer from './apps/helpers/NotificationContainer';
import { showNotification } from './apps/helpers/showNotification';
export const LoadingContext = React.createContext(false);
function App() {
  const [loading, setLoading] = React.useState(false)
  const [loadingMessage, setLoadingMessage] = React.useState('Please wait ... ...')
  function changeLoadingState(flag){
    if(flag){
      setLoading(true);
    }else{
      setLoading(false);
    }
  }
  useEffect(() => {
    (function checkNetworkConnectivity(){
      window.addEventListener("load", () => {        
        !navigator.onLine && showNotification("error", "Lost Network Connection")
  
        window.addEventListener("online", () => {
          showNotification("success", "Network Connection Stable")
        })
  
        window.addEventListener("offline", () => {
          showNotification("error", "Lost Network Connection")
        })
      })
    })()
  }, 
  [])
  return (
    <>
      <LoadingContext.Provider value={{loading, changeLoadingState, loadingMessage, setLoadingMessage}}>
        <LoadingOverlay active={loading} spinner text={loadingMessage}>
          <Layout/>
          <NotificationContainer />
        </LoadingOverlay>
      </LoadingContext.Provider>
    </>
  );
}
export default App;