import React from "react";
import { NavLink } from "react-router-dom";

export default function DataServicePageSelection() {
    document.title = "Data Service"

    return (
        <>
			<h4 className="category-title mb-3">Choose Data Type</h4>
            <div className="service-selection">
                <NavLink
                    to={{
                        pathname: "/portal/data-service",
                        state: "Advertisement",
                    }}
                >
                    <div className="service-selection-box">
                        <img
                            src="../assets/images/dataservice/data-image.jpg"
                            alt="Data Service"
                            className="img-fluid service-selection-image"
                        />
                        <h3 className="service-selection-title mt-3">
                            Advertisement Data
                        </h3>
                        <h6
                            className="text-center mt-2"
                            style={{ color: "grey" }}
                        >
                            TV Media, Radio Media, Print Media & Online Media
                            Advertisement Data
                        </h6>
                    </div>
                </NavLink>

                <NavLink
                    to={{
                        pathname: "/portal/data-service",
                        state: "News",
                    }}
                >
                    <div className="service-selection-box">
                        <img
                            alt="News Service"
                            src="../assets/images/dataservice/news-image.jpg"
                            className="img-fluid service-selection-image"
                        />
                        <h3 className="service-selection-title mt-3"> News Data </h3>
                        <h6 className="text-center mt-2" style={{ color: "grey" }}>
                            TV Media, Radio Media, Print Media & Online Media
                            News Data
                        </h6>
                    </div>
                </NavLink>
                
            </div>
        </>
    );
}
