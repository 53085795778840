import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function FormDatePicker(props) {
    const [open, setOpen] = useState(false)
    const {
        label,
        state,
        setState,
        defaultCalendarMonth = new Date(),
        minDate = null,
        maxDate = null,
        error = false
    } = props;
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={state}
                onChange={(newValue) => {
                    setState(newValue);
                }}
                defaultCalendarMonth={defaultCalendarMonth}
                minDate={minDate}
                maxDate={maxDate}
                inputFormat={"dd-MMM-yyyy"}
                disableMaskedInput
                renderInput={(props) => {
                    return (
                        <TextField
                            {...props}
                            autoComplete="off"
                            onClick={() => setOpen(true)}
                            size="small"
                            sx={(theme) => (
                                error
                                ?
                                {
                                    width: 400,
                                    [theme.breakpoints.down('sm')]: {
                                        width: 'calc(100vw - 3rem)',
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& > fieldset": {
                                            borderColor: "#F94C66",
                                        },
                                    },
                                    "& .MuiOutlinedInput-root.Mui-focused": {
                                        "& > fieldset": {
                                            borderColor: "#F94C66",
                                            "& > legent": {
                                                color: "#F94C66",
                                            },
                                        },
                                    },
                                    "& .MuiSvgIcon-root": {
                                        color:"#F94C66"
                                    }
                                    
                                }
                                :
                                {
                                    width: 400,
                                    [theme.breakpoints.down('sm')]: {
                                        width: 'calc(100vw - 3rem)'
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& > fieldset": {
                                            borderColor: "#27606C",
                                        },
                                    },
                                    "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": {
                                            border: "2px solid #27606C",
                                        },
                                    },
                                    "& .MuiOutlinedInput-root.Mui-focused": {
                                        "& > fieldset": {
                                            borderColor: "#27606C",
                                            "& > legent": {
                                                color: "#27606C",
                                            },
                                        },
                                    },
                                    "& .MuiSvgIcon-root": {
                                        color:"#27606C"
                                    }
                                }

                            )
                            }
                            InputLabelProps={{
                                style: { color: `${error ? '#F94C66' : "#27606C"}` },
                            }}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );
}
