import React, { useEffect, useState } from "react";
import "./navbar.css";
import {getCookies, removeCookies} from "../../helpers/Cookies"
import { removeStorages, removeStore } from "../../helpers/Storage"
import { NavLink, useHistory } from "react-router-dom";
import * as api from "../../api/request/DataServiceAPI";
import ModernDropdown from "../dropdrowns/ModernDropdown";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeIcon from '@mui/icons-material/Home';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function Navbar() {
    const [clientEmail, setClientEmail] = useState('')
    // hamburger show/hide state
    const [menuBarCSS, setMenuBarCSS] = useState('')
    // toggling mobile menu sub list
    const [open, setOpen] = React.useState(false);

    const history = useHistory()

    const handleClick = () => {
        setOpen(!open);
    };

    // client logout 
    const logOut = async (e) => {
        const log_out = await api.clientLogout();
        // console.log(log_out);

        // clear client cookies
        removeCookies('udata', {path:"/"});

        // clear client storage data
        removeStore('alert_service');

        removeStorages();

        // notify server that logged out

        window.location.href = "/"
    }

    // hamburger show/hide controller
    const handleMenuButton = () => {
        setMenuBarCSS((prev) => prev === '' ? 'block' : '');
    }

    // fetching client information from cookies
    useEffect(() => {
        const user_data = getCookies('udata')
        setClientEmail(user_data?.client?.email)
    }, 
    // eslint-disable-next-line
    [])

	return (
        <>
            <div className="bg-prim-2 height-3 nav-center-element">
                <div className="container navbar flex-item">
                    <div className="d-flex gap-4">
                        <span className="navlink">
                            <NavLink 
                                to="/portal/home"
                                className="text-white"
                            >
                                Home
                            </NavLink>
                        </span>
                        <span className="navlink">
                            <NavLink 
                                to="/portal/alert-service"
                                className="text-white"
                            >
                                Alert Services
                            </NavLink>
                        </span>
                        {/* <span className="navlink toggle-dropdown"> */}
                        <span className="dropdown">
                            <NavLink 
                                to="/portal/data-service-selection"
                                className="text-white"
                            >
                                Data Services
                            </NavLink>
                            {/* <Dropdown /> */}
                            <ModernDropdown />
                        </span>
                        <span className="navlink">
                            <NavLink 
                                to="/portal/other-service"
                                className="text-white"
                            >
                                Other Services
                            </NavLink>
                        </span>
                    </div>

                    <div className="text-white">
                        <span className="fullname">{ clientEmail }</span>
                        <button className="btn-nav" onClick={() => history.push("/instruction")}>
                            <span className="btn-nav-logout">Help</span>
                        </button>
                        <button className="btn-nav" onClick={logOut}>
                            {/* <a href="/" className="btn-nav-logout">Log Out</a> */}
                            <span className="btn-nav-logout">Log Out</span>
                        </button>
                    </div>
                </div>

                {/* Mobile Navbar */}
                <div className="mobile-navbar">
                    <span onClick={handleMenuButton}>Menu</span>
                    <div className="mobile-navbar-menu" style={{ display: `${menuBarCSS}` }}>
                        <List
                            sx={{ 
                                width: "100%",  
                                bgcolor: "background.paper",
                                boxShadow: '0 2px 5px 0 rgba(0,0,0,0.57)'
                            }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            <NavLink 
                                to="/portal/home"
                                className="text-bg d-flex"
                                onClick={() => setMenuBarCSS('')}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <HomeIcon className="text-bg" />
                                    </ListItemIcon>
                                    <ListItemText primary="Home" />
                                </ListItemButton>
                            </NavLink>
                            <NavLink 
                                to="/portal/alert-service"
                                className="text-bg d-flex"
                                onClick={() => setMenuBarCSS('')}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <AssessmentIcon className="text-bg" />
                                    </ListItemIcon>
                                    <ListItemText primary="Alert Services" />
                                </ListItemButton>
                            </NavLink>
                            <ListItemButton onClick={handleClick}>
                                <ListItemIcon>
                                    <TextSnippetIcon className="text-bg" />
                                </ListItemIcon>
                                <ListItemText className="text-bg" primary="Data Services" />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <NavLink 
                                    className="text-bg d-flex" 
                                    to={{
                                        pathname: "/portal/data-service",
                                        state: "Advertisement"
                                    }}
                                    onClick={() => {
                                        setMenuBarCSS('')
                                        setOpen(!open)
                                    }}
                                >
                                    <List component="div" disablePadding>
                                        <ListItemButton sx={{ paddingLeft: 4 }}>
                                            <ListItemIcon>
                                                <ArrowForwardIosIcon className="text-bg" />
                                            </ListItemIcon>
                                            <ListItemText primary="Advertisement Data" />
                                        </ListItemButton>
                                    </List>
                                </NavLink>
                                <NavLink 
                                    className="text-bg d-flex" 
                                    to={{
                                        pathname: "/portal/data-service",
                                        state: "News"
                                    }}
                                    onClick={() => {
                                        setMenuBarCSS('')
                                        setOpen(!open)
                                    }}
                                >
                                    <List component="div" disablePadding>
                                        <ListItemButton sx={{ paddingLeft: 4 }}>
                                            <ListItemIcon>
                                                <ArrowForwardIosIcon className="text-bg" />
                                            </ListItemIcon>
                                            <ListItemText primary="News Data" />
                                        </ListItemButton>
                                    </List>
                                </NavLink>
                            </Collapse>
                            <NavLink 
                                to="/portal/other-service"
                                className="text-bg d-flex"
                                onClick={() => setMenuBarCSS('')}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <MiscellaneousServicesIcon className="text-bg" />
                                    </ListItemIcon>
                                    <ListItemText primary="Other Services" />
                                </ListItemButton>
                            </NavLink>
                        </List>
                    </div>
                    <a href="/" className="text-white btn-logout" onClick={logOut}>Log Out</a>
                </div>
            </div>
        </>
    );
}
