/* eslint-disable */

import React from "react"
import utf8 from "utf8"
import { createLinks } from './Links'

// display compitator news here
export const DisplayPrintOnline = (data, companyInfo={}, type='') => {
    
    // define style
    let style = { backgroundColor: "#fffde7", padding: '1rem', marginBottom:'1rem' }
    
    let linksDiv = ""
    let printDiv = ""
    let onlineDiv = ""
    let tv_radioDiv = ""

    let linksLength = 0
    let printLength = 0
    let onlineLength = 0
    let tv_radioLength = 0

    // assign alert title
    // let title = utf8.decode(data.title)
    let title = data.title

    // assign company name
    let company_name = companyInfo.company_name

    // assign company logo
    let company_logo = companyInfo.company_logo

    // set default company bg color
    let company_bg = '#90a4ae';

    // assign company set color
    if(companyInfo.company_bg){
        let bg_arr = companyInfo.company_bg.split('#')
        let bg_code = bg_arr[1].split(';')
        // re-assign company bg color
        company_bg = `#${bg_code[0]}`
    }

    // if links exists
    if (typeof data.link === 'object') {
        linksDiv = createLinks(data.link.info)
        linksLength = data.link.info.length
    }
    
    // if print exists
    if (typeof data.print === 'object') {
        printDiv = createLinks(data.print.info)
        printLength = data.print.info.length
    }

    
    // if online links exists
    if (typeof data.online === 'object') {
        onlineDiv = createLinks(data.online.info)
        onlineLength = data.online.info.length
    }

    // if tv/radio links exists
    if (typeof data.tv_radio === 'object') {
        tv_radioDiv = createLinks(data.tv_radio.info)
        tv_radioLength = data.tv_radio.info.length
    }
    
    return (
        <div className="pb-4" style={style}>
            <div className="row">
                <div className="col-6">
                    <div style={{textAlign: "left"}}>
                        <span style={{background: company_bg, color:"#fff", padding: "1rem"}}>
                            {type}
                        </span>
                    </div>
                </div>
                <div className="col-6">
                    <div style={{textAlign: "right"}}>
                        <span style={{background: company_bg, color:"#fff", padding: "1rem"}}>
                            {(company_logo) ? (
                                <img src={company_logo} style={{width:"15px", height:"15px", marginRight: '0.5rem'}} />
                            ) : ''  }
                            {company_name}
                        </span>
                    </div>
                </div>
            </div>
            
           
            <hr/>
            <div>
                <h6>{title}</h6>
            </div>

            {/* if synopsis exists then print */}
            {(data.synopsis)?(
                <div>
                    <strong> Synopsis : </strong> {data.synopsis}
                </div>
            ) : '' }

            {/* if synopsis exists then print */}
            {(data.synopsis2)?(
                <div className="pt-4">
                    {data.synopsis2}
                </div>
            ) : '' }
            
            {/* Its shows when links exists */}
            { (linksLength > 0) ? (
                <div className="row">
                    <div className="col-md-3 py-2">
                        <b className="btn btn-success btn-sm">
                            Links &nbsp; &nbsp; &rarr; &nbsp; &nbsp; {linksLength}
                        </b>
                    </div>
                    <div className="col-md-9 py-2">{linksDiv}</div>
                </div>
            ) : '' }

            {/* Its shows when print exists */}
            { (printLength > 0) ? (
                <div className="row">
                    <div className="col-md-3 py-2">
                        <b className="btn btn-success btn-sm">
                            Print Source &nbsp; &nbsp; &rarr; &nbsp; &nbsp; {printLength}
                        </b>
                    </div>
                    <div className="col-md-9 py-2">{printDiv}</div>
                </div>
            ) : '' }
            
            {/* Its shows when online exists */}
            { (onlineLength > 0) ? (
                <div className="row">
                    <div className="col-md-3 py-2">
                        <b className="btn btn-success btn-sm">
                            Online Source &nbsp; &nbsp; &rarr; &nbsp; &nbsp; {onlineLength}
                        </b>
                    </div>
                    <div className="col-md-9 py-2">{onlineDiv}</div>
                </div>
            ) : '' }

            {/* Its shows when tv/radio exists */}
            { (tv_radioLength > 0) ? (
                <div className="row">
                    <div className="col-md-3 py-2">
                        <b className="btn btn-success btn-sm">
                            Tv/Media Source &nbsp; &nbsp; &rarr; &nbsp; &nbsp; {tv_radioLength}
                        </b>
                    </div>
                    <div className="col-md-9 py-2">{tv_radioDiv}</div>
                </div>
            ) : '' }

        </div>
    )
}
