export const AlertServicePage = {
    'download':{cursor: "pointer",color:"green"},
}

export const TextEmailNew = {
    'background': '#C23D46',
    'color': '#fff',
    'padding': '0.2rem 0.3rem',
    'border-radius': '1.5rem',
    'font-size': '0.6em'
}
export const alert_service_title_bg = "#BDBDBD"