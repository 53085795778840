import { AD_REPORT } from "../../../helpers/dataservice/Settings";
import AdvMediaCategories from "./components/AdvMediaCategories";
import AdvRadioDataForm from "./forms/AdvRadioDataForm";

function AdvRadioMediaDataPage() {
    document.title = "Data - Radio Media";

    return (
        <div className="content-section">
            <div className="container">
                <AdvMediaCategories />
                <h4 className="category-title">
                    Radio Media (Advertisement Data)
                </h4>

                <AdvRadioDataForm dataformFor={AD_REPORT.RADIO} />
            </div>
        </div>
    );
}

export default AdvRadioMediaDataPage;
