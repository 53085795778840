/* eslint-disable */
import axios from 'axios';
import { getCookies } from '../../helpers/Cookies';
import { saveAs } from 'file-saver';
import { FileType, ContentType, MIMETypes } from '../../helpers/Constant';
import { ALERT_SERVICE } from "../APIEndPoints";
export const GetAndDownload = async (filter={}) => {
    try{
        const { id, path, type, alert_flag } = filter;
        let request_url = '';
        let MIME = ''
        switch (type) {
            case FileType.pdf:
                request_url = ALERT_SERVICE.getPdfFile;
                MIME = MIMETypes.PDF;
                break;
            case FileType.ppt:
                request_url = ALERT_SERVICE.getPptFile;
                MIME = MIMETypes.PPT;
                break;
            case FileType.zip:
                request_url = ALERT_SERVICE.getZipFile;
                MIME = MIMETypes.ZIP;
                break;
            case FileType.excel:
                request_url =  ALERT_SERVICE.getExcelFile;
                MIME = MIMETypes.EXCEL;
                break;
            case FileType.docx:
                request_url = ALERT_SERVICE.getDocFile;
                MIME = MIMETypes.DOC;
                break;
            default:
                return false;
        }

        const config = {
            id, path, type, responseType: "arraybuffer", responseEncoding: "binary",
        }
        const params = new URLSearchParams();
        params.append('id', id);
        params.append('path', path);
        params.append('type', type);
        params.append('flag', alert_flag);
        
        const result = await AxiosInstance('FILE', null, true).post(request_url, params)
                .then(response => {
                    let src = MIME + response.data;
                    // if(type === 'ZIP'){
                    //     const byteCharacters = atob(src.split(",")[1]);
                    //     const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
                    //     const byteArray = new Uint8Array(byteNumbers);

                    //     const blob = new Blob([byteArray], { type: "application/zip" });

                    //     saveAs(blob, path);
                    // } else {
                    //     saveAs(src, path)
                    // }
                    saveAs(src, path)
                    return true;
                })
                .catch(error => {
                    console.log(error.message)
                    return true;
                })
        return result;

    } catch(err){
        console.log(err);
        return true;
    }
}
export const GetJSONAlert = async (filter={}) => {
    try{
        const { id, subscription_id, fileURL } = filter;
        let request_url = ALERT_SERVICE.getJSONAlert;
        const params = new URLSearchParams();
        params.append('id', id);
        params.append('file', fileURL);
        params.append('s_id', subscription_id);
        params.append('type', 'json-alert');
        const result = await AxiosInstance('FILE', 'json', true).post(request_url, params)
            .then(response => {
                return response.data
            })
            .catch(error => {
                console.log(error.message)
                return []
            })
        return result;

    } catch(err){
        console.log(err);
        return [];
    }
}
const AxiosInstance = (server='', content='', authenticate=false) => {
    let baseURL = process.env.REACT_APP_API_ENDPOINT;
    let contentType = ContentType.urlEncoded;
    if(server === 'FILE'){
        baseURL = process.env.REACT_APP_RESOURCE_ENDPOINT;
    }
    if(content === 'text/html'){
        contentType = ContentType.textHtml;
    }
    let instance  = axios.create({
        baseURL,
        headers: {
            'content-type': contentType,
            'Authorization': authenticate ? UserToken() : ''
        }
    });
    if(content === 'json'){
        instance  = axios.create({
            baseURL,
            headers: {
                'Authorization': authenticate ? UserToken() : ''
            }
        })
    }
    return instance;
}
const UserToken = () => {
    let token = null
    if(getCookies('udata') && getCookies('udata') !== undefined && getCookies('udata') !== null) {
        const {access} = getCookies('udata')
        if(access && access.token !== undefined && access.token !== null ){
            token = access.token
        }
    }
    return token
}
