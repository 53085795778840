import { NavLink } from "react-router-dom";
function NotificationComponent(){
    document.title = "Notification"
    return(
        <>
            <div className="top-bar bg-prim-2">
                <div className="container">
                    <div className="row min-height-50px">
                        <div className="col-3 col-md-2 item-center min-height-50px">
                            <img src={window.location.origin+"/assets/images/icons/home.svg"} alt="" className="img-fluid icon-15 sm-d-dn" />
                            <div className="px-2">
                                <NavLink to="/portal/home">
                                    <strong className="font-white sm-d-font-12">Dashboard</strong>
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-3 col-md-2 border-left item-center min-height-50px">
                            <NavLink to="/portal/request-service">
                                <strong className="font-white sm-d-font-12">Request Service</strong>
                            </NavLink>
                        </div>
                        <div className="col-3 col-md-2 border-left border-right item-center min-height-50px">
                            <a href="/notification.html" className="active">
                                <strong className="font-white sm-d-font-12">Notification</strong>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-section">
                <div className="container">                    
                    <div className="row ">
                        <div className="col-md-12 py-4 padding-l-two-rem">
                            <h6>Notification</h6>
                            <span className="font-size-14">
                                Welcome back, in this time you have 4 new mail, and 15 new announcement.
                            </span>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <hr/>
                            <p>Copyright © 2021 Rayans Archives Limited, All rights reserved </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotificationComponent