import React, {useState} from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import Cookies from 'universal-cookie'
import Authapi from "../../context/Authapi"
import { PrivateRoutesDataService, ProtectedRoute } from "../../route/Routes"
import HomeComponent from  "../home/Homepage.jsx"
import LoginComponent from "../auth/Loginpage.jsx"
import ForgetPasswordComponent from "../auth/ForgetPasswordPage.jsx"
import NotificationsComponent from "../notification/NotificationPage"
import RequestServiceComponent from "../request_service/RequestServicePage"
import AlertServiceComponent from "../alert_service/AlertServicePage"
import AlertServiceDetailsPage from "../alert_service/AlertSeviceDetailsPage"
import JSONAlertServiceDetailsPage from "../alert_service/JSONAlertSeviceDetailsPage"
import DataServiceComponent from "../data_service/DataServicePage"
import AdDataComponent from "../ad_data/AdDataPage"
import DailyNews from "../alert_service/dev-modern-design/DailyNews"
import DailyAdv from "../alert_service/dev-modern-design/DailyAdv"
import PeriodicAdvElastic from "../alert_service/dev-modern-design/PeriodicAdvElastic"
import DailyNewsClassic from "../alert_service/dev-classic-design/DailyNews"
import DailyAdvClassic from "../alert_service/dev-classic-design/DailyAdv"
import PeriodicAdvElasticClassic from "../alert_service/dev-classic-design/PeriodicAdvElastic"
import PageNotFoundComponent from "./404";
import AlertInbox from "../alert_service/AlertInbox"
import Navbar from "../../helpers/navbar/Navbar"
import OtherServicePage from "../other_service/OtherServicePage"
import NotAllowedPage from "../not_allowed_service/NotAllowedPage"
import DataServicePageSelection from "../data_service/DataServicePageSelection"
import AdvTvMediaDataPage from "../data_service/advertisement-data/AdvTvMediaDataPage"
import AdvRadioMediaDataPage from "../data_service/advertisement-data/AdvRadioMediaPage"
import AdvPrintMediaDataPage from "../data_service/advertisement-data/AdvPrintMediaDataPage"
import AdvOnlineMediaDataPage from "../data_service/advertisement-data/AdvOnlineMediaDataPage"
import NewsRadioMediaDataPage from "../data_service/news-data/NewsRadioMediaDataPage"
import NewsOnlineMediaDataPage from "../data_service/news-data/NewsOnlineMediaDataPage"
import NewsTvMediaDataPage from "../data_service/news-data/NewsTvMediaDataPage"
import NewsPrintMediaDataPage from "../data_service/news-data/NewsPrintMediaDataPage"
import InstructionPage from "../instruction-data-download/InstructionPage"
import ChromeInstruction from "../instruction-data-download/ChromeInstruction"
import FirefoxInstruction from "../instruction-data-download/FirefoxInstruction"

const cookies = new Cookies();

function Index(){
    let auth_status;
    const session_data = cookies.get('udata');
    if(session_data){
        if( session_data.client.row && 
            session_data.client.user &&
            session_data.access.token ){
            auth_status = true;
        }else{
            auth_status = false;    
        }
    }else{
        auth_status = false;
    }
    const [auth, setAuth] = useState(auth_status);
    const authControl = () => setAuth(auth? true : false);
    const dataServiceInfo = session_data?.service?.list

    return(
        <>
            <Authapi.Provider value={{ auth, authControl, dataServiceInfo }}>
                <Router>
                    <Switch>
                        <Route  exact path="/" auth={auth} 
                                component={ () => { 
                                    return (auth) ? <Redirect to="/portal/home" /> : <LoginComponent/>
                                }} />
                        <Route  exact path="/forget-password" auth={auth} 
                                component={ () => { 
                                    return (auth) ? <Redirect to="/portal/home" /> : <ForgetPasswordComponent/> 
                                }} />
                        <Route>
                            <Routes />
                        </Route>
                    </Switch>
                </Router>
            </Authapi.Provider>
        </>
    )
}

const Routes = () => { 
    const Auth = React.useContext(Authapi);

    return(
        <div style={{ height: '100vh' }}>
            <Navbar />

            <Switch>
                <ProtectedRoute  exact path="/portal/home"   auth={Auth.auth} component={HomeComponent} />
                <ProtectedRoute  path="/portal/notification"  auth={Auth.auth} component={NotificationsComponent} />
                <ProtectedRoute  path="/portal/request-service"  auth={Auth.auth} component={RequestServiceComponent} />
                <ProtectedRoute  path="/portal/alert-service"  auth={Auth.auth} component={AlertServiceComponent} />
                <ProtectedRoute  path="/portal/data-service"  auth={Auth.auth} component={DataServiceComponent} />
                <ProtectedRoute  path="/portal/data-service-selection"  auth={Auth.auth} component={DataServicePageSelection} />
                <ProtectedRoute  path="/portal/ad-data"  auth={Auth.auth} component={AdDataComponent} />
                <PrivateRoutesDataService  path="/portal/adv/tv-media-data"  auth={Auth} type="ad" media="tv" component={AdvTvMediaDataPage} />
                <PrivateRoutesDataService  path="/portal/adv/radio-media-data"  auth={Auth} type="ad" media="radio" component={AdvRadioMediaDataPage} />
                <PrivateRoutesDataService  path="/portal/adv/print-media-data"  auth={Auth} type="ad" media="print" component={AdvPrintMediaDataPage} />
                <PrivateRoutesDataService  path="/portal/adv/online-media-data"  auth={Auth} type="ad" media="online" component={AdvOnlineMediaDataPage} />
                <PrivateRoutesDataService  path="/portal/news/tv-media-data"  auth={Auth} type="news" media="tv" component={NewsTvMediaDataPage} />
                <PrivateRoutesDataService  path="/portal/news/radio-media-data"  auth={Auth} type="news" media="radio" component={NewsRadioMediaDataPage} />
                <PrivateRoutesDataService  path="/portal/news/print-media-data"  auth={Auth} type="news" media="print" component={NewsPrintMediaDataPage} />
                <PrivateRoutesDataService  path="/portal/news/online-media-data"  auth={Auth} type="news" media="online" component={NewsOnlineMediaDataPage} />
                <ProtectedRoute  exact path="/portal/alert-details/:id" auth={Auth.auth} component={AlertServiceDetailsPage} />
                <ProtectedRoute  exact path="/portal/json-alert-details/:id" auth={Auth.auth} component={JSONAlertServiceDetailsPage} />
                <ProtectedRoute  exact path="/portal/dev-dailynews-modern-alert" auth={Auth.auth} component={DailyNews} />
                <ProtectedRoute  exact path="/portal/dev-dailyadv-modern-alert" auth={Auth.auth} component={DailyAdv} />
                <ProtectedRoute  exact path="/portal/dev-PeriodicAdvElastic-modern-alert" auth={Auth.auth} component={PeriodicAdvElastic} />
                <ProtectedRoute  exact path="/portal/dev-dailynews-classic-alert" auth={Auth.auth} component={DailyNewsClassic} />
                <ProtectedRoute  exact path="/portal/dev-dailyadv-classic-alert" auth={Auth.auth} component={DailyAdvClassic} />
                <ProtectedRoute  exact path="/portal/periodical-alert-service-classic" auth={Auth.auth} component={PeriodicAdvElasticClassic} />
                <ProtectedRoute  exact path="/portal/other-service"  auth={Auth.auth} component={OtherServicePage} />
                <ProtectedRoute  exact path="/portal/contact-for-service"  auth={Auth.auth} component={NotAllowedPage} />
                <Route exact path="/inbox" component={AlertInbox} />
                <ProtectedRoute  exact path="/instruction" auth={Auth.auth} component={InstructionPage} />
                <ProtectedRoute  exact path="/instruction/chrome" auth={Auth.auth} component={ChromeInstruction} />
                <ProtectedRoute  exact path="/instruction/firefox" auth={Auth.auth} component={FirefoxInstruction} />

                <ProtectedRoute  path="*" auth={Auth.auth} component={PageNotFoundComponent} />
            </Switch>
        </div>
    )
}

export default Index