import AdvMediaCategories from "./components/AdvMediaCategories";
import AdvPrintDataForm from "./forms/AdvPrintDataForm";

function AdvPrintMediaDataPage() {
    document.title = "Data - Print Media";

    return (
        <div className="content-section">
            <div className="container">
                <AdvMediaCategories />
                    <h4 className="category-title">
                        Print Media (Advertisement Data)
                    </h4>
                <AdvPrintDataForm />
            </div>
        </div>
    );
}

export default AdvPrintMediaDataPage;
