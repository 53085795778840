
function PageNotFoundComponent(){
    document.title = "404 Page Not Found"
    return(
        <>
            <div className="row">
                <div className="col-sm-12 text-center">
                    <img className="img-fluid" src="../assets/images/icons/404.gif" alt="404 Not Found"/>
                    <br/>
                    <a href="/" className="m-3 p-3 bg-prim-2 text-white">
                        Go To Home
                    </a>
                </div>
            </div>
        </>
    )
}

export default PageNotFoundComponent