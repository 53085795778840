/* eslint-disable */

import React from "react"
import {NavLink} from "react-router-dom"
import "react-datepicker/dist/react-datepicker.css"
import LoadingOverlay from 'react-loading-overlay'
import { Icons } from "../../../helpers/Constant"
import { getCSSPropertyToValue } from "../../../helpers/Methods"
import { alert_service_title_bg } from "../../../helpers/InlineStyle"
import { ReadableDateTime } from "../../../helpers/DateTime"
import data2 from '../../../storage/data/html_periodical_adv_alert.json';


/**
 * generate periodical elastic ad alert view using alert data
 * 
 * @method
 * @name PeriodicAdvElastic
 * @param 
 * @returns {object} generate periodical ad alert view
 * @created_at  24th November 2021
 * @created_by  Muhammad Hasan
 */
function PeriodicAdvElastic(){
    
    // title for windows tab
    document.title = "PeriodicAdvElastic - Classic"
    // title for alert 
    let [title, setTitle] = React.useState(data2.title)
    
    // set television data
    let [televisionData, setTelevisionData] = React.useState(data2.Television.details)
    // set radio data
    let [radioData, setRadioData] = React.useState(data2.Radio.details)
    // set print data
    let [printMediaData, setPrintData] = React.useState(data2.Print_Media.details)
    // set online data
    let [onlineMediaData, setOnlineMediaData] = React.useState(data2.Online_Media.details)
    
    let elastic_alert = ['Television', 'Radio', 'Print_Media', 'Online_Media']

	// state for page loading indicator
	let [loading, setLoading] = React.useState(false)
    let [banner, setBanner] = React.useState(data2.banner)
    let [created, setCreated] = React.useState(data2.created_at)
    

    let [displayColumn, setDisplayColumn] = React.useState('')
    let [video, setVideo] = React.useState('')
    let [videoTitle, setVideoTitle] = React.useState('')
    let [adType, setAdType] = React.useState(1) // 1= youtube, 2= image
    let [imageUrl, setImageUrl] = React.useState('')


    // open iframe with video or image
    const withLinked = (link="", channel="") => {
        // get array of given link
        let video_url_arr = link.split('/')
        
        if (video_url_arr.length === 4) {
            // set media type as video
            setAdType(1)
            
            // set media name
            setVideoTitle(channel)

            // get only youtube video
            let video_code = video_url_arr[video_url_arr.length-1]
            
            // set youtube video code for iframe
            setVideo(video_code)
        } else {
            // set media type as image
            setAdType(2)

            // set media name
            setVideoTitle(channel)
            
            // set image url for iframe
            setImageUrl(link)
        }

        return(
            <>
                <span 
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    className="badge badge-info"
                    style={{color:'#fff',backgroundColor:'#17a2b8', marginRight:"0.3rem"}}>
                        {link.media_name}
                </span>
            </>
        )
    }

    const generateAdAlert = (links, index, details_size) => {
        let link_index = links.link.length - 1
        // console.log(links, index, details_size)
        return(
            <> 
                {/* display alert title */}
                <div className="col-sm-12 p-2 bg-light">
                    {links.title}
                </div>

                {/* display ad links */}
                <div className="col-sm-12 bg-light pb-1 p-2">
                    {links.link.map((link, index) => (
                        (link[0] !== '') ?
                        (
                            // if ad link exists
                            <span 
                                className="badge badge-info"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => {withLinked(link[0], link[1])}}
                                style={{color:'#000',backgroundColor:'#e4e4e4', marginRight:"0.3rem", border:"1px solid #c4c2c2", fontSize:'0.7rem', fontWeight:"bold"}}>
                                {link[1]}
                            </span>
                        ) : 
                        // remove comma form the last link
                        (link_index == index) ? 
                            (
                                // if ad link does't exists
                                <small className="" style={{color:'#000'}}>
                                    &nbsp;{link[1]}&nbsp;
                                </small>
                            ) : (
                                // if ad link does't exists
                                <small className="" style={{color:'#000'}}>
                                    &nbsp;{link[1]}&nbsp;,
                                </small>
                            )
                    ))}

                    {/* draw a line after finish the links */}
                    {(index == details_size) ? '': (<hr/>)}
                </div>
            </>
        )
    }

    const getLinks = (details) => {
        // set default title bg
        let title_bg = alert_service_title_bg
        
        // get logo
        let logo = details.info.logo
        
        // get details size
        let details_size = details.details.length - 1

        // set title background color if exists
        if(details.info.bg){
            title_bg = `#${getCSSPropertyToValue(details.info.bg)}`
        }

        const generateDeatils = (detail) => {
            return(
                <>  
                    {detail.map((dt, index) => (
                        generateAdAlert(dt, index, details_size)
                    ))}
                </>
            )
        }
        
        return(
            <div className="">
                <div className="col-md-12 pt-1" style={{backgroundColor:title_bg}}>
                    <strong>
                        {(logo)  ?   (
                            <img src={logo} style={{width:"15px", height:"15px", marginRight:"0.5rem"}}/>
                        ) : ''}
                        {details.info.name}
                    </strong>
                    {generateDeatils(details.details)}
                </div>
            </div>
        )
    }
    
    const clearIframeSource = () => {
        setVideo(window.location.origin+"/assets/images/loading.gif")
        setImageUrl(window.location.origin+"/assets/images/loading.gif")
    }

    /**
     * generate alert title and links of periodical elisticle ad alert 
     * 
     * @method
     * @name displayData
     * @param {object} data 
     * @returns {view} generate periodical ad alert column
     */
    const displayData = (data) => {
        return (
            <div className={`col-md-12 my-3`}  style={{backgroundColor:data.bg, color:"#000", textAlign:"left"}}>
                {/* display alert name of alert category */}
                <h6 className="text-center py-2" style={{backgroundColor:"#d8d2d2"}}>
                    {data.info}
                </h6>
                {/* display media name and links */}
                { data.details.map( (detail) => (
                    <div className="row">
                        {getLinks(detail)}
                    </div>
                ))}
            </div>
        )
    }

    React.useEffect( () => {
        
        // preset alert column size
        const columnFormat = () => {
            let count = 0
            elastic_alert.map((value, index) =>{
                if(data2[value].details){
                    count++;
                }
            })
            let div_column = `col-md-${(12/count)}`
            // set alert number of column length
            setDisplayColumn(div_column)
            return true
        }
        columnFormat()
        
    },[])
    
    return(
        <>
            <LoadingOverlay active={loading} spinner text='Fetching Data ... ...' >
                <div style={{minHeight:100+'vh'}}>

                    <div className="top-bar bg-prim-2">
                        <div className="container">
                            <div id="overlay"></div>
                            <div className="row min-height-50px">
                                <div className="col-3 col-md-2 item-center min-height-50px">
                                    <img src={window.location.origin+"/assets/images/icons/home.svg"} alt="" className="img-fluid icon-15  sm-d-dn" />
                                    <div className="px-2">
                                        <NavLink to="/portal/home">
                                            <strong className="font-white  sm-d-font-12">Dashboard</strong>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 border-left item-center min-height-50px">
                                    <a href="/alert-service.html">
                                        <strong className="font-white sm-d-font-12">HTML Alert Test</strong>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-section">
                        <div className="container">
                            <div className="row py-4 mx-1 mx-md-0">
                                <div className="col-md-12 my-md-3 py-2 alert-details-block">
                                    
                                    <div className="row border-bottom-gray py-3">

                                        {/* display alert page icon */}
                                        <div className="col-2 col-md-1 alert-img">
                                            <div className="alert-img">
                                                <img src={Icons.roundBlue} alt="" className="img-fluid search-icon-wh" />
                                            </div>
                                        </div>

                                        {/* display periodical alert title */}
                                        <div className="col-10 col-md-8">
                                            <div className="alert-title font-prim">
                                                { (title) ? title : '' }
                                            </div>
                                            <div className="alert-from font-soft-gray-2 font-size-12">
                                                From: Ryans Archives Report Team
                                            </div>
                                        </div>

                                        {/* display periodical alert created date */}
                                        <div className="col-md-3">
                                            <div className="alert-date-time">
                                                <strong className="font-soft-gray-2 font-size-12">
                                                    {ReadableDateTime(created)}
                                                </strong>
                                            </div>
                                        </div>

                                    </div>

                                    {/* display alert page banner */}
                                    <img src={banner} style={{width: '100%', height: '175px'}}/>

                                    <div className="row">
                                        {/* display television column if exists */}
                                        { (televisionData) ? 
                                            <div className={`${displayColumn} ad-tv-bg`} style={{wordWrap:'break-word'}}>
                                                <div className="col-md-12 ad-tv">
                                                    <strong>Television</strong>
                                                </div>
                                                {televisionData.map((alert) => (
                                                    displayData(alert)
                                                ))}
                                            </div> : '' 
                                        }

                                        {/* display radio column if exists */}
                                        { (radioData) ? 
                                            <div className={`${displayColumn} ad-radio-bg`} style={{wordWrap:'break-word'}}>
                                                <div className="col-md-12 ad-radio">
                                                    <strong>Radio</strong>
                                                </div>
                                                {radioData.map((alert) => (
                                                    displayData(alert)
                                                ))}
                                            </div> : '' 
                                        }

                                        {/* display print media column if exists */}
                                        { (printMediaData != undefined) ? 
                                            <div className={`${displayColumn} ad-tv-bg`} style={{wordWrap:'break-word'}}>
                                                <div className="col-md-12 ad-print-media">
                                                    <strong>Print Media</strong>
                                                </div>
                                                {printMediaData.map((alert) => (
                                                    displayData(alert)
                                                ))}
                                            </div> : '' 
                                        }

                                        {/* display online media column if exists */}
                                        { (onlineMediaData) ? 
                                            <div className={`${displayColumn} ad-radio-bg`} style={{wordWrap:'break-word'}}>
                                                 <div className="col-md-12 ad-online-media">
                                                    <strong>Online Media</strong>
                                                </div>
                                                {onlineMediaData.map((alert) => (
                                                    displayData(alert)
                                                ))}
                                            </div> : '' 
                                        }

                                        {/* model for display video or ad image */}
                                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">
                                                        {videoTitle}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={clearIframeSource}>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                {(adType == 1) ? (
                                                    <iframe 
                                                        id="cartoonVideo"
                                                        title="Diplay Video Ad"
                                                        className="embed-responsive-item"
                                                        style={{width:"100%", height:"25rem", background:"center center no-repeat"}}
                                                        src={`//www.youtube.com/embed/${video}`} 
                                                        allowfullscreen>
                                                    </iframe>
                                                ):(
                                                    <iframe 
                                                        id="cartoonVideo"
                                                        title="Diplay Image Ad"
                                                        className="embed-responsive-item"
                                                        style={{width:"100%", height:"25rem", background:"center center no-repeat"}}
                                                        src={imageUrl}>
                                                    </iframe>
                                                )}
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-bs-dismiss="modal"
                                                        onClick={clearIframeSource}>
                                                            Close
                                                    </button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </LoadingOverlay>
        </>
    )
}

export default PeriodicAdvElastic;
