import { useEffect, useState } from "react";
import NewsMediaCategories from "./components/NewsMediaCategories";
import NewsDataForm from "./forms/NewsDataForm";

function NewsTvMediaDataPage() {
    document.title = "Data - TV Media";

    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);

    const cat = [
        {
            id: 1,
            name: "Politics",
        },
        {
            id: 2,
            name: "Sports",
        },
        {
            id: 3,
            name: "Banking",
        },
        {
            id: 4,
            name: "Telecommunication",
        },
    ];

    const sub_cat = [
        {
            id: 1,
            c_id: 1,
            name: "Students Politics",
        },
        {
            id: 2,
            c_id: 1,
            name: "Youth Politics",
        },
        {
            id: 3,
            c_id: 2,
            name: "Cricket",
        },
        {
            id: 4,
            c_id: 2,
            name: "Football",
        },
        {
            id: 5,
            c_id: 3,
            name: "General Banking",
        },
        {
            id: 6,
            c_id: 3,
            name: "ATM Card",
        },
        {
            id: 7,
            c_id: 4,
            name: "Mobile Phone Operator",
        },
        {
            id: 8,
            c_id: 4,
            name: "Telecom Industry",
        },
        {
            id: 9,
            c_id: 4,
            name: "Mobile Handset",
        },
    ];

    useEffect(
        () => {
            setCategories(cat);
            setSubcategories(sub_cat);
        },
        //eslint-disable-next-line
        []
    );

    return (
        <div className="content-section">
            <div className="container">
                <NewsMediaCategories />

                <h4 className="category-title">TV Media (News Data)</h4>

                <NewsDataForm type="news_tvc" />
                
            </div>
        </div>
    );
}

export default NewsTvMediaDataPage;
