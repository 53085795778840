export const CLIENT = {
    login: 'client/user/login',
    logout: 'client/user/logout',
    forget_password: "client/user/forget-password",
}

export const ALERT_SERVICE = {
    get: 'alert-service/get-alert',
    flash: 'alert-service/flash-alert',
    json_alert: 'alert-service/json-alert',
    json_alert_detail: 'alert-service/json-alert-detail',
    read_status: 'alert-service/change-read-status',
    inbox_alert: 'admin/alert-inbox/fetch',
    get_unread: 'admin/alert-inbox/unread',
    inbox_change_read_status: 'admin/alert-inbox/change-reads-status',
    search: 'alert-service/search',
    getPdfFile: '/file/pdf',
    getExcelFile: '/file/excel',
    getPptFile: '/file/ppt',
    getDocFile: '/file/doc',
    getZipFile: '/file/zip',
    getJSONAlert: '/file/json'
}

export const DATA_SERVICE = {
    get_adv_print_company: 'client/data-access/get-print-ad-data-option',
    get_adv_print_more_option: 'client/data-access/get-print-ad-more-option',
    get_adv_online_company: 'client/data-access/get-online-ad-data-option',
    get_adv_online_more_option: 'client/data-access/get-online-ad-more-option',
    get_adv_online_data: 'client/report/get-online-ad-data',    
    get_adv_tv_option: 'client/data-access/get-tv-ad-data-option',
    get_adv_tv_more_option: 'client/data-access/get-tv-ad-more-option',
    get_adv_tv_data_response: 'client/report/download',   
    search_adv_print_online_company: 'client/company/search/print-online',
    search_adv_tv_company: 'client/company/search/tv-ad',
    search_adv_print_online_brand: 'client/brand/search/print-online',
    search_adv_tv_brand: 'client/brand/search/tv-ad',
    search_adv_radio_brand: 'client/brand/search/radio-ad',
    search_adv_radio_company: 'client/company/search/radio-ad',
    get_adv_radio_info: 'client/data-access/get-radio-ad-data-option',
    get_adv_radio_more_info: 'client/data-access/get-radio-ad-more-option',
    radio_data_access: 'client/data-access/rdc-ad-data-option',
    get_news_tv_data: 'client/data-access/get-tvc-news-data-option',
    get_news_radio_data: 'client/data-access/get-rdc-news-data-option',
    get_news_print_data: 'client/data-access/get-print-news-data-option',
    get_news_online_data: 'client/data-access/get-online-news-data-option',
    search_news_company: 'client/company/search/news',
    get_tv_news_report: 'client/report/news-tv-report',
    get_rdc_news_report: 'client/report/news-radio-report',
    get_print_news_report: 'client/report/news-print-report',
    get_online_news_report: 'client/report/news-online-report',
    get_adv_tv_data: 'client/report/ad-tv-report',
    get_adv_radio_data: 'client/report/ad-radio-report',
    get_adv_print_data: 'client/report/ad-print-report',
    get_adv_online_data: 'client/report/ad-online-report',
    wrong: 'client/wrong-api',
}

export const DATA_OPTION = {
    tv_ad_data: "client/data-access/tv-ad-data-option",
    rdc_ad_data: "client/data-access/rdc-ad-data-option",
    print_online_ad_data: "client/data-access/print-online-ad-data-option",
    news_data: "client/data-access/news-data-option",
}